import { LeaderboardType, Status, Tier } from 'api/generated/graphql';

const routes = {
  userAgreement: () => '/user-agreement',
  avatar: () => '/avatar',
  createCar: () => '/create-car',
  garage: () => '/garage',
  salvage: () => '/salvage',
  fusion: () => '/car-fusion',
  leaderboard: (whenFilter?: LeaderboardType) =>
    `/leaderboard?when=${whenFilter || 'daily'}`,
  login: (redirectTo?: string) =>
    `/login${redirectTo ? `?redirectTo=${redirectTo}` : ''}`,
  '2fa': (redirectTo?: string) =>
    `/2fa${redirectTo ? `?redirectTo=${redirectTo}` : ''}`,
  '2fa/confirmation': ({
    redirectTo,
    type,
  }: {
    redirectTo?: string;
    type?: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA';
  }) => {
    if (!redirectTo && !type) return '/2fa/confirmation';

    const queryParams = new URLSearchParams();
    if (redirectTo) queryParams.append('redirectTo', redirectTo);
    if (type) queryParams.append('type', type);

    return `/2fa/confirmation?${queryParams.toString()}`;
  },
  'connect-wallet': () => '/connect-wallet',
  'connect-wallet/rewards': (referenceId: string) =>
    `/connect-wallet/rewards?referenceId=${referenceId}`,
  races: (tierFilter?: Tier, raceStatus?: Status) => `/races`,
  race: (id: string) => `/races/${id}`,
  raceResults: () => '/race-results',
  resetPassword: () => '/reset-password',
  rewards: () => '/rewards',
  marketplace: (paths?: string[]) =>
    `/marketplace${(paths && `/${paths?.join('/')}`) || ''}`,
  marketplaceTrailers: (referenceId: string, source?: string) =>
    `/trailers/rewards?referenceId=${referenceId}${
      source ? `&source=${source}` : ''
    }`,
  root: () => '/',
  settings: () => '/settings',
  publicDashboard: () => '/dashboard',
  verifyAccount: ({
    email,
    redirectTo,
  }: {
    email: string;
    redirectTo?: string;
  }) => {
    const queryParams = new URLSearchParams();
    queryParams.append('email', email);
    if (redirectTo) queryParams.append('redirectTo', redirectTo);

    return `/verify-account?${queryParams.toString()}`;
  },
};

export default routes;
