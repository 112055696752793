import { Flex, Text } from "@chakra-ui/react"
import Image from "next/image"
import { BottomBarLine } from "./FeaturedRace"
import router from "next/router"

export const BuyTickets = () => {
  return (
    <Flex
      w="100%"
      h="100%"
      role="group"
      position="relative"
      borderRadius="8px"
      border="1.5px solid rgba(255, 255, 255, 0.1)"
      backdropFilter="auto"
      backdropBlur="20px"
      cursor="pointer"
      background="rgba(0, 0, 0, 0.6)"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)"
      style={{
        backgroundOrigin: 'border-box',
      }}
      overflow="hidden"
      onClick={() => {
        router.push('/marketplace/tickets');
      }}
    >
      <Flex 
        w="100%"
        h="100%"
        position="absolute"
        top="0"
        left="0"
        background="linear-gradient(234.29deg, rgba(216, 56, 50, 0.6) 0%, rgba(216, 56, 50, 0) 100%)"
      />
      <Flex
        w={{ base: '135px', md: '302px' }}
        h={{ base: '125px', md: '224px' }}
        position="absolute"
        top={{ base: -4, md: 'auto' }}
        bottom={{ base: 'auto', md: -10 }}
        right={{ base: 12, md: 4 }}
        borderRadius="8px"
        zIndex={-1}
        overflow="hidden"
        transform="rotate(355deg)"
      >
        <Image 
          src="/images/home/Ticket.png"
          alt="First Race"
          fill
        />
      </Flex>
      {/* <Image 
        src="/images/home/Ticket.png"
        alt="Buy Tickets"
        fill
      /> */}
      <Flex
        flexDir="column"
        zIndex={1}
        w="full"
        alignItems="flex-start"
        justifyContent="flex-end"
        p={{ base: '1rem', md: '2rem' }}
        boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)"
        bgGradient="linear(to-t, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
        _hover={{
          bgGradient:
            'linear(to-t, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
        }}
      >
        <Text
          fontStyle="italic"
          fontSize={{ base: '16px', md: '24px' }}
          lineHeight={{ base: '16px', md: '24px' }}
          fontWeight="700"
          background="linear-gradient(90deg, #D83832 0%, #F6685E 100%)"
          bgClip="text"
        >
          BUY
        </Text>
        <Text
          fontStyle="italic"
          fontSize={{ base: '24px', md: '48px' }}
          fontWeight="600"
          color="white"
          lineHeight={{ base: '24px', md: '48px' }}
        >
          Tickets
        </Text>
      </Flex>
      <BottomBarLine />
    </Flex>
  )
}