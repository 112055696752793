import { Image } from '@chakra-ui/react';
const IconMaticBig = ({ 
  width = '88px', 
  height = '88px' 
}: { 
  width?: string | Record<string, string>, 
  height?: string | Record<string, string> 
}) => {
  return <Image 
    src="/images/icons/icon-matic-big.png" 
    alt="Matic Big" 
    width={width} 
    height={height} 
  />
}

export default IconMaticBig;
