import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import {
  IconRainConditions,
  IconTemperatureConditions,
  IconWrenchConditions,
} from 'icons';
import {
  AirTemperatureCategory,
  TrackTemperatureCategory,
} from 'api/generated/graphql';
import {
  AirTemperatureColors,
  TrackTemperatureColors,
  WeatherColors,
} from '../../RaceInfo/RaceInfoTypes';
import { useState } from 'react';
import { conditionsHoverText } from './FullRaceConditions';

// Function to determine the number of filled boxes
const getFilledBoxes = (
  category: string,
  type: 'air' | 'track' | 'weather' | 'skill'
): number => {
  if (type === 'skill') {
    // Convert difficulty 0-5 to 1-6 boxes
    return parseInt(category) + 1;
  }

  const categories = {
    air: Object.keys(AirTemperatureColors),
    track: Object.keys(TrackTemperatureColors),
    weather: Object.keys(WeatherColors),
  };
  const index = categories[type].indexOf(category);
  return (index + 1) / 2
};

// Component to render the boxes
const ConditionBoxes = ({
  category,
  type,
}: {
  category: string;
  type: 'air' | 'track' | 'weather' | 'skill';
}) => {
  const filledBoxes = getFilledBoxes(category, type);
  const color = 'yellow';

  return (
    <Flex alignItems="center" gap={1}>
    {[...Array(3)].map((_, index) => {
      const fillAmount = Math.min(1, Math.max(0, filledBoxes - index));
      
      return (
        <Box
          key={index}
          w={2}
          h={2}
          position="relative"
          borderRadius="2px"
          border="1px solid"
          borderColor={fillAmount > 0 ? color : 'white.20'}
          overflow="hidden"
        >
          <Box
            position="absolute"
            left={0}
            bottom={0}
            w={fillAmount === 0.5 ? '50%' : '100%'}
            h="100%"
            bg={fillAmount > 0 ? color : 'transparent'}
          />
        </Box>
      );
    })}
  </Flex>
  );
};

const RaceConditions = ({
  airTemperature,
  trackTemperature,
  weather,
  showHover = false,
  difficulty = 1,
}: {
  airTemperature: AirTemperatureCategory;
  trackTemperature: TrackTemperatureCategory;
  weather: string;
  showHover?: boolean;
  difficulty?: number;
}) => {
  const [conditionHovered, setConditionHovered] = useState<
    'skill' | 'air' | 'track' | 'weather' | null
  >(null);

  const renderCondition = (
    icon: JSX.Element,
    label: string,
    type: 'skill' | 'air' | 'track' | 'weather',
    category: string,
    isHovered: boolean
  ) => {
    const content = (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        background={`linear-gradient(90deg, rgba(255, 255, 255, ${
          isHovered ? '0.3' : '0.1'
        }) 0%, rgba(255, 255, 255, 0) 100%)`}
        px="8px"
        py="4px"
        borderRadius="8px"
        w="full"
        onMouseEnter={() => setConditionHovered(type)}
        onMouseLeave={() => setConditionHovered(null)}
      >
        <Flex alignItems="center" gap={2}>
          {icon}
          <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
            {label}
          </Text>
        </Flex>
        <ConditionBoxes category={category} type={type} />
      </Flex>
    );

    return showHover ? (
      content
    ) : (
      <Tooltip label={conditionsHoverText[type]} openDelay={500}>
        {content}
      </Tooltip>
    );
  };

  return (
    <Flex
      flexDir="column"
      gap="0.5rem"
      alignItems="center"
      justifyContent="center"
      w="full"
    >
      <Flex gap={4} w="full">
        <Flex flexDirection="row" gap={1} w="full">
          {renderCondition(
            <IconTemperatureConditions />,
            'Air',
            'air',
            airTemperature,
            conditionHovered === 'air'
          )}
          {renderCondition(
            <IconTemperatureConditions />,
            'Track',
            'track',
            trackTemperature,
            conditionHovered === 'track'
          )}
          {renderCondition(
            <IconRainConditions />,
            'Rain',
            'weather',
            weather,
            conditionHovered === 'weather'
          )}
        </Flex>
      </Flex>

      {showHover && (
        <Text
          fontSize="0.75rem"
          lineHeight="0.875rem"
          color="white.60"
          fontWeight={500}
        >
          {conditionHovered
            ? conditionsHoverText[conditionHovered]
            : 'Select a Condition to learn more'}
        </Text>
      )}
    </Flex>
  );
};

export default RaceConditions;
