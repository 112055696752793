import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconQrCode = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M6 1c.531 0 1 .469 1 1v4c0 .563-.469 1-1 1H2c-.563 0-1-.438-1-1V2c0-.531.438-1 1-1h4Zm-.5 4.5v-3h-3v3h3ZM6 9c.531 0 1 .469 1 1v4c0 .563-.469 1-1 1H2c-.563 0-1-.438-1-1v-4c0-.531.438-1 1-1h4Zm-.5 4.5v-3h-3v3h3ZM14 1c.531 0 1 .469 1 1v4c0 .563-.469 1-1 1h-4c-.563 0-1-.438-1-1V2c0-.531.438-1 1-1h4Zm-.5 4.5v-3h-3v3h3ZM14 10V9h1v4h-3v-1h-1v3H9V9h3v1h2Zm-2 5v-1h1v1h-1Zm2 0v-1h1v1h-1ZM4.5 3.5v1h-1v-1h1Zm8 0v1h-1v-1h1Zm-8 8v1h-1v-1h1Z"
    />
  </svg>
));
export default SvgIconQrCode;
