import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconReceive = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={46}
    height={40}
    fill="none"
    viewBox="0 0 46 40"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M43.844 26.094c.86.86 1.406 2.031 1.25 3.203 0 1.25-.547 2.422-1.485 3.125l-7.89 6.328c-1.094.86-2.344 1.25-3.672 1.25H1.422C.797 40 .25 39.375.25 38.75c0-.703.547-1.25 1.25-1.25h30.547c.781 0 1.484-.313 2.11-.781l7.89-6.25a1.658 1.658 0 0 0 0-2.578c-.234-.235-.625-.391-1.016-.391-.39 0-.86.156-1.172.39l-4.843 3.829c-.547.468-1.328.781-2.032.781H18.922c-.703 0-1.25-.625-1.25-1.25 0-.703.547-1.25 1.25-1.25h6.875c1.015 0 1.875-.86 1.875-1.875 0-1.094-.86-1.875-1.875-1.875H14.312c-1.093 0-2.109.313-3.046.938L7.672 30h-6.25C.797 30 .25 29.453.25 28.75c0-.625.547-1.25 1.25-1.25h5.39l2.97-2.188c1.406-1.015 2.89-1.562 4.53-1.562h11.25c2.188 0 4.22 1.563 4.532 3.75.156.938 0 1.797-.39 2.5h3.28c.157 0 .391 0 .547-.156l4.766-3.828c.781-.625 1.719-.938 2.734-.938 1.016 0 1.953.313 2.735 1.016Zm-22.11-11.172c1.875.312 3.828 0 4.063-1.328.234-1.094-.469-1.563-3.438-2.344l-.547-.156c-1.718-.469-5.312-1.485-4.687-5.078.39-1.875 2.031-3.125 4.297-3.438V1.25c0-.625.625-1.25 1.328-1.25.625 0 1.25.625 1.25 1.25v1.406c.625.078 1.406.313 2.422.625.625.235 1.015.938.781 1.563-.234.625-.937 1.015-1.562.781-.86-.234-1.485-.39-2.032-.469-1.797-.312-3.75 0-3.984 1.328-.156.938.156 1.407 2.813 2.188l.625.156c2.343.625 5.859 1.563 5.234 5.235-.39 1.874-2.031 3.124-4.375 3.437v1.25c0 .703-.547 1.25-1.172 1.25-.703 0-1.328-.547-1.328-1.25v-1.328c-.625-.078-1.172-.235-3.516-1.016-.625-.234-.937-.937-.781-1.562a1.309 1.309 0 0 1 1.64-.781c1.954.703 2.422.78 2.97.859Z"
    />
  </svg>
));
export default SvgIconReceive;
