import {
  Button,
  Box,
  Text,
  Flex,
  ButtonGroup,
  Checkbox,
} from '@chakra-ui/react';

import { IconWarningSquare } from 'icons';
import { RInput, RLink } from 'components';
import routes from 'routes';
import { AuthProvider } from 'types';
import { ChangeEvent, useState } from 'react';

const LoginForm = ({
  currentView,
  email,
  password,
  termsUrl,
  privacyPolicyUrl,
  setEmail,
  setPassword,
  handleSubmit,
  setErrorMessage,
  setCurrentView,
}: {
  currentView: 'login' | 'signup';
  email: string;
  password: string;
  termsUrl?: string;
  privacyPolicyUrl?: string;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
    authProvider?: AuthProvider
  ) => void;
  setErrorMessage: (value: string) => void;
  setCurrentView: (value: 'login' | 'signup') => void;
}) => {
  const uppercase = /[A-Z]/g;
  const lowercase = /[a-z]/g;
  const numeric = /[0-9]/g;
  const special = /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/g;

  const validLength = password.length >= 8;
  const hasUppercase = uppercase.test(password);
  const hasLowercase = lowercase.test(password);
  const hasNumeric = numeric.test(password);
  const hasSpecialChar = special.test(password);
  const isValidPassword =
    hasUppercase && hasLowercase && hasNumeric && hasSpecialChar && validLength;

  const [ageCheckbox, setAgeCheckbox] = useState(false);
  const confirmAge = (e: ChangeEvent<HTMLInputElement>) => {
    setAgeCheckbox(e.target.checked);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mx="0" w="full">
        <Box w="full" px="4" mt="6">
          <RInput
            onChange={(e) => setEmail((e.target as HTMLInputElement).value.replace(' ', ''))}
            value={email}
            isRequired
            label="Enter email"
          />
        </Box>

        <Box w="full" mt="1rem" px="4">
          <RInput
            type="password"
            isRequired
            onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
            value={password}
            label="Password"
          />
          {currentView === 'signup' && (
            <Text
              fontSize="0.75rem"
              color={password.length === 0 ? 'paleSlate.100' : 'fieryCoral.100'}
              textAlign="left"
              display="flex"
              alignItems="center"
            >
              {password.length === 0 ? (
                <></>
              ) : !validLength ? (
                <>
                  <IconWarningSquare display="inline-block" mr="0.25rem" />{' '}
                  Password must be 8 characters or longer!
                </>
              ) : !hasUppercase ? (
                <>
                  <IconWarningSquare display="inline-block" mr="0.25rem" />{' '}
                  Please use at least one capital letter!
                </>
              ) : !hasLowercase ? (
                <>
                  <IconWarningSquare display="inline-block" mr="0.25rem" />{' '}
                  Please use at least one lowercase letter!
                </>
              ) : !hasNumeric ? (
                <>
                  <IconWarningSquare display="inline-block" mr="0.25rem" />{' '}
                  Please use at least one number!
                </>
              ) : (
                !hasSpecialChar && (
                  <>
                    <IconWarningSquare display="inline-block" mr="0.25rem" />{' '}
                    Please use at least one special character!
                  </>
                )
              )}
            </Text>
          )}
        </Box>

        {currentView === 'signup' && (
          <Box
            mt="4"
            px="4"
          >
            <Checkbox
              colorScheme="red"
              onChange={(e) => confirmAge(e)}
              isChecked={ageCheckbox}
              size="sm"
              color="white.60"
            >
              I agree that I am 18 years of age or older.
            </Checkbox>
          </Box>
        )}

        {currentView === 'login' && (
          <RLink
            variant="secondary"
            my="1rem"
            display="inline-block"
            href={routes.resetPassword()}
          >
            Forgot Password?
          </RLink>
        )}


        <Flex
          flexDir="column"
          alignItems="center"
          background="linear-gradient(180deg, #1E1E1E 0%, #121212 100%)"
          borderRadius="8px"
          px="4"
          pb="4"
          justifyContent="center"
          mt={currentView === 'signup' ? '1rem' : '0'}
        >
          {currentView === 'login' && (
            <>
              <Button
                id="btn-login"
                w="full"
                type="submit"
                isDisabled={!email || !password}
                my="1rem"
              >
                Log In
              </Button>
            </>
          )}

          {currentView === 'signup' && (
            <>
              <Flex
                mt="0.75rem"
                fontSize="12px"
                lineHeight="14px"
                justifyContent="center"
                flexDir="column"
                color="white.80"
              >
                <Box>
                  <Box as="span" display="block" mt="1" textAlign="center">
                    Click &apos;Sign Up&apos; to agree agree to the{' '}
                    <RLink
                      about="_blank"
                      target="noopener noreferrer"
                      variant="secondary"
                      href={termsUrl}
                      display="inline-block"
                    >
                      Terms of Service
                    </RLink>{' '}
                    and{' '}
                    <RLink
                      about="_blank"
                      target="noopener noreferrer"
                      variant="secondary"
                      href={privacyPolicyUrl}
                      display="inline-block"
                    >
                      Privacy Policy
                    </RLink>
                  </Box>
                </Box>
              </Flex>
              <Button
                id="btn-signup"
                w="full"
                type="submit"
                mt="1rem"
                isDisabled={!email || !isValidPassword || !ageCheckbox}
              >
                Sign Up
              </Button>
            </>
          )}

          {currentView === 'login' && (
            <RLink
              fontSize="0.875rem"
              onClick={() => {
                setEmail('');
                setPassword('');
                setErrorMessage('');
                setCurrentView('signup');
              }}
            >
              Don&apos;t have an account?{' '}
              <Text
                display="inline-block"
                fontSize="0.875rem"
                color="fieryCoral.100"
              >
                Sign Up
              </Text>
            </RLink>
          )}

          {currentView === 'signup' && (
            <RLink
              fontSize="0.875rem"
              onClick={() => {
                setEmail('');
                setPassword('');
                setErrorMessage('');
                setCurrentView('login');
              }}
              mt="4"
            >
              Already have an account?&nbsp;{' '}
              <Text
                display="inline-block"
                fontSize="0.875rem"
                color="fieryCoral.100"
              >
                Log In
              </Text>
            </RLink>
          )}
        </Flex>
      </Box>
    </form>
  );
};

export default LoginForm;
