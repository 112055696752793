import { Image } from '@chakra-ui/react';
const IconMaticPolygon = ({ 
  width = '32px', 
  height = '32px' 
}: { 
  width?: string | Record<string, string>, 
  height?: string | Record<string, string> 
}) => {
  return <Image 
    src="/images/icons/icon-matic-polygon.png" 
    alt="Matic Polygon" 
    width={width} 
    height={height} 
  />
}

export default IconMaticPolygon;
