import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Center, VStack, Text, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Head from 'next/head';
import capitalize from 'lodash.capitalize';
import BgGarage from 'public/images/BG-Image.png';

import { IconClose, IconRacinoLogo, IconRLogo } from 'icons';
import { loginRequest } from 'utils/cognito/loginRequest';
import { signupRequest } from 'utils/cognito/signupRequest';
import {
  RLoginForm,
  RLoadingAnimation,
  RLink,
  RAlert,
  RAuthHeading,
  RAuthHeadingButton,
  RImage,
} from 'components';
import routes from 'routes';
import { useSessionData, useUserSettings } from 'hooks';
import { AuthResponse, AuthProvider, UserAttributes } from 'types';
import { SessionData } from 'hooks/useSessionData';
import LoginHeader from 'components/LoginHeader/LoginHeader';
// import { useAuth } from 'context';

export const TERMS_URL = 'https://racino.io/terms-and-conditions';
const PRIVACY_POLICY_URL = 'https://racino.io/privacy-policy';

const LoginPage = () => {
  const router = useRouter();
  const { getUserSettings } = useUserSettings();
  const { refetch: getUserLogin } = getUserSettings;
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasUserData, setHasUserData] = useState(false);
  const [authProvider, setAuthProvider] = useState('email / password');
  const [animate, setAnimate] = useState(false);
  const initialView = router.query.view as 'login' | 'signup';
  const [currentView, setCurrentView] = useState<'login' | 'signup'>(initialView || 'login');

  const source = router.query.source as string

  const redirectTo = router.query.redirectTo
    ? decodeURIComponent(router.query.redirectTo as string)
    : routes.root();

  const { saveSessionData } = useSessionData();

  const handleLogin = async (authProvider: AuthProvider) => {
    let loginResponse: AuthResponse = null;
    try {
      setIsLoading(true);
      loginResponse = await loginRequest({
        authProvider,
        credentials: {
          email,
          password,
        },
      });

      if (loginResponse) {
        saveSessionData(loginResponse);
        setHasUserData(true);
      }
    } catch (error) {
      const customError = error as { message: string };

      if (
        customError.message === 'SMS_MFA' ||
        customError.message === 'SOFTWARE_TOKEN_MFA'
      ) {
        router.push(
          routes['2fa/confirmation']({
            redirectTo: encodeURIComponent(redirectTo),
            type: customError.message,
          })
        );
      }
      setIsLoading(false);
      setErrorMessage(customError.message);
    } finally {
      if (loginResponse) {
        if (
          !loginResponse.userAttributes?.phone_number &&
          getUserSettings.data?.getUser.testRacesAllowed
        ) {
          router.push(routes['2fa'](encodeURIComponent(redirectTo)));
        } else {
          router.push(redirectTo);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleSignup = async (authProvider: AuthProvider) => {
    let signupResponse: UserAttributes | unknown | null = null;

    try {
      // acceptedTermsAndContinue();
      setIsLoading(true);
      signupResponse = await signupRequest({
        authProvider,
        credentials: {
          email,
          password,
        },
        source,
      });

      // signed in via 3rd party auth, redirect to root
      if (signupResponse && authProvider) {
        saveSessionData(signupResponse as SessionData);
        setHasUserData(true);
        router.push(routes.root());
      }

      // signed up via email / password, redirect to account verification
      if (signupResponse && !authProvider) {
        // Store password temporarily
        localStorage.setItem('tempSignupPassword', password);
        router.push(
          routes.verifyAccount({
            email,
            redirectTo: encodeURIComponent(redirectTo),
          })
        );
      }
    } catch (error) {
      const customError = error as { message: string };
      setErrorMessage(customError.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
    authProvider?: AuthProvider
  ) => {
    e.preventDefault();
    if (authProvider) {
      setAuthProvider(capitalize(authProvider));
    }

    if (currentView === 'login') {
      handleLogin(authProvider);
    }

    if (currentView === 'signup') {
      handleSignup(authProvider);
    }
  };

  const handleCleanStates = () => {
    setErrorMessage('');
  };

  useEffect(() => {
    handleCleanStates();
    setAnimate(false);

    const animationTimeout = setTimeout(() => {
      setAnimate(true);
    }, 300);

    return () => clearTimeout(animationTimeout);
  }, [currentView]);

  useEffect(() => {
    if (initialView) {
      setCurrentView(initialView);
    }
  }, [initialView]);

  return (
    <>
      <Head>
        <title>Log In | Racino.io</title>
      </Head>

      <LoginHeader/>
      <Center height="100dvh" pos="relative" overflow="hidden">
        <RImage
          src={BgGarage.src}
          width="0" // width set in style property
          height="0" // height set in style property
          objectFit="cover"
          objectPosition="top right"
          top={0}
          right={0}
          pos="absolute"
          zIndex={-1}
          overflow="hidden"
          alt={'garage background image'}
          style={{
            width: '100%',
            maxWidth: '1300px',
            height: 'auto',
            maskImage:
              'radial-gradient(circle at top right, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0) 80%)',
          }}
        />
        {animate && (
          <VStack
            as={motion.div}
            w="full"
            spacing={8}
            maxW="40rem"
            textAlign="center"
            px="4"
            initial={{ opacity: 0, x: 200 }}
            exit={{ opacity: 0, x: 200 }}
            animate={animate ? { opacity: 1, x: 0 } : { opacity: 0, x: 200 }}
            transition={{ duration: '0.3s', ease: 'ease-in-out' }}
          >
            <>
              {isLoading && (
                <>
                  <RAuthHeading
                    rightButton={
                      <RAuthHeadingButton
                        Icon={IconClose}
                        alt="close"
                        onClick={() => {
                          // let user dismiss loading view if they bail on 3rd party login
                          setIsLoading(false);
                        }}
                      />
                    }
                  >
                    Authorizing Request
                  </RAuthHeading>
                  <RLoadingAnimation />
                  <Text fontSize="1rem">Authorizing with {authProvider}</Text>
                </>
              )}

              {!isLoading && !hasUserData && (
                <Flex
                  flexDirection="column"
                  background="rgba(21, 21, 23, 1)"
                  borderRadius="8px"
                  w="full"
                  maxW="32.5rem"
                  alignItems="center"
                  border="1.5px solid rgba(255, 255, 255, 0.1)"
                  zIndex={10}
                >
                  <RAuthHeading>
                    {currentView === 'signup' ? 'Sign Up' : 'Log In'}
                  </RAuthHeading>

                  <RImage 
                    src={require("public/images/Logo.png")}
                    alt="racino logo"
                    width="10rem"
                    my="1rem"
                  />

                  <Text
                    fontSize="18px"
                    lineHeight="24px"
                    color="white.95"
                    fontWeight="600"
                  >
                    {currentView === 'signup' ? 'Sign up to claim rewards' : 'Already raced with us before?'}
                  </Text>

                  {errorMessage && (
                    <RAlert
                      variant="error"
                      description={
                        errorMessage ||
                        `An error occurred while ${currentView === 'login'
                          ? 'logging in'
                          : 'creating your account'
                        }, please try again or contact support.`
                      }
                    />
                  )}

                  <VStack w="full" maxW="32.5rem" spacing={8} fontSize="sm">
                    <RLoginForm
                      currentView={currentView}
                      email={email}
                      password={password}
                      termsUrl={TERMS_URL}
                      privacyPolicyUrl={PRIVACY_POLICY_URL}
                      setEmail={setEmail}
                      setPassword={setPassword}
                      handleSubmit={handleSubmit}
                      setErrorMessage={setErrorMessage}
                      setCurrentView={setCurrentView}
                    />
                  </VStack>
                </Flex>
              )}
            </>
          </VStack>
        )}
      </Center>
    </>
  );
};

export default LoginPage;