export type Subset<K> = {
  // Partial for nested objects
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
    ? Subset<K[attr]> | null
    : K[attr] extends object | null | undefined
    ? Subset<K[attr]> | null | undefined
    : K[attr];
};

const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

export const formatOrdinal = (n: number): string => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

export const isMobileDevice = () => {
  if (typeof window !== 'undefined') {
    const userAgent =
      navigator?.userAgent || navigator?.vendor || window?.opera || '';

    return /android|avantgo|blackberry|bb|meego|iemobile|opera mini|phone|ipad|ipod|iphone|mobi|mobile|palm|pie|tablet|up.browser|up.link|webos|wos/i.test(
      userAgent
    );
  }
  return false;
};

export const generateSlugFromDate = ({
  name,
  isoDate,
}: {
  name: string;
  isoDate: string;
}) => {
  const normalizedTrackName = name.toLowerCase().replace(/\s+/g, '-');

  const timestamp = new Date(isoDate).getTime() / 1000;

  const slug = `${normalizedTrackName}-${timestamp}`;

  return slug;
};

export const getRaceDataFromSlug = (slug: string) => {
  const [name, timestamp] = slug.split('-');

  return {
    name,
    isoDate: new Date(Number(timestamp) * 1000).toISOString(),
  };
};

export const getNextDay3PMEST = () => {
  const now = new Date();
  const nextDay = new Date(now);
  nextDay.setUTCHours(20, 0, 0, 0);
  if (now > nextDay) {
    nextDay.setDate(now.getDate() + 1);
  }
  return nextDay.toISOString();
};

export const getNextFriday3PMEST = () => {
  const now = new Date();
  const nextFriday = new Date(now);
  // Calculate the number of days until next Friday
  const daysUntilFriday = (5 - now.getUTCDay() + 7) % 7; // If today is Friday, go to next Friday
  // Check if current time is past 3 PM EST
  if (daysUntilFriday === 0 && now.getUTCHours() >= 20) {
    // If it's Friday and past 3 PM, go to next Friday
    nextFriday.setUTCDate(now.getUTCDate() + 7);
  } else {
    nextFriday.setUTCDate(now.getUTCDate() + daysUntilFriday);
  }
  nextFriday.setUTCHours(20, 0, 0, 0); // Set to 3 PM EST (20:00 UTC)
  return nextFriday.toISOString();
};

export const isTimeBetweenThursdayAndFridayEST = () => {
  const now = new Date();

  // Get current day of week (0-6, 0 is Sunday)
  const currentDay = now.getUTCDay();

  // Get current hour in UTC (EST+4)
  const currentHour = now.getUTCHours();

  // Thursday is day 4, Friday is day 5
  if (currentDay === 4 && currentHour >= 20) {
    // After Thursday 3pm EST
    return true;
  }

  if (currentDay === 5 && currentHour < 20) {
    // Before Friday 3pm EST
    return true;
  }

  return false;
};
