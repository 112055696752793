import { Flex, Link, Text } from "@chakra-ui/react"
import RImage from "components/Image/Image"

const LoginHeader = () => {
  return (
    <Flex
      position="fixed"
      w="full"
      h="3.5rem"
      justifyContent="center"
      textTransform="uppercase"
      color="white.95"
      fontSize="0.875rem"
      display={{base: 'none', md: 'flex'}}
      zIndex={5}
      p="1rem"
    >
      <Flex
        h="full"
        w="full"
        bgColor="black.60"
        maxW={{ base: "60rem", md: "75rem" }}
        justifyContent="space-between"
        backdropFilter="auto"
        backdropBlur="blur(20px)"
        alignItems="center"
        p="2rem"
        borderRadius="12px"
      >
         <RImage 
          src={require("public/images/Logo.png")}
          alt="racino logo"
          width="10rem"
        />
        <Flex gap={8} display={{ base: "none", md: "flex" }}>
        <Link
          href="https://guide.racino.io/info" 
          target="_blank"
        >
          <Text>
            GUIDES
          </Text>
        </Link>
        <Link>
          <Text>
            SUPPORT
          </Text>
        </Link>
      </Flex>
      </Flex>
    </Flex>
  )
}

export default LoginHeader