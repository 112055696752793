
import { Image } from '@chakra-ui/react';
const IconTicket = ({ 
  width = '22px', 
  height = '22px',
  w = '22px',
  h = '22px',
  mr,
  ml,
}: { 
  width?: string | Record<string, string>, 
  height?: string | Record<string, string>,
  w?: string | Record<string, string>,
  h?: string | Record<string, string>,
  mr?: string | Record<string, string>,
  ml?: string | Record<string, string>,
}) => {
  return <Image 
    src="/images/tickets/racino-ticket-full.png" 
    alt="Racino Ticket" 
    width={width || w} 
    height={height || h} 
    mr={mr}
    ml={ml}
  />
}

export default IconTicket;
