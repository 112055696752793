import { Flex, Skeleton, Stack, Text, useDisclosure } from "@chakra-ui/react"
import { BottomBarLine } from "./FeaturedRace"
import { IconTicket, IconTicketTournament } from "icons"
import useUserActivity from "hooks/useUserActivity";
import { useQueryClient } from "@tanstack/react-query";
import { Title } from './Blocks/Title';
import { useEffect } from "react";
import { ActivityItem } from "components/UserActivity/ActivityItem";
import { EmptyState } from "components/Hub/EmptyState";

export const Activity = () => {
  const { data: activities, isLoading, isRefetching } = useUserActivity({});

  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    queryClient.invalidateQueries(['userActivity']);
  }, []);
  
  return (
    <Flex
      w="100%"
      h="100%"
      background="rgba(0, 0, 0, 0.6)"
      borderRadius="8px"
      border="1.5px solid rgba(255, 255, 255, 0.1)"
      backdropFilter="auto"
      backdropBlur="20px"
      role="group"
      position="relative"
      overflow="hidden"
    >
      <Flex 
        backgroundImage="url('/images/home/market_bg.webp')"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        w="full"
        h="full"
        position="absolute"
        top={0}
        left={0}
        opacity={0.4}
      />
      <Flex
        w="full"
        h="full"
        px={{ base: '1rem', md: '2rem' }}
        position="relative"
        flexDirection="row"
        justifyContent="flex-start"
        bgGradient="linear(to-t, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
        _hover={{
          bgGradient:
            'linear(to-t, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
        }}
      >
        <Flex
          gap="2"
          flexDirection="column"
          justifyContent="center"
          minW="fit-content"
          mr="4"
        >
          <Text
            fontStyle="italic"
            fontSize={{ base: '14px', md: '16px' }}
            lineHeight={{ base: '14px', md: '16px' }}
            fontWeight="600"
            background="linear-gradient(90deg, #D83832 0%, #F6685E 100%)"
            bgClip="text"
          >
            RECENT
          </Text>
          <Text
            fontStyle="italic"
            fontSize={{ base: '18px', md: '24px' }}
            fontWeight="600"
            color="white"
            lineHeight={{ base: '18px', md: '24px' }}
          >
            Activity
          </Text>
        </Flex>
        <Flex
          gap="2"
          w="full"
          flex="1"
          py="1rem"
          overflow="hidden"
        >
          <Stack
            w="full"
            gap={2}
            px={4}
            py={2}
            overflowY="auto"
            className="red-scrollbar"
          >
            {(isLoading || isRefetching) && <Skeleton w="full" h="64px" />}

            {!isLoading &&
              !isRefetching &&
              activities?.getUserActivity.map((item, index) => (
                <ActivityItem
                  key={index}
                  message={item.message || ''}
                  timestamp={item.timestamp}
                  value={item.value}
                  action={item.action}
                  currency={item.currency || ''}
                />
              ))}

            {!isLoading &&
              !isRefetching &&
              activities?.getUserActivity.length === 0 && (
                <EmptyState
                  title="No recent activity"
                  message="No recent activity, come back later and check."
                  textAlign="center"
                  mt="2rem"
                />
              )}
          </Stack>
        </Flex>
        <BottomBarLine />
      </Flex>
    </Flex>
  )
}