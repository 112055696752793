import { Box, Flex, HStack } from '@chakra-ui/react';

import { H2 } from './Blocks/H2';
import Image from 'next/image';
import { Title } from './Blocks/Title';
import useGetFeaturedRaces from 'hooks/HomeScreen/useGetFeaturedRaces';
import { RaceMinimap, RacesBackground } from 'components/RaceCard/RaceCard';
import RTiers from 'components/Tiers/Tiers';
import { RaceStatus, RarityTier } from 'types';
import {
  RDateWithLabel,
  RLoadingAnimation,
  RPrizePool,
  RRacePreviewDrawer,
  RRaceStatus,
} from 'components';
import { CurrencyType } from 'api/generated/graphql';
import { raceRarity } from 'components/RacePreviewDrawer/_components/RaceHeader';
import TournamentBadge from 'components/Tiers/TournamentBadge';
import routes from 'routes';
import router from 'next/router';
import { useGetTicketsAvailable } from 'hooks';

export const FeaturedRacePanel = ({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  const { data: queryResult, isLoading } = useGetFeaturedRaces();
  const { data: ticketsAvailable } = useGetTicketsAvailable();
  const featuredRace = queryResult?.getRaces?.[0];
  const trackName = featuredRace?.track?.frontendName;

  const TrackMinimapIcon =
    trackName && trackName in RaceMinimap ? RaceMinimap[trackName] : null;

  return (
    <Flex
      w="100%"
      h="100%"
      bg="rgba(0, 0, 0, 0.4)"
      role="group"
      onClick={(event) => {
        event.stopPropagation();
        if ((featuredRace?.tier.name.toLowerCase() === 'tournament' || featuredRace?.tier.name.toLowerCase() === 'weeklytournament') && ticketsAvailable?.ticketsAvailable.tournamentTicketsAvailable === 0) {
          router.push(`/races/${featuredRace?.id}`)
          return
        }
        if (featuredRace?.status === "open") {
          onOpen()
        } else {
          if (featuredRace?.id) {
            router.push(routes.race(featuredRace.id));
          }
        }
      }}
      cursor="pointer"
    >
      {isLoading ? (
        <Flex
          zIndex={0}
          w="full"
          h="full"
          gap="1.5rem"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
        >
          <RLoadingAnimation />
        </Flex>
      ) : (
        <Flex
          w="100%"
          h="100%"
          position="relative"
          bgGradient="linear(to-t, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
          _hover={{
            bgGradient:
              'linear(to-t, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
          }}
        >
          <Flex
            w="100%"
            h="100%"
            position="absolute"
            bgGradient="linear(to-tl, rgba(255, 143, 115, 0.3) 40%, rgba(0, 138, 138, 0.3) 80%)"
            zIndex={9}
          ></Flex>
          {/* HEADER */}
          <Flex
            position="absolute"
            zIndex={10}
            w="full"
            h="100px"
            p={4}
            direction="column"
            gap={1}
            bgGradient="linear(to-b, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0) 100%)"
          >
            <Title maxW="340px">Featured race</Title>

            <Flex flexDir={['column', 'row']} gap={[1, 2]}>
              <RRaceStatus
                size="sm"
                status={featuredRace?.status as RaceStatus}
              />
              <RDateWithLabel
                fontSize="sm"
                time={featuredRace?.startTime}
                status={featuredRace?.status as RaceStatus}
              />
            </Flex>
          </Flex>

          {/* CONTENT */}
          {featuredRace && TrackMinimapIcon && (
            <>
              <Flex
                w="100%"
                h="100%"
                position="relative"
                opacity={0.6}
                _groupHover={{ opacity: 0.8 }}
              >
                <Image
                  src={RacesBackground[featuredRace?.track?.frontendName]}
                  alt="Race Background"
                  style={{ objectFit: 'cover' }}
                  fill
                  sizes="(max-width: 768px) 100vw, 50vw"
                />
              </Flex>

              <Box
                pos="absolute"
                w={['6.5rem', '10.5rem']}
                h={['6.5rem', '10.5rem']}
                top="0"
                right="0"
                left="0"
                bottom="0"
                margin="auto"
                zIndex={10}
              >
                <TrackMinimapIcon w="full" h="full" />
              </Box>
            </>
          )}
          {/* FOOTER */}
          <Flex
            zIndex={10}
            position="absolute"
            bottom={0}
            left={0}
            w="full"
            p={4}
            h="100px"
            align="flex-end"
            bgGradient="linear(to-t, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0) 100%)"
            justify="space-between"
          >
            <HStack>
              {featuredRace?.tier?.name.toLowerCase() === 'tournament' || featuredRace?.tier?.name.toLowerCase() === 'weeklytournament' ? (
                <TournamentBadge />
              ) : (
                <RTiers
                  tier={
                    raceRarity[
                      featuredRace?.difficulty as keyof typeof raceRarity
                    ] as RarityTier
                  }
                  tierType="rarity"
                />
              )}
              <H2 maxW="340px">{featuredRace?.track?.frontendName}</H2>
            </HStack>

            <RPrizePool
              raceId={featuredRace?.id || ''}
              size="lg"
              text="Prize Pool"
              poolAmount={featuredRace?.prizePool}
              currency={CurrencyType.Usd}
              textShadow="lg"
            />
          </Flex>
          <BottomBarLine />

          <RRacePreviewDrawer
            raceId={featuredRace?.id || ''}
            //TODO: remove key and manage component state better
            key={featuredRace?.id || ''}
            isOpen={isOpen}
            onClose={onClose}
          />
        </Flex>
      )}
    </Flex>
  );
};

export const BottomBarLine = () => {
  return (
    <Flex
      w="calc(100% - 8px)"
      right={0}
      left={0}
      m="auto"
      h="6px"
      borderBottom="2px solid"
      borderX="2px solid"
      borderColor="whiteAlpha.300"
      position="absolute"
      bottom={1}
      transition="all ease-in-out 0.3s"
      _groupHover={{
        borderColor: 'whiteAlpha.600',
      }}
    />
  );
};
