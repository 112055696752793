import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTuning = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    viewBox="0 0 12 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M6.75 6.164c.656.305 1.125.961 1.125 1.711 0 .773-.469 1.43-1.125 1.734v1.641c0 .422-.352.75-.75.75a.743.743 0 0 1-.54-.21.743.743 0 0 1-.21-.54V9.61a1.88 1.88 0 0 1-1.125-1.735c0-.75.445-1.406 1.125-1.71V.75C5.25.352 5.578 0 6 0c.398 0 .75.352.75.75v5.414ZM6 8.625c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75.755.755 0 0 0-.75.75c0 .422.328.75.75.75Zm4.5-5.46c.656.304 1.125.96 1.125 1.71A1.92 1.92 0 0 1 10.5 6.609v4.641c0 .422-.352.75-.75.75a.743.743 0 0 1-.54-.21.743.743 0 0 1-.21-.54V6.61a1.88 1.88 0 0 1-1.125-1.735c0-.75.445-1.406 1.125-1.71V.75c0-.398.328-.75.75-.75.398 0 .75.352.75.75v2.414Zm-.75 2.46c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75.755.755 0 0 0-.75.75c0 .422.328.75.75.75ZM3 1.665c.656.304 1.125.96 1.125 1.71 0 .773-.469 1.43-1.125 1.734v6.141c0 .422-.352.75-.75.75a.743.743 0 0 1-.54-.21.743.743 0 0 1-.21-.54V5.11A1.88 1.88 0 0 1 .375 3.374c0-.75.445-1.406 1.125-1.71V.75c0-.398.328-.75.75-.75.398 0 .75.352.75.75v.914Zm-.75 2.46c.398 0 .75-.328.75-.75a.771.771 0 0 0-.75-.75.755.755 0 0 0-.75.75c0 .422.328.75.75.75Z"
    />
  </svg>
));
export default SvgIconTuning;
