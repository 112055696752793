import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconError = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={40}
    fill="none"
    viewBox="0 0 41 40"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#D83832"
      d="M20.5 28.125c1.016 0 1.875.86 1.797 1.875 0 1.094-.781 1.875-1.797 1.875s-1.875-.781-1.875-1.875c0-1.016.781-1.875 1.875-1.875Zm0-3.125c-.703 0-1.25-.547-1.25-1.25V12.5c0-.625.625-1.25 1.25-1.25.547 0 1.172.625 1.172 1.25v11.25c0 .703-.547 1.25-1.172 1.25Zm19.375 6.094a4.09 4.09 0 0 1 0 4.218c-.781 1.407-2.188 2.188-3.75 2.188H4.875c-1.64 0-3.047-.781-3.828-2.188a4.09 4.09 0 0 1 0-4.218L16.672 4.687A4.398 4.398 0 0 1 20.5 2.5c1.563.078 2.969.86 3.75 2.188l15.625 26.406Zm-2.188 2.968c.391-.546.313-1.171 0-1.718L22.063 5.937c-.313-.546-.938-.859-1.563-.937-.078 0 0 0 0 0-.703 0-1.328.39-1.64.938L3.233 32.343c-.312.547-.39 1.172 0 1.718.313.626.938.938 1.641.938h31.172c.703 0 1.328-.313 1.64-.938Z"
    />
  </svg>
));
export default SvgIconError;
