import { Flex, FlexProps } from '@chakra-ui/react';
import { IconCup } from 'icons';

interface TournamentBadgeProps extends FlexProps {
  useMinWidth?: boolean;
}

const TournamentBadge = ({ useMinWidth = false, ...props }: TournamentBadgeProps) => {
  return (
    <Flex
      {...(useMinWidth && { minW: "60px" })}
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        background="linear-gradient(225deg, #FCF4BF 0%, #EFC862 18%, #CD9832 73%, #BB811B 100%)"
        p="6px"
        borderRadius="0.25rem"
        {...props}
      >
        <IconCup />
      </Flex>
    </Flex>
  );
};

export default TournamentBadge;
