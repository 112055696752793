import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconTrialGeneration = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={12}
    fill="none"
    viewBox="0 0 16 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      d="m1.969 4.223.788-2.273A2.05 2.05 0 0 1 4.682.582h4.197a2.05 2.05 0 0 1 1.924 1.368l.789 2.273c.07.046.139.07.185.116a4.428 4.428 0 0 0-1.785.695h-6.91c-.627 0-1.113.51-1.113 1.113v1.855h6.307v.371c0 .255 0 .51.046.742H1.97v1.299a.55.55 0 0 1-.557.556.536.536 0 0 1-.557-.556V6.147c0-.811.441-1.53 1.114-1.924Zm1.275-.302h7.072l-.556-1.6a.95.95 0 0 0-.881-.626H4.682a.95.95 0 0 0-.882.626l-.556 1.6ZM2.71 6.518c0-.394.324-.742.742-.742.394 0 .742.348.742.742a.747.747 0 0 1-.742.742.733.733 0 0 1-.742-.742Zm6.307 1.855a3.34 3.34 0 0 1 3.339-3.339 3.355 3.355 0 0 1 3.34 3.34 3.34 3.34 0 0 1-3.34 3.338 3.325 3.325 0 0 1-3.34-3.339Zm4.081-1.878-2.226 1.67c-.093.07-.14.185-.093.301a.28.28 0 0 0 .278.186h.812l-.696 1.275c-.07.116-.046.255.07.325.093.092.232.092.348.023l2.226-1.67c.093-.07.139-.185.093-.301-.047-.116-.14-.209-.255-.209h-.835l.695-1.252c.07-.116.047-.255-.07-.348a.278.278 0 0 0-.347 0Z"
    />
  </svg>
));
export default SvgIconTrialGeneration;
