/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Heading, Skeleton, Stack } from '@chakra-ui/react';
import ActivityItem from 'components/WalletActivity/ActivityItem';
import { WalletPopover } from './subcomponents/WalletPopover';
import useUserActivity from 'hooks/useUserActivity';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { EmptyState } from 'components/Hub/EmptyState';

export const ActivityTab = () => {
  const { data: activities, isLoading, isRefetching } = useUserActivity({
    limit: 15,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries(['userActivity']);
  }, []);

  const walletActivities = activities?.getUserActivity.filter((item) => item.action === 'DEPOSIT' || item.action === 'WITHDRAW')

  return (
    <Flex flexDir={'column'} gap={8} pt={4} w="full" overflowY="auto" className="red-scrollbar" pr="2">
      <Stack w="full" gap={2}>
        {(isLoading || isRefetching) && <Skeleton w="full" h="64px" />}

        {!isLoading &&
          !isRefetching &&
          walletActivities &&
          walletActivities?.length > 0 && 
          walletActivities?.map((item, index) => (
            <ActivityItem
              key={index}
              message={item.message || ''}
              timestamp={item.timestamp}
              value={item.value}
              action={item.action}
              currency={item.currency || ''}
            />
          ))}

        {!isLoading &&
          !isRefetching &&
          walletActivities &&
          walletActivities?.length === 0 && (
            <EmptyState
              title="No recent activity"
              message="No recent activity, come back later and check."
              textAlign="center"
              mt="2rem"
            />
          )}
      </Stack>
    </Flex>
  );
};
