import { Flex, Text } from "@chakra-ui/react";
import { BalanceDataProps } from "context/fundsContext";

const WalletBalance = ({ balanceData, isLoading }: { balanceData: BalanceDataProps, isLoading: boolean }) => {
  return (
    <Flex
      flexDir="column"
      gap="2"
      alignItems="center"
    >
      <Text
        fontSize="56px"
        lineHeight="56px"
        fontWeight="500"
        color="white.100"
      >
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format((Number(balanceData?.usdc.usdValue) + Number(balanceData?.vext.usdValue) + Number(balanceData?.matic.usdValue)))}
      </Text>
      {/* <Flex
        alignItems="center"
        gap="2"
      >
        <Text
          fontSize="18px"
          lineHeight="24px"
          fontWeight="600"
          color="bloodMoon.100"
        >
          -$0.0589
        </Text>
        <Flex
          background="rgba(216, 56, 50, 0.2)"
          borderRadius="4px"
          padding="4px 8px"
        >
          <Text
            fontSize="18px"
            lineHeight="24px"
            fontWeight="600"
            color="bloodMoon.100"
          >
            -$0.28%
          </Text>
        </Flex>
      </Flex> */}
    </Flex>
  )
}

export default WalletBalance;