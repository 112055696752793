import { Box, Flex, Show, Text, useDisclosure } from "@chakra-ui/react"
import RaceCountdown from "components/HomeScreen/RaceCountdown/RaceCountdown"
import Image from 'next/image';
import { RacesBackground, RaceMinimap } from "components/RaceCard/RaceCard"
import overlayGradient from 'public/images/races/race-thumb-overlay.png';
import RImage from "components/Image/Image";
import useGetFeaturedRaces from "hooks/HomeScreen/useGetFeaturedRaces";
import { useGetTicketsAvailable } from "hooks";
import { RLoadingAnimation, RRacePreviewDrawer } from "components";
import router from "next/router";
import routes from "routes";
import { useState } from "react";

export const FeaturedRaceBanner = () => {
  const { data: queryResult, isLoading } = useGetFeaturedRaces();
  const { data: ticketsAvailable } = useGetTicketsAvailable();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const featuredRace = queryResult?.getRaces?.[0];
  const trackName = featuredRace?.track?.frontendName || 'Bahrain';

  const [isLoadingTimer, setIsLoadingTimer] = useState(true);


  const TrackMinimapIcon =
    trackName && trackName in RaceMinimap ? RaceMinimap[trackName] : null;
    
  return (
    <Flex
      w="full"
      background="rgba(0, 0, 0, 0.8)"
      borderRadius="8px"
      border="1.5px solid rgba(255, 255, 255, 0.1)"
      backdropFilter="auto"
      backdropBlur="20px"
      alignItems="center"
      justifyContent="space-between"
      px={{ base: '0.25rem', md: '1rem' }}
      mb="1rem"
      position="relative"
      onClick={(event) => {
        event.stopPropagation();
        if ((featuredRace?.tier.name.toLowerCase() === 'tournament' || featuredRace?.tier.name.toLowerCase() === 'weeklytournament') && ticketsAvailable?.ticketsAvailable.tournamentTicketsAvailable === 0) {
          router.push(`/races/${featuredRace?.id}`)
          return
        }
        if (featuredRace?.status === "open") {
          onOpen()
        } else {
          if (featuredRace?.id) {
            router.push(routes.race(featuredRace.id));
          }
        }
      }}
      cursor="pointer"
    >
      {isLoading ? (
        <Flex
          w="full"
          h="full"
          alignItems="center"
          justifyContent="center"
        >
          <RLoadingAnimation />
        </Flex>
      ) : (
        <>
          <Flex
            background="linear-gradient(360deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
            _hover={{
            background:
              'linear(to-t, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
            }}
            w="full"
            h="full"
            position="absolute"
            top="0"
            left="0"
            borderRadius="8px"
          />
          <Flex
            alignItems="center"
            gap={{ base: '1', md: '2' }}
          >
          {/* <RaceThumbnail trackName="Bahrain" difficulty={1} tier="race" /> */}
          <Flex
            position="relative"
            w={{ base: '44px', md: '87px' }}
            h={{ base: '32px', md: '53px' }}
          >
            <RImage
              src={overlayGradient.src}
              width="0" // width set in style property
              height="0" // height set in style property
              objectFit="cover"
              objectPosition="top left"
              blendMode="hard-light"
              borderRadius="0.5rem"
              style={{
                width: '100%',
                height: '100%',
                zIndex: 10,
                position: 'absolute',
                top: 0,
                left: 0,
                overflow: 'hidden',
              }}
              alt={'background image'}
            />
            <RImage
              src={RacesBackground[trackName]}
              alt="Race Background"
              style={{ objectFit: 'cover', opacity: 0.8, borderRadius: '0.5rem' }}
              width="100%"
              height="100%"
            />
            {TrackMinimapIcon && (
              <Box
                pos="absolute"
                top="0"
                right="0"
                left="0"
                bottom="0"
                margin="auto"
                zIndex={10}
              >
                <TrackMinimapIcon w="full" h="full" />
              </Box>
            )}
          </Flex>
          <Text
            color="white.100"
            fontFamily="heading"
            fontSize={{ base: '16px', md: '24px' }}
            lineHeight={{ base: '16px', md: '32px' }}
            fontWeight="400"
          >
            {trackName}
          </Text>
        </Flex>
        {featuredRace && (
          <Flex alignItems="center">
            <RaceCountdown 
              startTime={featuredRace?.startTime || new Date().toISOString()} 
              isLoading={isLoadingTimer} 
              setIsLoading={setIsLoadingTimer}
            />
          </Flex>
        )}
        <Flex
          alignItems="center"
          gap="2"
          flexDir={{ base: 'column', md: 'row' }}
          >
          <Text
            color="white.60"
            fontFamily="heading"
            fontSize={{ base: '12px', md: '16px' }}
            lineHeight={{ base: '12px', md: '16px' }}
          >
            PRIZE POOL
          </Text>
            <Text
              color="treasuredTeal.100"
              fontSize={{ base: '16px', md: '24px' }}
              lineHeight={{ base: '16px', md: '24px' }}
              fontWeight="600"
            >
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(Number(featuredRace?.prizePool) / 100)}
            </Text>
          </Flex>
          <RRacePreviewDrawer
            raceId={featuredRace?.id || ''}
            key={featuredRace?.id || ''}
            isOpen={isOpen}
            onClose={onClose}
          />
        </>
      )}
    </Flex>
  )
}