import { extendTheme, DeepPartial, Theme } from '@chakra-ui/react';

import { alertTheme } from 'components/Alert/alertTheme';
import { buttonTheme } from 'components/Button/buttonTheme';
import { drawerTheme } from 'components/Drawer/drawerTheme';
import { formTheme } from 'components/Form/formTheme';
import { iconButtonTheme } from 'components/IconButton/iconButtonTheme';
import { linkTheme } from 'components/Link/linkTheme';
import { modalTheme } from 'components/Modal/modalTheme';
import { partCardTheme } from 'components/PartCard/partCardTheme';
import { progressTheme } from 'components/Progress/progressTheme';
import { skeletonTheme } from 'components/Skeleton/skeletonTheme';
import { raceCardTheme } from 'components/RaceCard/raceCardTheme';
import { radioTheme } from 'components/Radio/radioTheme';
import { selectTheme } from 'components/Select/selectTheme';
import { tagTheme } from 'components/Tag/tagTheme';
import { tiersTheme } from 'components/Tiers/tiersTheme';
import { toastTheme } from 'components/Toast/toastTheme';
import { tooltipTheme } from 'components/Tooltip/tooltipTheme';
import { switchTheme } from 'components/Switch/switchTheme';

import { colors, defaultGradient } from 'utils/themeConstants';
import { tabTheme } from 'components/Tab/tabTheme';
import { menuTheme } from 'components/Menu/menuTheme';
// console.log(chakraTheme);

type colorsType = typeof colors;
export type baseThemeType = DeepPartial<Theme & { colors: colorsType }>;

const theme: baseThemeType = extendTheme({
  colors,
  breakpoints: {
    base: '0px',
    sm: '426px',
    md: '769px',
    lg: '1025px',
    xl: '1281px',
    '2xl': '1367px',
  },
  fonts: {
    heading: `'Audiowide', sans-serif`,
    body: `'Chakra Petch', sans-serif`,
    input: '',
  },
  styles: {
    global: {
      body: {
        background: defaultGradient(100),
        backgroundColor: 'black.100',
        color: 'white.100',
        height: 'full',
        width: 'full',
        minHeight: '100dvh',
        overflow: 'hidden',
      },
      form: {
        width: '100%',
      },
      // disable Chakra's default focus styles
      '[data-focus], :focus': {
        boxShadow: 'none !important',
      },
    },
  },
  components: {
    Alert: alertTheme,
    Button: buttonTheme,
    Drawer: drawerTheme,
    Form: formTheme,
    IconButton: iconButtonTheme,
    Link: linkTheme,
    Modal: modalTheme,
    PartCard: partCardTheme,
    Progress: progressTheme,
    RaceCard: raceCardTheme,
    Radio: radioTheme,
    Select: selectTheme,
    Skeleton: skeletonTheme,
    Tag: tagTheme,
    Text: {
      baseStyle: {
        fontSize: 'md',
      },
    },
    Tiers: tiersTheme,
    Toast: toastTheme,
    Tooltip: tooltipTheme,
    Tabs: tabTheme,
    Menu: menuTheme,
    Switch: switchTheme,
  },
});

export default theme;
