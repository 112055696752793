import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconWalletFilled = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M14 4c1.094 0 2 .906 2 2v7c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2V3c0-1.094.875-2 2-2h11.5c.813 0 1.5.688 1.5 1.5V3H2.5c-.281 0-.5.25-.5.5 0 .281.219.5.5.5H14Zm-1 6.5c.531 0 1-.438 1-1 0-.531-.469-1-1-1-.563 0-1 .469-1 1 0 .563.438 1 1 1Z"
    />
  </svg>
));
export default SvgIconWalletFilled;
