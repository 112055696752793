import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetTicketsAvailable = () => {
  const { graphql, graphQLClient, hasToken } = useGraphQLClient();

  const GetTicketsAvailableGql = graphql(`
    query GetTicketsAvailable {
      ticketsAvailable {
        ticketsAvailable
        vextTicketsAvailable
        tournamentTicketsAvailable
        weeklyTournamentTicketsAvailable
      }
    }
  `);

  const GetTicketsAvailable = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['ticketsAvailable'],
    queryFn: async () => {
      return graphQLClient.request(GetTicketsAvailableGql);
    },
  });
  return GetTicketsAvailable;
};

export default useGetTicketsAvailable;
