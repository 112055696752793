import { Button, Flex, Text } from "@chakra-ui/react";
import { RInput } from "components";
import { useUserSettings } from "hooks";
import { IconArrow, IconCheckGreen, IconCopyPaste, IconMaticWallet, IconQrCode, IconUsdcWallet } from "icons";
import { useState } from "react";

const Receive = ({ setActiveSection }: { setActiveSection: (section: "receive" | "send" | "wallet") => void }) => {
  const { getUserSettings } = useUserSettings();
  const { data } = getUserSettings;
  
  const [activeReceiveSection, setActiveReceiveSection] = useState<"receive" | "address">("receive");
  const [copied, setCopied] = useState<{ [key: string]: boolean }>({ USDC: false, MATIC: false });

  const handleCopyToClipboard = async (text: string, currency: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(() => ({ [currency]: true }));
      setTimeout(() => setCopied(() => ({ [currency]: false })), 2000);
    } catch (err) {
      console.error('Falha ao copiar:', err);
      setCopied(() => ({ [currency]: false }));
    }
  };

  return (
    <Flex
      flexDir="column"
      gap="4"
      alignItems="center"
      justifyContent="flex-start"
      minH="550px"
      position="relative"
      w="full"
    >
      {activeReceiveSection === "receive" && (
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          gap="2"
          p="4"
          w="full"
        >
          <Text
            fontSize="24px"
            lineHeight="28px"
            fontFamily="heading"
            fontWeight="400"
            color="white.100"
          >
            Receive
          </Text>

          <Text
            textAlign="center"
            fontSize="14px"
            lineHeight="18px"
            fontWeight="400"
            fontFamily="heading"
            color="white.80"
            whiteSpace="pre-line"
          >
            {"Please only send tokens on Polygon\n to this wallet."}
          </Text>

          <Flex
            background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
            borderRadius="8px"
            alignItems="center"
            border="1px solid rgba(255, 255, 255, 0.1)"
            gap="4"
            w="full"
            px="4"
            py="4"
            mt="4"
            style={{
              backgroundOrigin: "border-box"
            }}
            onClick={() => setActiveReceiveSection("address")}
            cursor="pointer"
          >
            <Flex
              alignItems="center"
              gap="1rem"
              w="full"
              justifyContent="space-between"
            >
              <Flex
                alignItems="center"
                gap="4"
                w="full"
              >
                <IconUsdcWallet />
                <Flex
                  flexDir="column"
                  gap="1"
                  w="full"
                >
                  <Text
                    fontSize="14px"
                    lineHeight="14px"
                    fontWeight="400"
                    fontFamily="heading"
                    color="white.80"
                  >
                    USDC
                  </Text>

                  <Flex w="155px">
                    {copied.USDC ? (
                      <Flex
                        alignItems="center"
                        gap="2"
                        w="full"
                      >
                        <Text
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="400"
                          color="treasuredTeal.100"
                        >
                          Copied
                        </Text>
                        <IconCheckGreen />
                      </Flex>
                    ) : (
                      <Text
                        maxW="155px"
                        noOfLines={1}
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight="600"
                        color="white.80"
                      >
                        {data?.getUser.walletAddress}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                gap="6"
              >
                {/* <Flex
                  background="rgba(255, 255, 255, 0.1)"
                  borderRadius="14px"
                  justifyContent="center"
                  alignItems="center"
                  p="3"
                  w="full"
                  h="full"
                  _hover={{
                    background: "rgba(255, 255, 255, 0.2)"
                  }}
                  transition="all 0.2s ease-in-out"
                >
                  <IconQrCode />
                </Flex> */}

                <Flex
                  background="rgba(255, 255, 255, 0.1)"
                  borderRadius="14px"
                  justifyContent="center"
                  alignItems="center"
                  p="3"
                  w="full"
                  h="full"
                  _hover={{
                    background: "rgba(255, 255, 255, 0.2)"
                  }}
                  transition="all 0.2s ease-in-out"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyToClipboard(data?.getUser.walletAddress || '', 'USDC');
                  }}
                >
                  <IconCopyPaste 
                    w="16px"
                    h="16px"
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
            borderRadius="8px"
            alignItems="center"
            border="1px solid rgba(255, 255, 255, 0.1)"
            gap="4"
            w="full"
            px="4"
            py="4"
            style={{
              backgroundOrigin: "border-box"
            }}
            onClick={() => setActiveReceiveSection("address")}
            cursor="pointer"
          >
            <Flex
              alignItems="center"
              gap="1rem"
              w="full"
              justifyContent="space-between"
            >
              <Flex
                alignItems="center"
                gap="4"
                w="full"
              >
                <IconMaticWallet />
                <Flex
                  flexDir="column"
                  gap="1"
                  w="full"
                >
                  <Text
                    fontSize="14px"
                    lineHeight="14px"
                    fontWeight="400"
                    fontFamily="heading"
                    color="white.80"
                  >
                    MATIC
                  </Text>

                  <Flex w="155px">
                    {copied.MATIC ? (
                      <Flex
                        alignItems="center"
                        gap="2"
                        w="full"
                      >
                        <Text
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="400"
                          color="treasuredTeal.100"
                        >
                          Copied
                        </Text>
                        <IconCheckGreen />
                      </Flex>
                    ) : (
                      <Text
                        maxW="155px"
                        noOfLines={1}
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight="600"
                        color="white.80"
                      >
                        {data?.getUser.walletAddress}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                gap="6"
              >
                {/* <Flex
                  background="rgba(255, 255, 255, 0.1)"
                  borderRadius="14px"
                  justifyContent="center"
                  alignItems="center"
                  p="3"
                  w="full"
                  h="full"
                  _hover={{
                    background: "rgba(255, 255, 255, 0.2)"
                  }}
                  transition="all 0.2s ease-in-out"
                >
                  <IconQrCode />
                </Flex> */}

                <Flex
                  background="rgba(255, 255, 255, 0.1)"
                  borderRadius="14px"
                  justifyContent="center"
                  alignItems="center"
                  p="3"
                  w="full"
                  h="full"
                  _hover={{
                    background: "rgba(255, 255, 255, 0.2)"
                  }}
                  transition="all 0.2s ease-in-out"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyToClipboard(data?.getUser.walletAddress || '', 'MATIC');
                  }}
                >
                  <IconCopyPaste 
                    w="16px"
                    h="16px"
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            position="absolute"
            bottom="0"
            w="full"
            h="82px"
            background="linear-gradient(180deg, #1E1E1E 0%, #121212 100%);"
            borderRadius="20px"
            borderBottomLeftRadius="0"
            borderBottomRightRadius="0"
            justifyContent="center"
            alignItems="center"
            gap="2"
            p="4"
            cursor="pointer"
            onClick={() => setActiveSection("wallet")}
          >
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              w="full"
              h="full"
              _hover={{
                background: "rgba(255, 255, 255, 0.2)"
              }}
              transition="all 0.2s ease-in-out"
            >
              <IconArrow />
            </Flex>
          </Flex>
        </Flex>
      )}

      {activeReceiveSection === "address" && (
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          gap="4"
          p="4"
        >
          <Text
            fontSize="24px"
            lineHeight="28px"
            fontFamily="heading"
            fontWeight="400"
            color="white.100"
          >
            Receive Address
          </Text>

          <Flex
            flexDir="column"
            gap="2"
          >
            <Text
              textAlign="center"
              fontSize="14px"
              lineHeight="18px"
              fontWeight="400"
              fontFamily="heading"
              color="white.80"
            >
              Your USDC address
            </Text>
          
            <Flex
              background="rgba(21, 21, 23, 1)"
              border="1px solid rgba(255, 255, 255, 0.4)"
              px="4"
              py="2"
              gap="2"
            >
              <Text
                fontSize="18px"
                lineHeight="24px"
                fontWeight="400"
                color="white.100"
                maxW="270px"
                textAlign="center"
              >
                {data?.getUser.walletAddress || 'Loading wallet...'}
              </Text>
            </Flex>

            <Button
              variant="secondary"
              onClick={() => handleCopyToClipboard(data?.getUser.walletAddress || '', 'USDC')}
              gap="2"
            >
              <IconCopyPaste />
              COPY
            </Button>
          </Flex>

          <Flex
            position="absolute"
            bottom="0"
            w="full"
            h="82px"
            background="linear-gradient(180deg, #1E1E1E 0%, #121212 100%);"
            borderRadius="20px"
            borderBottomLeftRadius="0"
            borderBottomRightRadius="0"
            justifyContent="center"
            alignItems="center"
            gap="2"
            p="4"
            cursor="pointer"
            onClick={() => setActiveReceiveSection("receive")}
          >
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              w="full"
              h="full"
              _hover={{
                background: "rgba(255, 255, 255, 0.2)"
              }}
              transition="all 0.2s ease-in-out"
            >
              <IconArrow />
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default Receive;