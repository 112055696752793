import { Flex, Text } from '@chakra-ui/react';
import { EndedActivityDate } from 'components/EndedActivityDate/EndedActivityDate';
import { IconUsdcPolygon } from 'icons';

interface ItemProps {
  message: string;
  timestamp: string;
  action: string;
  value: string;
  currency: string;
}

const ActivityItem = ({ message, timestamp, action, value, currency }: ItemProps) => {
  return (
    <Flex
      background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
      borderRadius="8px"
      p="3"
      justifyContent="space-between"
      alignItems="center"
      w="full"
      border="1px solid rgba(255, 255, 255, 0.1)"
      _hover={{
        background: "linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)"
      }}
      transition="background 0.3s ease"
      style={{
        backgroundOrigin: "border-box"
      }}
    >
      <Flex
        alignItems="center"
        gap="3"
      >
        <IconUsdcPolygon />
        
        <Flex flexDir="column">
          <Text
            fontSize="14px"
            lineHeight="18px"
            fontWeight="400"
            color="white.80"
            fontFamily="heading"
          >
            {action === 'WITHDRAW' ? 'Sent' : 'Received'}
          </Text>
          <EndedActivityDate timeLeft={timestamp} />
        </Flex>
      </Flex>

      <Text
        fontSize="18px"
        lineHeight="24px"
        fontWeight="600"
        color="white.80"
      >
      {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(Number(value))}
      </Text>
    </Flex>
  )
}

export default ActivityItem;