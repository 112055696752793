import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconHourglassPast = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M2.5.938C2.5.43 2.89 0 3.438 0h13.124a.95.95 0 0 1 .938.938c0 .546-.43.937-.938.937h-.312v.742c0 1.602-.664 3.086-1.758 4.219L11.29 10l3.203 3.203c1.094 1.094 1.758 2.617 1.758 4.18v.742h.313a.95.95 0 0 1 .937.938c0 .546-.43.937-.938.937H3.438c-.546 0-.937-.39-.937-.938 0-.507.39-.937.938-.937h.312v-.742c0-1.563.625-3.086 1.719-4.18L8.672 10 5.469 6.836A6.037 6.037 0 0 1 3.75 2.617v-.742h-.313c-.546 0-.937-.39-.937-.938Zm11.875 1.68v-.743h-8.75v.742c0 1.094.39 2.11 1.172 2.89L10 8.712l3.164-3.203a4.05 4.05 0 0 0 1.211-2.89Z"
    />
  </svg>
));
export default SvgIconHourglassPast;
