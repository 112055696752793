import { Tag, Flex, Box, Text } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';

import { RaceStatus as RaceStatusType, Size } from 'types';

const MotionTag = motion(Tag);
const MotionBox = motion(Box);

const breathingAnimation = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 0.5,
    transition: {
      duration: 0.8,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatType: 'reverse',
    },
  },
};

const RaceStatus = ({
  status,
  size = 'md',
  type = 'detailed',
}: {
  status: RaceStatusType;
  size?: Size;
  type?: 'simple' | 'detailed';
}) => {
  const bgLookup: { [K in RaceStatusType]: string } = {
    open: 'treasuredTeal.100',
    upcoming: 'creamyOrangeBlush.100',
    live: 'bloodMoon.100',
    closed: 'alexandria.100',
    ended: 'cerebralGrey.100',
    cancelled: 'cerebralGrey.100',
    postponed: '',
    qualifying: 'firstDaffodil.100',
    tuning: 'perrywinkle.100',
  };

  const controls = useAnimation();

  useEffect(() => {
    if (status === 'live') {
      controls.start('visible');
    } else {
      controls.stop();
      controls.set('hidden');
    }
  }, [status, controls]);

  if (type === 'simple') {
    return (
      <Flex alignItems="center">
        <Text
          textTransform="uppercase"
          fontSize="0.875rem"
          lineHeight="1.125rem"
          fontWeight={500}
          color={bgLookup[status as keyof typeof bgLookup]}
        >
          {status}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex alignItems="center">
      {status === 'live' && (
        <MotionBox
          bgColor="bloodMoon.100"
          w="0.75rem"
          h="0.75rem"
          borderRadius="100%"
          display="inline-block"
          mr="0.5rem"
          variants={breathingAnimation}
          initial="hidden"
          animate={controls}
        />
      )}

      <MotionTag
        size={size}
        textTransform={'uppercase'}
        justifyContent="center"
        padding="0"
        width={status === 'live' ? '35px' : 'fit-content'}
        borderRadius={status === 'live' ? '2px' : 'none'}
        bg={status === 'live' ? 'bloodMoon.100' : 'transparent'}
        color={status === 'live' ? 'white' : bgLookup[status as keyof typeof bgLookup]}
        paddingLeft={0}
        variants={breathingAnimation}
        initial="hidden"
        animate={controls}
      >
        {status}
      </MotionTag>
    </Flex>
  );
};

export default RaceStatus;
