import {
  Box,
  Modal as ChakraModal,
  Flex,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Image,
  Button,
} from '@chakra-ui/react';
import TournamentBadge from 'components/Tiers/TournamentBadge';
import { IconGoldenModal } from 'icons';
import { useEffect, useRef } from 'react';

interface RewardsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const RewardsModal = ({
  isOpen,
  onClose,
}: RewardsModalProps) => {

  return (
    <ChakraModal 
      isOpen={isOpen} 
      onClose={onClose}
      size="2xl"
      variant="rewardsModal"
      isCentered
    >
      <ModalOverlay />
      <ModalContent 
        p={0} 
        borderRadius="0.5rem" 
        position="relative" 
        overflow="hidden"
      >
        <Box
          as="video"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          autoPlay
          loop
          muted
          playsInline
          opacity={0.8}
          
          zIndex={0}
          sx={{
            objectFit: 'cover',
          }}
        >
          <source src="videos/rewards-bg.webm" type="video/webm" />
        </Box>
        <ModalHeader 
          zIndex={100}
          background="linear-gradient(90deg, rgba(128, 128, 128, 0.8) 0%, rgba(163, 163, 163, 0) 100%)"
          borderTopLeftRadius="0.5rem"
          borderTopRightRadius="0.5rem"
        >
          <Flex
            alignItems="center"
            gap={2}
            pl={2}
          >
            <Text 
              textTransform="none"
              fontSize="16px"
              color="white.80"
            >
              New Reward
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody 
          p={0} 
          flexDir="column" 
          borderBottomLeftRadius="0.5rem" 
          borderBottomRightRadius="0.5rem"
          position="relative"
        >
          <Flex 
            w="100%" 
            h="316px" 
            flexDir="column" 
            alignItems="center" 
            justifyContent="space-between"
            textAlign="center"
            zIndex={2}
            p="1rem"
          >
            <Flex
              background="linear-gradient(90deg, rgba(178, 166, 61, 0) 0%, rgba(178, 166, 61, 0.2) 50%, rgba(178, 166, 61, 0) 100%);"
              borderRadius="0.5rem"
              w="100%"
              h="100%"
              p="8"
            >
              <Flex
                border="3px solid rgba(255, 255, 255, 0.8)"
                background="linear-gradient(225deg, rgba(255, 255, 255, 0.4) 0%, rgba(238, 238, 238, 0.4) 18%, rgba(160, 160, 160, 0.4) 73%, rgba(151, 151, 151, 0.4) 100%)"
                borderRadius="0.5rem"
                w="100%"
                h="100%"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
              >
                <Text
                  fontStyle="italic"
                  fontWeight="700"
                  fontSize="40px"
                  lineHeight="40px"
                  color="white.95"
                >
                  TRIAL CAR
                </Text>

                <Image
                  src="images/cars/trial-shadow.png"
                  alt="car"
                />
              </Flex>
            </Flex>
            
          </Flex>
        </ModalBody>
        <ModalFooter
          background="linear-gradient(90deg, rgba(216, 167, 66, 0.06) 0%, rgba(216, 167, 66, 0.6) 100%);"
          p={4}
          gap={3}
          zIndex={1}
        >
          <Button
            onClick={onClose}
            fontSize="16px"
            borderRadius="4px"
            className="animated"
            w="full"
            variant="goldenButton"
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default RewardsModal;
