import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { alertAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    paddingX: 4,
    paddingY: 2,
    fontSize: 'sm',
    backdropFilter: 'blur(10px)',
    // _before: {
    //   position: 'absolute',
    //   left: '0.6rem',
    //   content: '""',
    //   height: 'calc(100% - 1rem)',
    //   borderLeft: 'solid 2px',
    //   top: '0.5rem',
    //   backdropFilter: 'blur(10px)',
    // },
    // _after: {
    //   position: 'absolute',
    //   right: '0.6rem',
    //   content: '""',
    //   height: 'calc(100% - 1rem)',
    //   borderLeft: 'solid 2px',
    //   top: '0.5rem',
    // },
  },
  description: {
    color: 'white.80',
  },
});

const warningVariant = definePartsStyle({
  container: {
    backgroundColor: 'preciousPersimmon.20',
    _before: {
      borderColor: 'alexandria.100',
    },
    _after: {
      borderColor: 'alexandria.100',
    },
  },
});

const infoVariant = definePartsStyle({
  container: {
    backgroundColor: 'ionizedAirGlow.20',
    _before: {
      borderColor: 'iceColdStare.100',
    },
    _after: {
      borderColor: 'iceColdStare.100',
    },
  },
});

const walletInfoVariant = definePartsStyle({
  container: {
    backgroundColor: 'ionizedAirGlow.20',
    _before: {
      borderColor: 'iceColdStare.100',
    },
    _after: {
      borderColor: 'iceColdStare.100',
    },
    paddingX: 0,
    paddingY: 0,
    borderRadius: '12px',
    borderBottom: '1px solid',
    borderColor: 'bleuchatel.100',
    backgroundOrigin: 'border-box',
  },
});

const carGenerationVariant = definePartsStyle({
  container: {
    backgroundColor: 'ionizedAirGlow.20',
    _before: {
      borderColor: 'iceColdStare.100',
    },
    _after: {
      borderColor: 'iceColdStare.100',
    },
    paddingX: 0,
    paddingY: 0,
    borderRadius: '12px',
    borderBottom: '1px solid',
    borderColor: 'bleuchatel.100',
    backgroundOrigin: 'border-box',
  },
});

const successVariant = definePartsStyle({
  container: {
    backgroundColor: 'treasuredTeal.20',
    _before: {
      borderColor: 'mintie.100',
    },
    _after: {
      borderColor: 'mintie.100',
    },
  },
});

const errorVariant = definePartsStyle({
  container: {
    backgroundColor: 'fieryCoral.20',
    _before: {
      borderColor: 'fieryCoral.100',
    },
    _after: {
      borderColor: 'fieryCoral.100',
    },
  },
});

export const alertTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    warning: warningVariant,
    info: infoVariant,
    error: errorVariant,
    success: successVariant,
    walletInfo: walletInfoVariant,
    carGeneration: carGenerationVariant,
  },
  defaultProps: {
    variant: 'info',
  },
});
