import { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Heading,
  Text,
  UseDisclosureProps,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { partsData } from 'utils/partData';
import { RDrawer, RImage, RLoadingAnimation } from 'components';
import { useGetTicketsAvailable, useRepairCarParts } from 'hooks';
import { IconRepair, IconWrenchScrewdriver } from 'icons';
import IconTicket from 'components/IconTicket/IconTicket';
import { CarPart } from 'types';
import { useQueryClient } from '@tanstack/react-query';
import { useAnimatedTransition } from 'context';
import { PartTypeName } from 'api/generated/graphql';

interface RepairPartsDrawerProps extends UseDisclosureProps {
  carId?: string;
  maximumTimesUsed: number | null;
  requiredRepairPart?: PartTypeName;
}

const carPartsObj: CarPart[] = [
  {
    partType: 'frontWing',
    id: 'Front Wing',
    name: 'Front Wing',
    locked: false,
    rankRequired: 0,
  },
  {
    partType: 'rearWing',
    id: 'Rear Wing',
    name: 'Rear Wing',
    locked: false,
    rankRequired: 0,
  },
  {
    partType: 'engineCooling',
    id: 'Engine Cooling',
    name: 'Engine Cooling',
    locked: false,
    rankRequired: 0,
  },
  {
    partType: 'brakeCooling',
    id: 'Brake Cooling',
    name: 'Brake Cooling',
    locked: false,
    rankRequired: 0,
  },
  {
    partType: 'transmissionRatio',
    id: 'Transmission Ratio',
    name: 'Transmission Ratio',
    locked: false,
    rankRequired: 0,
  },
  {
    partType: 'engineMode',
    id: 'Engine Mode',
    name: 'Engine Mode',
    locked: false,
    rankRequired: 0,
  },
  {
    partType: 'suspensionRideHeight',
    id: 'Suspension Ride Height',
    name: 'Suspension Ride Height',
    locked: false,
    rankRequired: 0,
  },
  {
    partType: 'suspensionStiffness',
    id: 'Suspension Stiffness',
    name: 'Suspension Stiffness',
    locked: false,
    rankRequired: 0,
  },
];

const RepairPartsDrawer = ({
  carId = '',
  maximumTimesUsed,
  isOpen = false,
  onClose = () => null,
  requiredRepairPart,
}: RepairPartsDrawerProps) => {
  const [selectedCarPart, setSelectedCarPart] = useState<CarPart>();

  const { showAnimatedTransition } = useAnimatedTransition();

  const {
    onClose: modalClose,
    isOpen: modalOpen,
    onOpen: modalOnOpen,
  } = useDisclosure();

  const queryClient = useQueryClient();
  const { data } = useGetTicketsAvailable();
  const { mutateAsync, isLoading } = useRepairCarParts();

  const handleSelectCarPart = (part: CarPart) => {
    setSelectedCarPart(part);
    console.log(part);
  };

  const handleClose = () => {
    setSelectedCarPart(undefined);
    onClose();
  };

  const handleRepairCarParts = async () => {
    await mutateAsync({
      carId,
      partName:
        selectedCarPart?.partType === 'transmissionRatio'
          ? ('transmission' as PartTypeName)
          : (selectedCarPart?.partType as PartTypeName),
    });

    queryClient.invalidateQueries({
      queryKey: ['garageQuery'],
    });
    queryClient.invalidateQueries({
      queryKey: ['ticketsAvailable'],
    });

    showAnimatedTransition({
      Icon: IconWrenchScrewdriver,
      id: 'car-repaired-success',
      title: 'Car',
      subtitle: 'Repaired',
      color: 'treasuredTeal.100',
      bgColor:
        'linear-gradient(116deg, #04360F -11.26%, #000400 30.41%, #011205 71.39%, #006845 104.43%)',
      gradientColor: {
        from: 'rgba(72, 223, 187, 0.2)',
        to: 'rgba(72, 223, 187, 0)',
      },
    });

    handleClose();
  };

  useEffect(() => {
    if (requiredRepairPart) {
      if (requiredRepairPart === 'transmission') {
        setSelectedCarPart(
          carPartsObj.find((part) => part.partType === 'transmissionRatio')
        );
      } else {
        setSelectedCarPart(
          carPartsObj.find((part) => part.partType === requiredRepairPart)
        );
      }
    }
  }, [requiredRepairPart]);

  const partMetadata = partsData.find((p) => p.name === selectedCarPart?.partType);

  return (
    <RDrawer
      heading="Car Repair"
      isOpen={isOpen}
      onClose={onClose}
      variant="upgrade"
      headingUppercase={true}
      headingProps={{
        fontSize: '18px',
        fontWeight: 400,
      }}
      drawerHeaderProps={{
        bg: 'linear-gradient(180deg, #121212 0%, #1E1E1E 100%)',
        borderBottomRadius: '1rem',
        color: 'white.60',
        textTransform: 'none',
      }}
      drawerBodyProps={{
        bg: 'linear-gradient(180deg, rgba(38, 166, 154, 0) 0%, rgba(38, 166, 154, 0.64) 72.48%, rgba(38, 166, 154, 0.16) 100%)',
      }}
      drawerFooterProps={{
        bg: `linear-gradient(180deg, #121212 0%, #1E1E1E 100%)`,
        borderTop: 'none',
        borderTopRadius: '1.25rem',
        mt: '-1rem'
      }}
      drawerBody={
        !isLoading ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            flex="1 0 0"
            px={{ base: '0', md: '8' }}
            pt={{ base: '2' }}
          >
            <Heading
              fontSize={{ base: '2rem', md: '2.5rem' }}
              fontWeight="400"
            >
              Repair Now
            </Heading>
            <Text
              fontSize="1rem"
              fontWeight={400}
              color="white.80"
              fontFamily="heading"
            >
              {partMetadata?.screenName}
            </Text>
            <Flex
              position="relative"
              zIndex="1"
              justifyContent="center"
              alignItems="center"
              width={{ base: '70%', md: '100%' }}
              mb="2rem"
            >
              <Image
                src={partMetadata?.path ?? ''}
                alt={partMetadata?.screenName ?? ''}
                zIndex="2"
                w="400px"
              />
            </Flex>
          </Flex>
        ) : (
          <Flex
            zIndex={0}
            w="full"
            h="full"
            gap="1.5rem"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <RLoadingAnimation />
            <Text fontSize="1rem" color="white.80">
              We&apos;re repairing your car. Please wait a moment.
            </Text>
          </Flex>
        )
      }
      drawerFooter={
        <Flex
          flexDirection="column"
          gap="1rem"
          w="full"
        >
          <Flex w="full" flexDir="column" gap="0.125rem">
            <Flex
              w="full"
              alignItems="center"
              justifyContent="space-between"
              px="0.5rem"
              py="0.25rem"
              borderTopRadius="0.5rem"
              bg="linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
            >
              <Heading
                fontSize="0.875rem"
                color="white.60"
                textTransform="uppercase"
                fontWeight={400}
              >
                Available
              </Heading>
              <Flex gap="0.5rem" alignItems="center">
                <IconTicket w="1.25rem" h="1.25rem" />
                <Heading
                  fontSize="1.125rem"
                  textTransform="uppercase"
                  color="white.80"
                  fontWeight={400}
                >
                  {data?.ticketsAvailable.ticketsAvailable}
                </Heading>
              </Flex>
            </Flex>

            <Flex
              w="full"
              alignItems="center"
              justifyContent="space-between"
              p="0.5rem"
              bg="linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
            >
              <Heading
                fontSize="0.875rem"
                color="white.60"
                textTransform="uppercase"
                fontWeight={400}
              >
                Repair Cost
              </Heading>
              <Flex gap="0.5rem" alignItems="center">
                <IconTicket w="1.25rem" h="1.25rem" />
                <Heading
                  fontSize="1.125rem"
                  textTransform="uppercase"
                  color="white.80"
                  fontWeight={400}
                >
                  1
                </Heading>
              </Flex>
            </Flex>
          </Flex>
          <Button
            w="full"
            variant="tertiary"
            borderRadius="0.25rem"
            onClick={handleRepairCarParts}
            isLoading={isLoading}
            disabled={data?.ticketsAvailable.ticketsAvailable === 0}
          >
            {data?.ticketsAvailable.ticketsAvailable === 0 ? 'No Tickets Available' : 'Repair'}
            {data?.ticketsAvailable.ticketsAvailable && data?.ticketsAvailable.ticketsAvailable > 0 && (
              <IconRepair
                ml="0.25rem"
              />
            )}
          </Button>
        </Flex>
      }
    />
  );
};

export default RepairPartsDrawer;
