import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { IconCheckEnded } from 'icons';
import { useEffect, useState } from 'react';

interface EndedDateProps {
  timeLeft: string;
  fontSize?: string;
}

export const EndedActivityDate = ({
  timeLeft,
  fontSize,
  ...rest
}: EndedDateProps & FlexProps) => {
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    const formatDate = () => {
      const date = new Date(timeLeft);
      const formatter = new Intl.DateTimeFormat(undefined, {
        day: 'numeric',
        month: 'numeric',
        year: '2-digit'
      });
      
      setFormattedDate(formatter.format(date));
    };

    formatDate();
  }, [timeLeft]);

  return (
    <Flex
      bg="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
      borderRadius="3px"
      p="0px 4px"
      align="center"
      w="fit-content"
      mt="1"
      {...rest}
    >
      <IconCheckEnded w="12px" h="12px" mr="1" />
      <Text
        as="i"
        fontWeight="bold"
        fontSize={{ base: '10px', md: fontSize || '12px' }}
      >
        {formattedDate}
      </Text>
    </Flex>
  );
};
