import { Flex, Show, Text } from "@chakra-ui/react"
import { IconArrow } from "icons"
import Image from "next/image"
import { BottomBarLine } from "./FeaturedRace"
import router from "next/router"

export const MyGarage = () => {
  return (
    <Flex
      w="100%"
      h="100%"
      role="group"
      position="relative"
      borderRadius="8px"
      background="rgba(0, 0, 0, 0.6)"
      border="1.5px solid rgba(255, 255, 255, 0.1)"
      backdropFilter="auto"
      backdropBlur="20px"
      cursor="pointer"
      backgroundImage="url('/images/home/my_garage.png')"
      backgroundSize="cover"
      backgroundPosition={{ base: '100%', md: 'center' }}
      backgroundRepeat="no-repeat"
      onClick={() => {
        router.push('/garage');
      }}
    >
      <Show below="md">
        <Flex
          w="100%"
          h="100%"
          position="absolute"
          top={0}
          left={0}
          borderRadius="8px"
          zIndex={-1}
          background="rgba(0, 0, 0, 0.2)"
        />
      </Show>
      <Flex
        flexDir="column"
        zIndex={1}
        w="full"
        alignItems="flex-start"
        justifyContent="flex-end"
        p={{ base: '1rem', md: '2rem' }}
        boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)"
        bgGradient="linear(to-t, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
        _hover={{
          bgGradient:
            'linear(to-t, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
        }}
      >
        <Text
          fontStyle="italic"
          fontSize={{ base: '16px', md: '24px' }}
          lineHeight={{ base: '16px', md: '24px' }}
          fontWeight="700"
          background="linear-gradient(90deg, #D83832 0%, #F6685E 100%)"
          bgClip="text"
        >
          MY
        </Text>
        <Text
          fontStyle="italic"
          fontSize={{ base: '24px', md: '48px' }}
          fontWeight="600"
          color="white"
          lineHeight={{ base: '24px', md: '48px' }}
        >
          Garage
        </Text>
      </Flex>
      <BottomBarLine />
    </Flex>
  )
}