import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconVextPolygon = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#7A7AFF"
      fillRule="evenodd"
      d="M31.2 16c0 8.395-6.805 15.2-15.2 15.2C7.607 31.2.8 24.395.8 16S7.607.8 16 .8C24.396.8 31.2 7.605 31.2 16ZM5.6 10.4 16 13.452 26.4 10.4l-7.813 8.448 3.137 5.18L16 21.408l-5.723 2.62 3.136-5.18L5.602 10.4Zm3.412 2.146 6.989 2.403 6.988-2.402-6.988 4.332-6.989-4.333Zm6.989 5.259-7.355-5.22 6.09 6.184-3.486 4.294L16 19.656l4.751 3.407-3.485-4.293 6.09-6.184-7.355 5.219Z"
      clipRule="evenodd"
    />
    <rect
      width={12}
      height={12}
      fill="#fff"
      rx={2}
      transform="matrix(-1 0 0 1 32 20)"
    />
    <path
      fill="#000"
      fillOpacity={0.8}
      d="M23.709 24.457a.58.58 0 0 1 .564 0l1.295.75.88.49 1.296.75a.58.58 0 0 0 .564 0l1.03-.587a.56.56 0 0 0 .282-.473v-1.159c0-.195-.1-.375-.282-.473l-1.013-.57a.579.579 0 0 0-.565 0l-1.013.57a.561.561 0 0 0-.282.474v.75l-.88-.506v-.75c0-.196.1-.375.282-.473l1.877-1.077a.58.58 0 0 1 .564 0l1.91 1.077a.527.527 0 0 1 .282.473v2.17c0 .195-.1.374-.282.472l-1.91 1.077a.58.58 0 0 1-.564 0l-1.296-.734-.88-.506-1.295-.734a.58.58 0 0 0-.564 0l-1.013.571a.56.56 0 0 0-.283.473v1.158c0 .196.1.375.283.473l1.012.587a.58.58 0 0 0 .565 0l1.013-.57a.561.561 0 0 0 .282-.474v-.75l.88.506v.75c0 .196-.1.375-.282.473l-1.91 1.077a.58.58 0 0 1-.564 0l-1.91-1.077a.56.56 0 0 1-.282-.473v-2.17c0-.195.1-.374.282-.472l1.927-1.093Z"
    />
  </svg>
));
export default SvgIconVextPolygon;
