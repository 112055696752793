import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconUsdcPolygon = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#2775CA"
      fillRule="evenodd"
      d="M32 16c0 8.867-7.133 16-16 16S0 24.867 0 16 7.133 0 16 0s16 7.133 16 16ZM6.667 12.733c-1.934 5.134.733 10.934 5.933 12.8.2.134.4.4.4.6v.934c0 .133 0 .2-.067.266-.066.267-.333.4-.6.267a11.993 11.993 0 0 1-7.8-7.8c-2-6.333 1.467-13.067 7.8-15.067.067-.066.2-.066.267-.066.267.066.4.266.4.533v.933c0 .334-.133.534-.4.667-2.733 1-4.933 3.133-5.933 5.933ZM19.067 5c.066-.267.333-.4.6-.267 3.666 1.2 6.6 4.067 7.8 7.867 2 6.333-1.467 13.067-7.8 15.067-.067.066-.2.066-.267.066-.267-.066-.4-.266-.4-.533v-.933c0-.334.133-.534.4-.667 2.733-1 4.933-3.133 5.933-5.933 1.934-5.134-.733-10.934-5.933-12.8-.2-.134-.4-.4-.4-.667v-.933c0-.134 0-.2.067-.267ZM20.4 18.533c0-2.333-1.4-3.133-4.2-3.466-2-.267-2.4-.8-2.4-1.734 0-.933.667-1.533 2-1.533 1.2 0 1.867.4 2.2 1.4.067.2.267.333.467.333h1.066c.267 0 .467-.2.467-.466V13a3.33 3.33 0 0 0-3-2.733v-1.6c0-.267-.2-.467-.533-.534h-1c-.267 0-.467.2-.534.534V10.2c-2 .267-3.266 1.6-3.266 3.267 0 2.2 1.333 3.066 4.133 3.4 1.867.333 2.467.733 2.467 1.8 0 1.066-.934 1.8-2.2 1.8-1.734 0-2.334-.734-2.534-1.734-.066-.266-.266-.4-.466-.4h-1.134c-.266 0-.466.2-.466.467v.067c.266 1.666 1.333 2.866 3.533 3.2v1.6c0 .266.2.466.533.533h1c.267 0 .467-.2.534-.533v-1.6c2-.334 3.333-1.734 3.333-3.534Z"
      clipRule="evenodd"
    />
    <rect
      width={12}
      height={12}
      fill="#fff"
      rx={2}
      transform="matrix(-1 0 0 1 32 20)"
    />
    <path
      fill="#000"
      fillOpacity={0.8}
      d="M23.709 24.457a.58.58 0 0 1 .564 0l1.295.75.88.49 1.296.75a.58.58 0 0 0 .564 0l1.03-.587a.56.56 0 0 0 .282-.473v-1.159c0-.195-.1-.375-.282-.473l-1.013-.57a.579.579 0 0 0-.565 0l-1.013.57a.561.561 0 0 0-.282.474v.75l-.88-.506v-.75c0-.196.1-.375.282-.473l1.877-1.077a.58.58 0 0 1 .564 0l1.91 1.077a.527.527 0 0 1 .282.473v2.17c0 .195-.1.374-.282.472l-1.91 1.077a.58.58 0 0 1-.564 0l-1.296-.734-.88-.506-1.295-.734a.58.58 0 0 0-.564 0l-1.013.571a.56.56 0 0 0-.283.473v1.158c0 .196.1.375.283.473l1.012.587a.58.58 0 0 0 .565 0l1.013-.57a.561.561 0 0 0 .282-.474v-.75l.88.506v.75c0 .196-.1.375-.282.473l-1.91 1.077a.58.58 0 0 1-.564 0l-1.91-1.077a.56.56 0 0 1-.282-.473v-2.17c0-.195.1-.374.282-.472l1.927-1.093Z"
    />
  </svg>
));
export default SvgIconUsdcPolygon;
