import { Flex, Show, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";

const RaceCountdown = ({ startTime, isLoading, setIsLoading }: { startTime: string, isLoading: boolean, setIsLoading: (isLoading: boolean) => void }) => {
  const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const start = new Date(startTime).getTime();
      const distance = start - now;

      if (distance <= 0) {
        clearInterval(timer);
        setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
        setIsLoading(false);
        return;
      }

      const hours = Math.floor(distance / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({ hours, minutes, seconds });
      setIsLoading(false);
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime, setIsLoading, isLoading]);

  if (isLoading) return null;

  return (
    <Flex
      borderRadius="10px"
      p={{ base: '0rem', md: '1rem' }}
      justifyContent="center"
      alignItems="center"
      zIndex={2}
      flexDir="row"
      gap={{ base: '0.5', md: '2' }}
    >
      <Show above="md">
        <Text
          color="white.60"
          fontFamily="heading"
          fontSize="16px"
        >
          GOING LIVE IN
        </Text>
      </Show>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap={{ base: '0.5', md: '2' }}
      >
        {timeLeft.hours > 0 && (
          <>
            <Flex
              border="1.5px solid rgba(255, 255, 255, 0.2)"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              px="4"
              py="2"
              borderRadius="8px"
              width={{ base: '40px', md: '64px' }}
              height={{ base: '36px', md: '53px' }}
            >
              <Text
                color="white.95"
                fontSize={{ base: '20px', md: '32px' }}
                lineHeight={{ base: '16px', md: '25.6px' }}
              >
                {String(timeLeft.hours).padStart(2, '0')}
              </Text>
              <Text
                color="white.40"
                fontSize={{ base: '8px', md: '10px' }}
                fontFamily="heading"
              >
                {`HOUR${timeLeft.hours > 1 ? 'S' : ''}`}
              </Text>
            </Flex>
            <Text
              fontSize="30px"
              color="white.95"
              mb="3"
            >
            :
            </Text>
          </>
        )}
        <Flex
          border="1.5px solid rgba(255, 255, 255, 0.2)"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          px="4"
          py="2"
          borderRadius="8px"
          width={{ base: '40px', md: '64px' }}
          height={{ base: '36px', md: '53px' }}
        >
          <Text
            color="white.95"
            fontSize={{ base: '20px', md: '32px' }}
            lineHeight={{ base: '16px', md: '25.6px' }}
          >
            {String(timeLeft.minutes).padStart(2, '0')}
          </Text>
          <Text
            color="white.40"
            fontSize={{ base: '8px', md: '10px' }}
            fontFamily="heading"
          >
            MINS
          </Text>
        </Flex>
        <Text
          fontSize="30px"
          color="white.95"
          mb="3"
        >
        :
        </Text>
        <Flex
          border="1.5px solid rgba(255, 255, 255, 0.2)"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          px="4"
          py="2"
          borderRadius="8px"
          width={{ base: '40px', md: '64px' }}
          height={{ base: '36px', md: '53px' }}
        >
          <Text
            color="white.95"
            fontSize={{ base: '20px', md: '32px' }}
            lineHeight={{ base: '16px', md: '25.6px' }}
          >
            {String(timeLeft.seconds).padStart(2, '0')}
          </Text>
          <Text
            color="white.40"
            fontSize={{ base: '8px', md: '10px' }}
            fontFamily="heading"
          >
            SEC
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
};

export default RaceCountdown;