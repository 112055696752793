import { useQuery } from '@tanstack/react-query';
import { LeaderboardFilter } from 'api/generated/graphql';

import { useGraphQLClient } from 'hooks';

const useGetLeaderboard = (LeaderboardFilter: LeaderboardFilter) => {
  const { graphql, graphQLClient, hasToken } = useGraphQLClient();

  const getLeaderboardGql = graphql(`
    query GetLeaderboard($LeaderboardFilter: LeaderboardFilter!) {
      getLeaderboard(filter: $LeaderboardFilter) {
        id
        earnings
        points
        position
        numberOfTickets
        type
        car {
          id
          name
          user {
            id
            playerName
            countryFlag
            avatarId
            # avatarUrl
          }
        }
      }
    }
  `);

  const getLeaderboard = useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['leaderboardQuery', LeaderboardFilter],
    queryFn: async () => {
      return graphQLClient.request(getLeaderboardGql, {
        LeaderboardFilter: LeaderboardFilter,
      });
    },
  });
  return getLeaderboard;
};

export default useGetLeaderboard;
