import { Flex, Text } from "@chakra-ui/react"
import { BottomBarLine } from "./FeaturedRace"
import { IconTicketTournament } from "icons"
import IconSilverTicket from "components/IconSilverTicket/IconSilverTicket"
import { useGetTicketsAvailable } from "hooks"
import IconTicket from "components/IconTicket/IconTicket"
import { CountdownTimer } from "components/CountdownTimer/CountdownTimer"
import { getNextDay3PMEST, getNextFriday3PMEST } from "utils/helpers"

export const MyTickets = () => {
  const { data: ticketsAvailable } = useGetTicketsAvailable();
  const nextFriday = getNextFriday3PMEST();

  return (
    <Flex
      w="100%"
      h="100%"
      background="rgba(0, 0, 0, 0.6)"
      borderRadius="8px"
      border="1.5px solid rgba(255, 255, 255, 0.1)"
      backdropFilter="auto"
      backdropBlur="20px"
      role="group"
      position="relative"
    >
        <Flex 
          backgroundImage="url('/images/home/market_bg.webp')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          w="full"
          h="full"
          position="absolute"
          top={0}
          left={0}
          zIndex={1}
          opacity={0.4}
        />
      <Flex
        w="full"
        h="full"
        px={{ base: '1rem', md: '2rem' }}
        position="relative"
        alignItems="center"
        justifyContent="space-between"
        bgGradient="linear(to-t, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
        _hover={{
          bgGradient:
            'linear(to-t, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
        }}
        zIndex={2}
      >
      <Flex
        gap="2"
        flexDirection="column"
        mr={{base: '1', md: '4'}}
      >
        <Text
          fontStyle="italic"
          fontSize={{ base: '14px', md: '16px' }}
          lineHeight={{ base: '14px', md: '16px' }}
          fontWeight="600"
          background="linear-gradient(90deg, #D83832 0%, #F6685E 100%)"
          bgClip="text"
        >
          MY
        </Text>
        <Text
          fontStyle="italic"
          fontSize={{ base: '18px', md: '24px' }}
          fontWeight="600"
          color="white"
          lineHeight={{ base: '18px', md: '24px' }}
        >
          Tickets
        </Text>
      </Flex>
      <Flex
        gap={{ base: '1', md: '2' }}
      >
        <Flex
          background="linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(360deg, rgba(255, 0, 0, 0) 05%, rgba(255, 0, 0, 0.2) 100%)"
          style={{
            backgroundOrigin: 'border-box',
          }}
          borderRadius="8px"
          border="1.5px solid rgba(255, 255, 255, 0.1)"
          backdropFilter="auto"
          backdropBlur="20px"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={{ base: '82px', sm: '110px', md: '85px', lg: '110px', xl: '130px', '2xl': '142px' }}
          height={{ base: '78px', sm: '90px', md: '80px', lg: '90px', xl: '110px', '2xl': '124px' }}
          gap={{ base: '1', md: '2' }}
          pt={{ base: '1rem', md: '0' }}
        >
          <IconTicket
            width={{ base: '1.85rem', md: '2.85rem' }}
            height={{ base: '1.85rem', md: '2.85rem' }}
          />
          <Text
            fontSize={{ base: '16px', md: '24px' }}
            fontWeight="600"
            color="white"
            lineHeight={{ base: '16px', md: '24px' }}
          >
            {ticketsAvailable?.ticketsAvailable.ticketsAvailable}
          </Text>
        </Flex>
        <Flex
          background="linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(360deg, rgba(255, 238, 88, 0) 45%, rgba(255, 238, 88, 0.2) 100%)"
          style={{
            backgroundOrigin: 'border-box',
          }}
          borderRadius="8px"
          border="1.5px solid rgba(255, 255, 255, 0.1)"
          backdropFilter="auto"
          backdropBlur="20px"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="relative"
          width={{ base: '82px', sm: '110px', md: '85px', lg: '110px', xl: '130px', '2xl': '142px' }}
          height={{ base: '78px', sm: '90px', md: '80px', lg: '90px', xl: '110px', '2xl': '124px' }}
          gap={{ base: '1', md: '2' }}
          pt={{ base: '1rem', md: '0' }}
        >
          {ticketsAvailable && ticketsAvailable?.ticketsAvailable.weeklyTournamentTicketsAvailable > 0 && (
            <Flex
              position="absolute"
              top={0}
              w="full"
              background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
              justify="center"
            >
              <CountdownTimer
                timeLeft={nextFriday}
                w="full"
                fontSize="12px"
                alignItems="center" 
                justifyContent="center"
              />
            </Flex>
          )}
          <IconTicketTournament
            w={{ base: '2rem', md: '3rem' }}
            h={{ base: '2rem', md: '3rem' }}
          />
          <Text
            fontSize={{ base: '16px', md: '24px' }}
            fontWeight="600"
            color="white"
            lineHeight={{ base: '16px', md: '24px' }}
          >
            {ticketsAvailable?.ticketsAvailable.weeklyTournamentTicketsAvailable}
          </Text>
        </Flex>
        <Flex
          background="linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(360deg, rgba(255, 255, 255, 0) 45%, rgba(255, 255, 255, 0.2) 100%)"
          borderRadius="8px"
          border="1.5px solid rgba(255, 255, 255, 0.1)"
          backdropFilter="auto"
          backdropBlur="20px"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={{ base: '82px', sm: '110px', md: '85px', lg: '110px', xl: '130px', '2xl': '142px' }}
          height={{ base: '78px', sm: '90px', md: '80px', lg: '90px', xl: '110px', '2xl': '124px' }}
          gap={{ base: '1', md: '2' }}
          position="relative"
          style={{
            backgroundOrigin: 'border-box',
          }}
          pt={{ base: '1rem', md: '0' }}
        >
          {ticketsAvailable && ticketsAvailable?.ticketsAvailable.tournamentTicketsAvailable > 0 && (
            <Flex
            position="absolute"
            top={0}
            w="full"
            background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
            justifyContent="center"
            alignItems="center"
          >
            <CountdownTimer
              timeLeft={getNextDay3PMEST()}
              w="full"
              fontSize="12px"
              alignItems="center"
              justifyContent="center"
            />
            </Flex>
          )}
          <IconSilverTicket 
            width={{ base: '1.75rem', md: '2.8rem' }}
            height={{ base: '1.75rem', md: '2.8rem' }}
          />
          <Text
            fontSize={{ base: '16px', md: '24px' }}
            fontWeight="600"
            color="white"
            lineHeight={{ base: '16px', md: '24px' }}
          >
            {ticketsAvailable?.ticketsAvailable.tournamentTicketsAvailable}
          </Text>
        </Flex>
      </Flex>
      <BottomBarLine />

      </Flex>
    </Flex>
  )
}