import {
  Flex,
  FlexProps,
  Box,
  Alert,
  ImageProps,
  Text,
  useTheme,
  Button,
  Skeleton,
  Tooltip,
} from '@chakra-ui/react';

import {
  RTiers,
  RBaseCardTopLeft,
  RPositionAndPoints,
  RGradientBorder,
  RCurrency,
  RAlert,
} from 'components';
import { CarPart, CarTier, Livery, RarityTier, AppliedLivery } from 'types';

import CarBaseCard from './CarBaseCard';
import isNumber from 'lodash.isnumber';
import {
  IconCheckeredFlag,
  IconLiveries,
  IconStopWatchTimer,
  IconTool,
} from 'icons';
import { PartTypeName, RaceParticipants } from 'api/generated/graphql';
import { useResults } from 'context';
import UpgradeCounter from 'components/UpgradeCounter/UpgradeCounter';
import RepairCounter from 'components/RepairCounter/RepairCounter';
import { useRouter } from 'next/router';
import RaceLevel from 'components/Races2/RaceLevel/RaceLevel';
import { rarityLevels } from 'utils/themeConstants';

export interface CarCardProps {
  carId?: string;
  showRepairButton?: boolean;
  carParts?: CarPart[];
  vehicleName?: string;
  points?: number;
  carPosition?: number;
  backgroundPreload?: boolean; // use when likely to be "above the fold"
  livery?: Livery;
  tier?: CarTier;
  rarity?: RarityTier;
  alert?: React.ReactElement<typeof Alert> | undefined;
  imageProps?: ImageProps;
  backgroundProps?: FlexProps;
  isMysteryCar?: boolean;
  hasBackground?: boolean;
  hasInsetBorder?: boolean;
  locked?: boolean;
  isSelected?: boolean;
  timesUsed?: number | null;
  maximumTimesUsed?: number | null;
  showCarHealth?: boolean;
  showResultsFooter?: boolean;
  playersParticipant?: RaceParticipants;
  nextRepairAt?: number | null | undefined;
  usageSinceLastRepair?: number | null | undefined;
  repairMessage?: string | null | undefined;
  showTopRightButtons?: boolean;
  appliedLivery?: AppliedLivery;
  isTrialCar?: boolean;
  isGarage?: boolean;
  isEntries?: boolean;
  isResults?: boolean;
  trialLastUpgradedAt?: Date;
  size?: 'sm' | 'md' | 'lg';
  requiredRepairPart?: PartTypeName;
  isIndividualGarage?: boolean;
  hasCardBg?: boolean;
}

const CarCard = ({
  carId,
  showRepairButton,
  carParts,
  children,
  vehicleName,
  points,
  carPosition,
  backgroundPreload,
  livery = 'orange',
  tier,
  rarity,
  alert,
  imageProps,
  backgroundProps,
  isMysteryCar,
  hasBackground = true,
  hasInsetBorder = true,
  locked = false,
  isSelected,
  timesUsed = 0,
  maximumTimesUsed = 1000,
  showCarHealth = false,
  showResultsFooter = false,
  playersParticipant,
  showTopRightButtons = false,
  appliedLivery,
  isTrialCar,
  size,
  nextRepairAt = 140,
  usageSinceLastRepair = 0,
  repairMessage,
  isGarage,
  isEntries,
  requiredRepairPart,
  trialLastUpgradedAt,
  isResults,
  isIndividualGarage,
  hasCardBg = false,
  ...rest
}: FlexProps & CarCardProps) => {
  const theme = useTheme();
  const { showAllResults } = useResults();
  const router = useRouter();

  // const getRepairStage = () => {
  //   const wornOutPartsCount = carParts?.reduce((count, part) => {
  //     return count + (part.timesUsed === part.maximumTimesUsed ? 1 : 0);
  //   }, 0);

  //   if (wornOutPartsCount === 1) return 1;
  //   if (wornOutPartsCount === 2) return 2;
  //   if (wornOutPartsCount === 3) return 3;
  //   if (wornOutPartsCount && wornOutPartsCount >= 4) return 4;
  // };

  // const repairStage = getRepairStage();

  const sizes = {
    width: {
      sm: '13.125rem',
      md: '33.75rem',
      lg: undefined,
    },
    fontSize: {
      sm: 'sm',
      md: undefined,
      lg: undefined,
    },
    titleMaxW: {
      sm: 'full',
      md: undefined,
      lg: undefined,
    },
    absDistance: {
      sm: '3',
      md: '1.5rem',
      lg: '1.5rem',
    },
    noOfLines: {
      sm: 1,
      md: undefined,
      lg: 2,
    },
  };

  if (isMysteryCar) {
    livery = 'blue';
    imageProps = {
      filter: 'grayscale(100%) brightness(15%)',
    };
  }

  return (
    <CarBaseCard
      overflow={'hidden'}
      rarity={rarity}
      locked={locked}
      isIndividualGarage={isIndividualGarage}
      hasBackground={hasBackground}
      hasInsetBorder={hasInsetBorder}
      imageProps={imageProps}
      backgroundProps={backgroundProps}
      isResults={isResults}
      backgroundSrc={
        appliedLivery
          ? require(`public/images/cars/livery-${appliedLivery.imageUrl}.webp`)
          : require(`public/images/cars/livery-${livery}.webp`)
      }
      backgroundPreload={backgroundPreload}
      w={sizes.width[size || 'lg']}
      hasCardBg={hasCardBg}
      {...rest}
    >
      {isSelected && <RGradientBorder color={theme.colors.bloodMoon[100]} />}

      {showTopRightButtons && (
        <Flex
          position="absolute"
          top={0}
          right={0}
          flexDir="column"
          gap={2}
          p={4}
          zIndex={10}
        >
          <Tooltip label="Click to change livery" openDelay={500}>
            <Button
              variant="icon"
              onClick={(e) => {
                e.stopPropagation();
                router.push(`/garage/${carId}?section=liveries`);
              }}
            >
              <IconLiveries />
            </Button>
          </Tooltip>
        </Flex>
      )}

      <RBaseCardTopLeft
        heading={vehicleName}
        headingProps={{
          fontSize: sizes.fontSize[size || 'lg'],
          noOfLines: sizes.noOfLines[size || 'lg'],
        }}
        maxW={sizes.titleMaxW[size || 'lg']}
        top={sizes.absDistance[size || 'lg']}
        left={sizes.absDistance[size || 'lg']}
      >
        <Flex gap="2" height="1.5rem" alignItems="center">
          {/* {tier && (
            <RTiers tierType="rarity" tier={rarity || 'common'} size="md" />
          )} */}
          {tier && (
            <RaceLevel level={rarityLevels[rarity || 'common' as keyof typeof rarityLevels]} />
          )}

          <RPositionAndPoints
            carPosition={carPosition}
            fontFamily="heading"
            points={points}
          />
        </Flex>
      </RBaseCardTopLeft>

      {children}

      {isMysteryCar && (
        <Text
          fontFamily="heading"
          fontSize="15rem"
          position="absolute"
          top=" 0"
          left="0"
          width="100%"
          lineHeight="1"
          opacity="0.7"
        >
          ?
        </Text>
      )}

      <Flex
        flexDirection="column"
        width="calc(100% - 3rem)"
        position="absolute"
        bottom={sizes.absDistance[size || 'lg']}
        right={{ base: '7', md: isGarage ? sizes.absDistance[size || 'lg'] : '0'}}
      >
        {showCarHealth && isNumber(timesUsed) && (
          <Flex
            flexDir={isGarage ? 'row' : { base: 'row', md: 'column' }}
            gap="4"
            w="full"
            zIndex={10}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
          >
            <UpgradeCounter
              timesUsed={timesUsed}
              maximumTimesUsed={maximumTimesUsed}
              isGarage={isGarage}
              trialLastUpgradedAt={trialLastUpgradedAt}
              isTrialCar={isTrialCar}
            />
            {!isTrialCar && (
              <RepairCounter
                timesUsed={usageSinceLastRepair ?? 0}
                maximumTimesUsed={nextRepairAt}
                isGarage={isGarage}
                carId={carId}
                requiredRepairPart={requiredRepairPart}
              />
            )}
          </Flex>
        )}
      </Flex>

      <Flex
        flexDirection="column"
        width="calc(100% - 3rem)"
        position="absolute"
        bottom={sizes.absDistance[size || 'lg']}
        right={isGarage ? sizes.absDistance[size || 'lg'] : '7'}
      >
        <Box maxWidth="24rem" mx="auto">
          {alert}
        </Box>

        {/* {showCarHealth && isNumber(timesUsed) && (
          isTrialCar ? (
            <UpgradeCounter
              timesUsed={timesUsed}
              maximumTimesUsed={maximumTimesUsed}
            />
          ) : (
            <RCarHealth
              carId={carId}
              showRepairButton={showRepairButton}
              carParts={carParts}
              repairStage={repairStage}
              timesUsed={timesUsed}
              maximumTimesUsed={maximumTimesUsed}
              isTrialCar={isTrialCar}
              hideRepair={size === 'sm' || size === 'md'}
            />
          )
        )} */}


        {usageSinceLastRepair === nextRepairAt && (
          <RAlert
            position="absolute"
            bottom={{ base: '4rem', md: '8rem' }}
            right="0"
            maxW="23rem"
            minH="2.5rem"
            variant="error"
            description="Your car is damaged and needs to be repaired before it can be used again."
          />
        )}

        {showResultsFooter && playersParticipant && (
          <Flex w="full" maxH="2.5rem" bg="black.60">
            <Flex
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
              width="full"
              p="2"
              gap="2"
            >
              <Skeleton isLoaded={showAllResults}>
                <Flex gap="2" alignItems="center">
                  <IconStopWatchTimer h="1rem" w="auto" />
                  <Text>{playersParticipant?.time}</Text>
                </Flex>
              </Skeleton>
              <Skeleton isLoaded={showAllResults}>
                <Flex gap="2" alignItems="center">
                  <IconCheckeredFlag
                    h="1rem"
                    w="auto"
                    __css={{ path: { fill: 'fieryCoral.100' } }}
                  />
                  <Text
                    color={'fieryCoral.100'}
                    fontSize={{ base: '0.875rem', md: 'lg' }}
                  >
                    {playersParticipant?.points}
                    <Text
                      ml="1"
                      as="span"
                      fontSize={{ base: '0.75rem', md: '0.875rem' }}
                    >
                      PTS
                    </Text>
                  </Text>
                </Flex>
              </Skeleton>
              <Skeleton isLoaded={showAllResults}>
                <Flex gap="2" alignItems="center">
                  <RCurrency
                    size="md"
                    amount={((playersParticipant?.earnings || 0) / 100).toFixed(
                      2
                    )}
                  />
                </Flex>
              </Skeleton>
            </Flex>
          </Flex>
        )}
      </Flex>
    </CarBaseCard>
  );
};

export default CarCard;
