import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { tabsAnatomy } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const myVariant = definePartsStyle({
  tab: {
    _selected: {
      bgColor: 'bloodMoon.40',
      borderColor: 'bloodMoon.100',
      color: 'white.100',
      opacity: 1,
      borderTopRadius: '8px',
      _hover: {
        bgColor: 'bloodMoon.40',
      },
    },
    _hover: {
      bgColor: 'white.30',
      color: 'white.100',
      opacity: 1,
    },
    bgColor: 'white.10',
    color: 'white.80',
    border: '1px solid',
    borderColor: 'white.60',
    opacity: 0.6,
    fontWeight: 600,
    h: 9,
    w: '100%',
    textTransform: 'uppercase',
    borderTopRadius: '8px',
  },

  tablist: {
    gap: 3,
  },

  tabpanels: {
    mt: 4,
  },

  tabpanel: {
    padding: 0,
  },
});

const myVariant2 = definePartsStyle({
  tab: {
    _selected: {
      bgColor: 'white.20',
      borderColor: 'white.40',
      color: 'white.100',
      opacity: 1,
      borderTopRadius: '8px',
      _hover: {
        bgColor: 'white.20',
      },
    },
    _hover: {
      bgColor: 'white.30',
      color: 'white.100',
      opacity: 1,
    },
    bgColor: 'white.10',
    color: 'white.80',
    border: '1px solid',
    borderColor: 'white.60',
    opacity: 0.6,
    fontWeight: 600,
    h: 9,
    w: '100%',
    textTransform: 'uppercase',
    borderTopRadius: '8px',
  },

  tablist: {
    gap: 1,
  },

  tabpanels: {
    mt: 4,
  },

  tabpanel: {
    padding: 0,
  },
});

const tabWallet = definePartsStyle({
  tab: {
    w: 'full',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '14px',
    _selected: {
      background: 'bloodMoon.100',
      borderTopRadius: '20px',
    },
  },
  tablist: {
    background: 'linear-gradient(180deg, #1E1E1E 0%, #121212 100%)',
    borderTopRadius: '20px',
    p: 0,
    h: 49,
  },
  tabpanel: {
    padding: 0,
  },
});

export const tabTheme = defineMultiStyleConfig({
  variants: { myVariant, myVariant2, tabWallet },
  defaultProps: {
    variant: 'myVariant',
  },
});
