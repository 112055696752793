import { Flex, Text } from "@chakra-ui/react";
import { IconReceive, IconSendWallet } from "icons";

const WalletButtons = ({ setActiveSection }: { setActiveSection: (section: 'receive' | 'send' | 'wallet') => void }) => {
  return (
    <Flex
      gap={4}
      w="full"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        background="rgba(255, 255, 255, 0.1)"
        borderRadius="8px"
        w={{ base: "136px", md: "156px" }}
        h={{ base: "96px", md: "108px" }}
        gap="2"
        textTransform="uppercase"
        fontFamily="heading"
        onClick={() => setActiveSection('receive')}
        cursor="pointer"
        _hover={{
          background: "rgba(255, 255, 255, 0.2)"
        }}
        transition="all 0.2s ease-in-out"
      >
        <IconReceive />
        <Text
          fontSize="14px"
          lineHeight="20px"
          fontWeight="400"
          color="white.100"
        >
          Receive
        </Text>
      </Flex>
      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        background="rgba(255, 255, 255, 0.1)"
        borderRadius="8px"
        w={{ base: "136px", md: "156px" }}
        h={{ base: "96px", md: "108px" }}
        gap="2"
        textTransform="uppercase"
        fontFamily="heading"
        onClick={() => setActiveSection('send')}
        cursor="pointer"
        _hover={{
          background: "rgba(255, 255, 255, 0.2)"
        }}
        transition="all 0.2s ease-in-out"
      >
        <IconSendWallet />
        <Text
          fontSize="14px"
          lineHeight="20px"
          fontWeight="400"
          color="white.100"
        >
          Send
        </Text>
      </Flex>
    </Flex>
  )
}

export default WalletButtons;
