import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconSendWallet = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={40}
    fill="none"
    viewBox="0 0 41 40"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M38.688 17.734c.937.391 1.484 1.328 1.562 2.266a2.559 2.559 0 0 1-1.563 2.344l-35 15a2.08 2.08 0 0 1-.937.234 2.496 2.496 0 0 1-1.875-.86c-.703-.702-.86-1.874-.39-2.812L7.75 20 .484 6.172a2.62 2.62 0 0 1 .391-2.89C1.344 2.733 2.047 2.5 2.75 2.5c.313 0 .625.078.938.234l35 15ZM2.672 5.078 9.859 18.75h24.922L2.671 5.078Zm0 30L34.703 21.25H9.86L2.672 35.078Z"
    />
  </svg>
));
export default SvgIconSendWallet;
