import { Button, Flex, Text, ToastId, useToast } from "@chakra-ui/react"
import { IconArrow, IconCheckGreen, IconError, IconRetry, IconRetryWallet, IconSend, IconSendWallet, IconUsdcBig, IconUsdcPolygon, IconVextBig, IconVextPolygon } from "icons";
import { useRef, useState } from "react";
import { SenderWalletInput } from "../TransferFunds/SenderWalletInput";
import { TransferInput } from "../TransferFunds/TransferInput";
import { useWallet } from "context";
import { BalanceDataProps, useFunds } from "context/fundsContext";
import RToast from "components/Toast/Toast";
import { useQueryClient } from "@tanstack/react-query";
import IconMaticPolygon from "components/IconMaticPolygon/IconMaticPolygon";
import IconMaticBig from "components/IconMaticBig/IconMaticBig";

interface SendProps {
  setActiveSection: (section: "receive" | "send" | "wallet") => void;
  balanceData: BalanceDataProps;
  activeSendSection: "send" | "to" | "summary" | "success" | "error" | "transaction";
  setActiveSendSection: (section: "send" | "to" | "summary" | "success" | "error" | "transaction") => void;
}

const Send = ({ 
  setActiveSection, 
  balanceData,
  activeSendSection,
  setActiveSendSection 
}: SendProps) => {
  // Init User Wallet
  const { wallet } = useWallet();

  const { actualTokenSelected, changeSelectedToken } = useFunds();

  const queryClient = useQueryClient();

  const toast = useToast();
  const toastIdRef = useRef<ToastId | null>(null);

  const [addressToSend, setAddressToSend] = useState('');
  const [ammountToSend, setAmmountToSend] = useState('');
  const [isTransfering, setIsTransfering] = useState(false);

  const [isValueValid, setIsValueValid] = useState(false);
  const [isAddressValid, setIsAddressValid] = useState(false);

  const handleSendMatic = async () => {
    setIsTransfering(true);
    const { transferMatic } = await import(
      '../../../utils/blockchain/transferNative'
    );
    if (!wallet) return;
    const result = await transferMatic(
      wallet,
      addressToSend as `0x${string}`,
      ammountToSend
    );
    console.log('result', result);
    setIsTransfering(false);
  };

  const handleSendUsdc = async () => {
    setIsTransfering(true);
    const { transferUsdc } = await import(
      '../../../utils/blockchain/transferERC20'
    );
    if (!wallet) return;
    console.log('Params', {
      aaWallet: wallet,
      to: addressToSend,
      rawAmmount: ammountToSend,
    });
    const result = await transferUsdc(wallet, addressToSend, ammountToSend);
    console.log('result', result);
    setIsTransfering(false);
  };

  const handleSendVext = async () => {
    setIsTransfering(true);
    const { transferVext } = await import(
      '../../../utils/blockchain/transferERC20'
    );
    if (!wallet) return;
    const result = await transferVext(wallet, addressToSend, ammountToSend);
    console.log('result', result);
    setIsTransfering(false);
  };

  const handleSend = async () => {
    try {
      switch (actualTokenSelected) {
        case 'matic':
          await handleSendMatic();
          break;
        case 'vext':
          await handleSendVext();
          break;
        case 'usdc':
          await handleSendUsdc();
          break;
        default:
          break;
      }
      setActiveSendSection("success");
      queryClient.invalidateQueries({ queryKey: ['useWalletBalance'] });
    } catch (error) {
       // Show Error Toast if Error
       toastIdRef.current = toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="error"
            title={`Error sending USDC: ${error}`}
            onClose={() => toast.close(toastIdRef.current as ToastId)}
          />
        ),
      });
      setIsTransfering(false);
      setActiveSendSection("error");
      console.error(error);
    }
  }

  const formatBalance = (value: number | undefined) => {
    if (!value) return "0";
    const num = Number(value);
    return num.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 4
    });
  };

  return (
      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="flex-start"
        minH="550px"
        position="relative"
        w="full"
      >
       {activeSendSection === "send" && (
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          gap="2"
          p="4"
          w="full"
        >
          <Text
            fontSize="24px"
            lineHeight="28px"
            fontFamily="heading"
            fontWeight="400"
            color="white.100"
            mb="4"
          >
            Send
          </Text>
          <Flex
            background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
            _hover={{
              background: "linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)"
            }}
            transition="all 0.2s ease-in-out"
            borderRadius="8px"
            alignItems="center"
            border="1px solid rgba(255, 255, 255, 0.1)"
            gap="4"
            w="full"
            px="4"
            py="4"
            style={{
              backgroundOrigin: "border-box"
            }}
            onClick={() => {
              changeSelectedToken('usdc');
              setActiveSendSection("to");
            }}
            cursor="pointer"
          >
            <Flex
              alignItems="center"
              gap="1rem"
              w="full"
              justifyContent="space-between"
            >
              <Flex
                alignItems="center"
                gap="4"
              >
                <IconUsdcPolygon />
                <Flex
                  flexDir="column"
                  gap="1"
                >
                  <Text
                    fontSize="14px"
                    lineHeight="14px"
                    fontWeight="400"
                    fontFamily="heading"
                    color="white.80"
                  >
                    USDC
                  </Text>

                  <Text
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight="600"
                    color="white.80"
                  >
                    {formatBalance(balanceData?.usdc.readableBalance)} USDC
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
            _hover={{
              background: "linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)"
            }}
            transition="all 0.2s ease-in-out"
            borderRadius="8px"
            alignItems="center"
            border="1px solid rgba(255, 255, 255, 0.1)"
            gap="4"
            w="full"
            px="4"
            py="4"
            style={{
              backgroundOrigin: "border-box"
            }}
            onClick={() => {
              changeSelectedToken('matic');
              setActiveSendSection("to");
            }}
            cursor="pointer"
          >
            <Flex
              alignItems="center"
              gap="1rem"
              w="full"
              justifyContent="space-between"
            >
              <Flex
                alignItems="center"
                gap="4"
              >
                <IconMaticPolygon />
                <Flex
                  flexDir="column"
                  gap="1"
                >
                  <Text
                    fontSize="14px"
                    lineHeight="14px"
                    fontWeight="400"
                    fontFamily="heading"
                    color="white.80"
                  >
                    MATIC
                  </Text>

                  <Text
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight="600"
                    color="white.80"
                  >
                    {formatBalance(balanceData?.matic.readableBalance)} MATIC
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
            _hover={{
              background: "linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)"
            }}
            transition="all 0.2s ease-in-out"
            borderRadius="8px"
            alignItems="center"
            border="1px solid rgba(255, 255, 255, 0.1)"
            gap="4"
            w="full"
            px="4"
            py="4"
            style={{
              backgroundOrigin: "border-box"
            }}
            onClick={() => {
              changeSelectedToken('vext');
              setActiveSendSection("to");
            }}
            cursor="pointer"
          >
            <Flex
              alignItems="center"
              gap="1rem"
              w="full"
              justifyContent="space-between"
            >
              <Flex
                alignItems="center"
                gap="4"
              >
                <IconVextPolygon />
                <Flex
                  flexDir="column"
                  gap="1"
                >
                  <Text
                    fontSize="14px"
                    lineHeight="14px"
                    fontWeight="400"
                    fontFamily="heading"
                    color="white.80"
                  >
                    VEXT
                  </Text>

                  <Text
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight="600"
                    color="white.80"
                  >
                    {formatBalance(balanceData?.vext.readableBalance)} VEXT
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            position="absolute"
            bottom="0"
            w="full"
            h="82px"
            background="linear-gradient(180deg, #1E1E1E 0%, #121212 100%);"
            borderRadius="20px"
            borderBottomLeftRadius="0"
            borderBottomRightRadius="0"
            justifyContent="center"
            alignItems="center"
            gap="2"
            p="4"
            cursor="pointer"
            onClick={() => setActiveSection("wallet")}
          >
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              w="full"
              h="full"
              _hover={{
                background: "rgba(255, 255, 255, 0.2)"
              }}
              transition="all 0.2s ease-in-out"
            >
              <IconArrow />
            </Flex>
          </Flex>
        </Flex>
       )}

       {activeSendSection === "to" && (
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          gap="4"
          p="4"
          w="full"
        >
          <Text
            fontSize="24px"
            lineHeight="28px"
            fontFamily="heading"
            fontWeight="400"
            color="white.100"
          >
            Send {actualTokenSelected === 'usdc' ? 'USDC' : actualTokenSelected === 'matic' ? 'MATIC' : 'VEXT'}
          </Text>
          
          {actualTokenSelected === 'usdc' ? (
            <IconUsdcBig />
          ) : actualTokenSelected === 'matic' ? (
            <IconMaticBig />
          ) : (
            <IconVextBig />
          )}

          <SenderWalletInput
            value={addressToSend}
            onChange={setAddressToSend}
            setCanSubmit={setIsAddressValid}
          />

          <TransferInput
            value={ammountToSend}
            onChange={setAmmountToSend}
            setCanSubmit={setIsValueValid}
          />

          <Flex
            position="absolute"
            bottom="0"
            w="full"
            h="82px"
            background="linear-gradient(180deg, #1E1E1E 0%, #121212 100%);"
            borderRadius="20px"
            borderBottomLeftRadius="0"
            borderBottomRightRadius="0"
            justifyContent="center"
            alignItems="center"
            gap="2"
            p="4"
          >
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              h="full"
              w="70px"
              _hover={{
                background: "rgba(255, 255, 255, 0.2)"
              }}
              transition="all 0.2s ease-in-out"
              cursor="pointer"
              onClick={() => setActiveSendSection("send")}
            >
              <IconArrow />
            </Flex>
            
            <Flex
              as="button"
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              h="full"
              w="full"
              _hover={{
                background: isValueValid && isAddressValid ? "rgba(255, 255, 255, 0.2)" : "rgba(255, 255, 255, 0.1)"
              }}
              transition="all 0.2s ease-in-out"
              cursor={isValueValid && isAddressValid ? "pointer" : "not-allowed"}
              onClick={() => setActiveSendSection("summary")}
              fontFamily="heading"
              gap="2"
              disabled={!isValueValid || !isAddressValid}
              opacity={isValueValid && isAddressValid ? 1 : 0.5}
            >
              NEXT
              <IconArrow 
                transform="rotate(180deg)"
              />
            </Flex>
          </Flex>
        </Flex>
       )}

       {activeSendSection === "summary" && (
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          gap="4"
          p="4"
          w="full"
        >
          <Text
            fontSize="24px"
            lineHeight="28px"
            fontFamily="heading"
            fontWeight="400"
            color="white.100"
          >
            Confirm Send
          </Text>

          <Flex
            alignItems="center"
            gap="2"
          >
            {actualTokenSelected === 'usdc' ? (
              <IconUsdcBig 
                w="5rem"
                h="5rem"
              />
            ) : actualTokenSelected === 'matic' ? (
              <IconMaticBig 
                width="5rem"
                height="5rem"
              />
            ) : (
              <IconVextBig 
                width="5rem"
                height="5rem"
              />
            )}

            <Text
              background="linear-gradient(270deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);"
              backgroundClip="text"
            >
              ------
            </Text>

            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="full"
              justifyContent="center"
              alignItems="center"
              w="5rem"
              h="5rem"
            >
              <IconSendWallet />
            </Flex>
          </Flex>

          <Text
            fontSize="48px"
            lineHeight="48px"
            fontWeight="500"
            color="white.100"
          >
            {`${formatBalance(Number(ammountToSend))}`}
          </Text>

          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            gap="0.5"
            w="full"
          >
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderTopRadius="8px"
              justifyContent="space-between"
              alignItems="center"
              w="full"
              p="4"
            >
              <Text
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                color="white.100"
              >
                To
              </Text>

              <Text
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                color="white.100"
                maxW="155px"
                noOfLines={1}
              >
                {`${addressToSend.slice(0, 6)}...${addressToSend.slice(-4)}`}
              </Text>
            </Flex>

            <Flex
              background="rgba(255, 255, 255, 0.1)"
              justifyContent="space-between"
              alignItems="center"
              borderBottomRadius="8px"
              w="full"
              p="4"
            >
              <Text
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                color="white.100"
              >
                Network
              </Text>

              <Text
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                color="white.100"
              >
                Polygon
              </Text>
            </Flex>

            {/* <Flex
              justifyContent="space-between"
              alignItems="center"
              w="full"
              p="4"
              background="rgba(255, 255, 255, 0.1)"
              borderBottomRadius="8px"
            >
              <Text
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                color="white.100"
              >
                Network Fee
              </Text>

              <Text
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                color="white.100"
              >
                Up to $0.000009
              </Text>
            </Flex> */}
          </Flex>

          <Flex
            position="absolute"
            bottom="0"
            w="full"
            h="82px"
            background="linear-gradient(180deg, #1E1E1E 0%, #121212 100%);"
            borderRadius="20px"
            borderBottomLeftRadius="0"
            borderBottomRightRadius="0"
            justifyContent="center"
            alignItems="center"
            gap="2"
            p="4"
          >
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              h="full"
              w="70px"
              _hover={{
                background: "rgba(255, 255, 255, 0.2)"
              }}
              transition="all 0.2s ease-in-out"
              cursor="pointer"
              onClick={() => setActiveSendSection("to")}
            >
              <IconArrow />
            </Flex>
            
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              as="button"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              h="full"
              w="full"
              _hover={{
                background: "rgba(255, 255, 255, 0.2)"
              }}
              transition="all 0.2s ease-in-out"
              cursor={isTransfering ? "not-allowed" : "pointer"}
              fontFamily="heading"
              gap="2"
              color="white.80"
              onClick={handleSend}
              disabled={isTransfering}
            >
              {isTransfering ? "SENDING..." : (
                <>
                  SEND
                  <IconSend />
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
       )}

       {activeSendSection === "success" && (
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          gap="4"
          p="4"
          w="full"
          h="full"
        >
          <Flex 
            background="linear-gradient(180deg, rgba(38, 166, 154, 0) 0%, rgba(38, 166, 154, 0.32) 100%);"
            w="full"
            h="full"
            position="absolute"
            top="0"
            left="0"
          />
          <Text
            fontSize="24px"
            lineHeight="28px"
            fontFamily="heading"
            fontWeight="400"
            color="white.100"
          >
            Sent!
          </Text>

          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            gap="4"
            mt="5rem"
          >
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="full"
              justifyContent="center"
              alignItems="center"
              w="5rem"
              h="5rem"
            >
              <IconCheckGreen 
                w="2rem"
                h="2rem"
              />
            </Flex>

            <Text
              fontSize="14px"
              lineHeight="18px"
              fontWeight="500"
              color="white.80"
            >
              Your tokens were successfully sent to {`${addressToSend.slice(0, 6)}...${addressToSend.slice(-4)}`}
            </Text>

            <Button
              w="full"
              variant="unstyled"
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="4px"
              justifyContent="center"
              fontSize="14px"
              lineHeight="18px"
              fontWeight="500"
              color="white.80"
              alignItems="center"
              _hover={{
                background: "rgba(255, 255, 255, 0.2)"
              }}
              transition="all 0.2s ease-in-out"
              onClick={() => setActiveSendSection("transaction")}
            >
              VIEW TRANSACTION
            </Button>
          </Flex>

          <Flex
            position="absolute"
            bottom="0"
            w="full"
            h="82px"
            background="linear-gradient(180deg, #1E1E1E 0%, #121212 100%);"
            borderRadius="20px"
            borderBottomLeftRadius="0"
            borderBottomRightRadius="0"
            justifyContent="center"
            alignItems="center"
            gap="2"
            p="4"
            cursor="pointer"
            onClick={() => {
              setActiveSection("wallet")
              setActiveSendSection("send")
            }}
          >
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              w="full"
              h="full"
              _hover={{
                background: "rgba(255, 255, 255, 0.2)"
              }}
              transition="all 0.2s ease-in-out"
              fontFamily="heading"
              fontSize="16px"
              lineHeight="16px"
              fontWeight="400"
              color="white.80"
            >
              CLOSE
            </Flex>
          </Flex>
        </Flex>
       )}


       {activeSendSection === "error" && (
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          gap="4"
          p="4"
          w="full"
          h="full"
        >
          <Flex
            background="linear-gradient(180deg, rgba(216, 56, 50, 0) 0%, rgba(216, 56, 50, 0.32) 100%);"
            w="full"
            h="full"
            position="absolute"
            top="0"
            left="0"
          />
          <Text
            fontSize="24px"
            lineHeight="28px"
            fontFamily="heading"
            fontWeight="400"
            color="white.100"
          >
            Unable to send
          </Text>

          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            gap="4"
            mt="5rem"
          >
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="full"
              justifyContent="center"
              alignItems="center"
              w="5rem"
              h="5rem"
            >
              <IconError />
            </Flex>

            <Text
              fontSize="14px"
              lineHeight="18px"
              fontWeight="500"
              color="white.80"
            >
              There was an error attempting to send tokens to {`${addressToSend.slice(0, 6)}...${addressToSend.slice(-4)}`}
            </Text>
          </Flex>

          <Flex
            position="absolute"
            bottom="0"
            w="full"
            h="82px"
            background="linear-gradient(180deg, #1E1E1E 0%, #121212 100%);"
            borderRadius="20px"
            borderBottomLeftRadius="0"
            borderBottomRightRadius="0"
            justifyContent="center"
            alignItems="center"
            gap="2"
            p="4"
          >
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              w="200px"
              h="full"
              _hover={{
                background: "rgba(255, 255, 255, 0.2)"
              }}
              transition="all 0.2s ease-in-out"
              fontFamily="heading"
              fontSize="16px"
              lineHeight="16px"
              fontWeight="400"
              color="white.80"
              cursor="pointer"
              onClick={() => setActiveSection("wallet")}
            >
              CLOSE
            </Flex>

            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              w="full"
              h="full"
              _hover={{
                background: "rgba(255, 255, 255, 0.2)"
              }}
              transition="all 0.2s ease-in-out"
              fontFamily="heading"
              fontSize="16px"
              lineHeight="16px"
              fontWeight="400"
              color="white.80"
              cursor="pointer"
              onClick={() => setActiveSendSection("to")}
              gap="2"
            >
              RETRY
              <IconRetryWallet />
            </Flex>
          </Flex>
        </Flex>
       )}

      {activeSendSection === "transaction" && (
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          gap="4"
          p="4"
          w="full"
        >
          <Flex 
            background="linear-gradient(180deg, rgba(38, 166, 154, 0) 0%, rgba(38, 166, 154, 0.32) 100%);"
            w="full"
            h="full"
            position="absolute"
            top="0"
            left="0"
          />
          <Text
            fontSize="24px"
            lineHeight="28px"
            fontFamily="heading"
            fontWeight="400"
            color="white.100"
          >
            Sent
          </Text>

          <Flex
            alignItems="center"
            gap="2"
          >
            {actualTokenSelected === 'usdc' ? (
              <IconUsdcBig 
                w="5rem"
                h="5rem"
              />
            ) : actualTokenSelected === 'matic' ? (
              <IconMaticBig 
                width="5rem"
                height="5rem"
              />
            ) : (
              <IconVextBig 
                width="5rem"
                height="5rem"
              />
            )}

            <Text
              background="linear-gradient(270deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);"
              backgroundClip="text"
            >
              ------
            </Text>

            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="full"
              justifyContent="center"
              alignItems="center"
              w="5rem"
              h="5rem"
            >
              <IconCheckGreen 
                w="2rem"
                h="2rem"
              />
            </Flex>
          </Flex>

          <Text
            fontSize="48px"
            lineHeight="48px"
            fontWeight="500"
            color="white.100"
          >
            ${`${ammountToSend}`}
          </Text>

          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            gap="0.5"
            w="full"
          >
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderTopRadius="8px"
              justifyContent="space-between"
              alignItems="center"
              w="full"
              p="4"
            >
              <Text
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                color="white.100"
              >
                To
              </Text>

              <Text
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                color="white.100"
                maxW="155px"
                noOfLines={1}
              >
                {`${addressToSend.slice(0, 6)}...${addressToSend.slice(-4)}`}
              </Text>
            </Flex>

            <Flex
              background="rgba(255, 255, 255, 0.1)"
              justifyContent="space-between"
              alignItems="center"
              w="full"
              p="4"
              borderBottomRadius="8px"
            >
              <Text
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                color="white.100"
              >
                Network
              </Text>

              <Text
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                color="white.100"
              >
                Polygon
              </Text>
            </Flex>

            {/* <Flex
              justifyContent="space-between"
              alignItems="center"
              w="full"
              p="4"
              background="rgba(255, 255, 255, 0.1)"
              borderBottomRadius="8px"
            >
              <Text
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                color="white.100"
              >
                Network Fee
              </Text>

              <Text
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
                color="white.100"
              >
                Up to $0.000009
              </Text>
            </Flex> */}
          </Flex>

          <Flex
            position="absolute"
            bottom="0"
            w="full"
            h="82px"
            background="linear-gradient(180deg, #1E1E1E 0%, #121212 100%);"
            borderRadius="20px"
            borderBottomLeftRadius="0"
            borderBottomRightRadius="0"
            justifyContent="center"
            alignItems="center"
            gap="2"
            p="4"
            cursor="pointer"
            onClick={() => setActiveSection("wallet")}
          >
            <Flex
              background="rgba(255, 255, 255, 0.1)"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              w="full"
              h="full"
              _hover={{
                background: "rgba(255, 255, 255, 0.2)"
              }}
              transition="all 0.2s ease-in-out"
              fontFamily="heading"
              fontSize="16px"
              lineHeight="16px"
              fontWeight="400"
              color="white.80"
            >
              CLOSE
            </Flex>
          </Flex>
        </Flex>
       )}
      
    </Flex>
  )
}

export default Send;