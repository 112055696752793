import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPoints = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={12}
    fill="none"
    viewBox="0 0 13 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M12.25 5.25c.398 0 .75.352.75.75 0 .422-.352.75-.75.75h-6A.74.74 0 0 1 5.5 6c0-.398.328-.75.75-.75h6ZM6.25 3a.74.74 0 0 1-.75-.75c0-.398.328-.75.75-.75h6c.398 0 .75.352.75.75 0 .422-.352.75-.75.75h-6Zm6 6c.398 0 .75.352.75.75 0 .422-.352.75-.75.75h-6a.74.74 0 0 1-.75-.75c0-.398.328-.75.75-.75h6ZM1.937 5.25a.542.542 0 0 1-.562-.563c0-.304.234-.562.563-.562h.375V2.297a.571.571 0 0 1-.68-.258c-.164-.258-.07-.61.187-.773l.75-.422a.494.494 0 0 1 .563 0 .539.539 0 0 1 .304.469v2.812h.376c.304 0 .562.21.562.563a.555.555 0 0 1-.563.562H1.938Zm2.25 4.875c.305 0 .563.258.563.586a.55.55 0 0 1-.563.539H1.54a.585.585 0 0 1-.516-.352c-.07-.21-.023-.468.165-.609l1.945-1.758a.543.543 0 0 0 .07-.797.46.46 0 0 0-.398-.21.518.518 0 0 0-.446.14l-.304.258a.584.584 0 0 1-.797-.07.584.584 0 0 1 .07-.797l.281-.258c.352-.305.82-.445 1.266-.399.469.024.89.235 1.195.61a1.697 1.697 0 0 1-.187 2.367l-.867.75h1.171Z"
    />
  </svg>
));
export default SvgIconPoints;
