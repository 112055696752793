import { Dispatch, SetStateAction, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import {
  IconCheckGreen,
  IconCheckSquare,
  IconMedal,
  IconMoneyBag,
  IconTicketTournament,
  IconUncheckGrey,
  IconLock,
} from 'icons';
import IconTicket from 'components/IconTicket/IconTicket';
import {
  activeCarSelectBgGradient,
  carSelectBgGradient,
  leaderboardTicketLockedGradient,
} from 'utils/themeConstants';
import { RaceThumbnail } from 'components/Races2/RaceThumbnail/RaceThumbnail';
import { Races } from 'api/generated/graphql';
import { SVGIcon } from 'types';
import RImage from 'components/Image/Image';
import IconSilverTicket from 'components/IconSilverTicket/IconSilverTicket';

interface RaceTicketProps {
  race?: Races;
  numberOfTickets?: number;
  type?: 'racino' | 'leaderboard' | 'tournament' | 'weeklyTournament';
  isSelected?: boolean;
  setIsSelected?: Dispatch<SetStateAction<boolean | undefined>>;
  isLocked?: boolean;
}

export const RaceTicket = ({
  race,
  numberOfTickets = 0,
  type = 'racino',
  isSelected = true,
  setIsSelected,
  isLocked = false,
}: RaceTicketProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const borderColor = isSelected
    ? 'treasuredTeal.100'
    : isHovered
    ? isLocked
      ? 'rgba(102, 102, 102, 1)'
      : 'cerebralGrey.100'
    : 'carbon.100';

  return (
    <Flex
      w="full"
      h={{ base: '4rem', md: '4.5rem' }}
      cursor={isLocked ? 'not-allowed' : 'pointer'}
      borderWidth={2}
      borderColor={borderColor}
      borderRadius="0.25rem"
      mb="0.25rem"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (isLocked) return;
        if (setIsSelected) setIsSelected((oldState) => !oldState);
      }}
    >
      <Flex
        pos="relative"
        w={{ base: '6.875rem', md: '7.5rem' }}
        h="full"
        bg="black"
        alignItems="center"
        justifyContent="center"
      >
        {type === 'leaderboard' ? (
          <RImage
            alt="leaderboard jackpot"
            src={require('public/images/tickets/leaderboard-jackpot.png')}
            w="full"
            h="full"
          />
        ) : (
          race && (
            <RaceThumbnail
              trackName={race.track.frontendName}
              difficulty={race.difficulty}
              tier={race.tier.name.toLowerCase() === 'tournament' || race.tier.name.toLowerCase() === 'weeklytournament' ? 'tournament' : 'race'}
            />
          )
        )}
      </Flex>
      <Flex
        h="full"
        w={{ base: 'calc(100% - 110px)', md: 'calc(100% - 120px)' }}
        py={{ base: '0.5rem', md: '0.75rem' }}
        px={{ base: '0.5rem', md: '1rem' }}
        justifyContent="space-between"
        bg={
          isSelected
            ? activeCarSelectBgGradient
            : isLocked
            ? leaderboardTicketLockedGradient
            : carSelectBgGradient
        }
      >
        <Flex h="full" flexDir="column" justifyContent="space-between">
          <BulletPoint
            Icon={IconMedal}
            text={type === 'leaderboard' ? 'Earn Points' : 'Defeat Rivals'}
            subtitle={type === 'leaderboard' ? 'Daily' : null}
          />
          <BulletPoint
            Icon={IconMoneyBag}
            text={
              type === 'leaderboard'
                ? 'Win Bigger'
                : type === 'tournament'
                ? 'Jackpot Payout'
                : 'Win Big'
            }
            subtitle={type !== 'racino' ? '$100+' : null}
          />
        </Flex>

        <Flex
          h="full"
          alignItems="center"
          gap={{ base: '0.25rem', md: '0.5rem' }}
        >
          {type === 'tournament' ? (
            <IconSilverTicket
              width={{ base: '1.5rem', md: '2.5rem' }}
              height={{ base: '1.5rem', md: '2.5rem' }}
            />
          ) : type === 'weeklyTournament' ? (
            <IconTicketTournament
              w={{ base: '1.5rem', md: '2.5rem' }}
              h={{ base: '1.5rem', md: '2.5rem' }}
            />
          ) : (
            <IconTicket
              w={{ base: '1.5rem', md: '2.5rem' }}
              h={{ base: '1.5rem', md: '2.5rem' }}
            />
          )}
          <Text
            fontSize={{
              base: '1.5rem',
              md: '2rem',
            }}
            color="white.100"
            fontWeight={600}
            mr={{ base: '0.25rem', md: '0.5rem' }}
          >
            {numberOfTickets}
          </Text>
          {isSelected && setIsSelected ? (
            <IconCheckSquare h="1rem" />
          ) : isSelected ? (
            <IconCheckGreen h="1rem" />
          ) : isLocked ? (
            <IconLock
              h="1rem"
              w="1rem"
              __css={{
                path: {
                  fill: 'white.60',
                },
              }}
            />
          ) : (
            <IconUncheckGrey h="1rem" />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const BulletPoint = ({
  Icon,
  text,
  subtitle,
}: {
  text: string;
  Icon: SVGIcon;
  subtitle?: string | null;
}) => {
  return (
    <Flex alignItems="center" gap="0.5rem">
      <Icon w="1rem" h="1rem" />
      <Text
        fontSize={{ base: '0.625rem', md: '0.75rem' }}
        lineHeight={{ base: '0.75rem', md: '0.875rem' }}
        color="white.80"
        textTransform="capitalize"
        fontWeight={600}
      >
        {text}
      </Text>
      {subtitle && (
        <Text
          fontSize={{ base: '0.625rem', md: '0.75rem' }}
          lineHeight={{ base: '0.75rem', md: '0.875rem' }}
          color="treasuredTeal.100"
          textTransform="uppercase"
          fontWeight={600}
        >
          {subtitle}
        </Text>
      )}
    </Flex>
  );
};
