import { Flex, Text } from "@chakra-ui/react";
import { GetTicketsAvailableQuery } from "api/generated/graphql";
import IconMaticPolygon from "components/IconMaticPolygon/IconMaticPolygon";
import IconSilverTicket from "components/IconSilverTicket/IconSilverTicket";
import IconTicket from "components/IconTicket/IconTicket";
import { BalanceDataProps } from "context/fundsContext";
import { IconTicketTournament, IconUsdcPolygon, IconVextPolygon } from "icons";


const WalletSummary = ({ balanceData, ticketsAvailable }: { balanceData: BalanceDataProps, ticketsAvailable: GetTicketsAvailableQuery | undefined }) => {
  const formatBalance = (value: number | undefined) => {
    if (!value) return "0";
    const num = Number(value);
    return num.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 4
    });
  };

  return (
    <Flex
      flexDir="column"
      gap="1"
      w="full"
    >
      <Flex
        background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
        borderRadius="8px"
        px={{ base: "1rem", md: "1.5rem" }}
        py={{ base: "0.5rem", md: "0.75rem" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid rgba(255, 255, 255, 0.1)"
        style={{
          backgroundOrigin: 'border-box',
        }}
      >
        <Flex
          alignItems="center"
          gap="3"
        >
          <IconUsdcPolygon />
          <Flex
            flexDir="column"
          >
            <Text
              fontSize="14px"
              lineHeight="18px"
              fontWeight="400"
              color="white.80"
              fontFamily="heading"
            >
              USDC
            </Text>
            <Text
              fontSize="12px"
              lineHeight="16px"
              fontWeight="600"
              color="white.80"
            >
              {formatBalance(balanceData?.usdc.readableBalance)} USDC
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexDir="column"
          alignItems="flex-end"
        >
          <Text
            fontSize="14px"
            lineHeight="18px"
            fontWeight="600"
            color="white.80"
          >
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(Number(balanceData?.usdc.usdValue))}
          </Text>
          {/* <Text
            fontSize="14px"
            lineHeight="18px"
            fontWeight="600"
            color="treasuredTeal.100"
          >
            +$0.05
          </Text> */}
        </Flex>
      </Flex>

      <Flex
        background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
        borderRadius="8px"
        px={{ base: "1rem", md: "1.5rem" }}
        py={{ base: "0.5rem", md: "0.75rem" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid rgba(255, 255, 255, 0.1)"
        style={{
          backgroundOrigin: 'border-box',
        }}
      >
        <Flex
          alignItems="center"
          gap="3"
        >
          <IconMaticPolygon />
          <Flex
            flexDir="column"
          >
            <Text
              fontSize="14px"
              lineHeight="18px"
              fontWeight="400"
              color="white.80"
              fontFamily="heading"
            >
              MATIC
            </Text>
            <Text
              fontSize="12px"
              lineHeight="16px"
              fontWeight="600"
              color="white.80"
            >
              {formatBalance(balanceData?.matic.readableBalance)} MATIC
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexDir="column"
          alignItems="flex-end"
        >
          <Text
            fontSize="14px"
            lineHeight="18px"
            fontWeight="600"
            color="white.80"
          >
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(Number(balanceData?.matic.usdValue))}
          </Text>
          {/* <Text
            fontSize="14px"
            lineHeight="18px"
            fontWeight="600"
            color="treasuredTeal.100"
          >
            +$0.05
          </Text> */}
        </Flex>
      </Flex>

      <Flex
        background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
        borderRadius="8px"
        px={{ base: "1rem", md: "1.5rem" }}
        py={{ base: "0.5rem", md: "0.75rem" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid rgba(255, 255, 255, 0.1)"
        style={{
          backgroundOrigin: 'border-box',
        }}
      >
        <Flex
          alignItems="center"
          gap="3"
        >
          <IconVextPolygon />
          <Flex
            flexDir="column"
          >
            <Text
              fontSize="14px"
              lineHeight="18px"
              fontWeight="400"
              color="white.80"
              fontFamily="heading"
            >
              VEXT
            </Text>
            <Text
              fontSize="12px"
              lineHeight="16px"
              fontWeight="600"
              color="white.80"
            >
              {formatBalance(balanceData?.vext.readableBalance)} VEXT
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexDir="column"
          alignItems="flex-end"
        >
          <Text
            fontSize="14px"
            lineHeight="18px"
            fontWeight="600"
            color="white.80"
          >
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(Number(balanceData?.vext.usdValue))}
          </Text>
          {/* <Text
            fontSize="14px"
            lineHeight="18px"
            fontWeight="600"
            color="treasuredTeal.100"
          >
            +$0.05
          </Text> */}
        </Flex>
      </Flex>

      <Flex
        background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
        borderRadius="8px"
        px={{ base: "1rem", md: "1.5rem" }}
        py={{ base: "0.5rem", md: "0.75rem" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid rgba(255, 255, 255, 0.1)"
        style={{
          backgroundOrigin: 'border-box',
        }}
      >
        <Flex
          alignItems="center"
          gap="3"
        >
          <IconTicket 
            width="32px"
            height="32px"
          />
          <Text
            fontSize="14px"
            lineHeight="18px"
            fontWeight="400"
            color="white.80"
            fontFamily="heading"
          >
            Tickets
          </Text>
        </Flex>
        
        <Text
          fontSize="18px"
          lineHeight="24px"
          fontWeight="600"
          color="white.80"
        >
          {ticketsAvailable?.ticketsAvailable.ticketsAvailable}
        </Text>
      </Flex>

      <Flex
        background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
        borderRadius="8px"
        px={{ base: "1rem", md: "1.5rem" }}
        py={{ base: "0.5rem", md: "0.75rem" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid rgba(255, 255, 255, 0.1)"
        style={{
          backgroundOrigin: 'border-box',
        }}
      >
        <Flex
          alignItems="center"
          gap="3"
        >
          <IconTicketTournament 
            width="34px"
            height="34px"
          />
          <Text
            fontSize="14px"
            lineHeight="18px"
            fontWeight="400"
            color="white.80"
            fontFamily="heading"
          >
            Tickets
          </Text>
        </Flex>
        
        <Text
          fontSize="18px"
          lineHeight="24px"
          fontWeight="600"
          color="white.80"
        >
          {ticketsAvailable?.ticketsAvailable.weeklyTournamentTicketsAvailable}
        </Text>
      </Flex>

      <Flex
        background="linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)"
        borderRadius="8px"
        px={{ base: "1rem", md: "1.5rem" }}
        py={{ base: "0.5rem", md: "0.75rem" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid rgba(255, 255, 255, 0.1)"
        style={{
          backgroundOrigin: 'border-box',
        }}
      >
        <Flex
          alignItems="center"
          gap="3"
        >
          <IconSilverTicket 
            width="32px"
            height="32px"
          />
          <Text
            fontSize="14px"
            lineHeight="18px"
            fontWeight="400"
            color="white.80"
            fontFamily="heading"
          >
            Tickets
          </Text>
        </Flex>
        
        <Text
          fontSize="18px"
          lineHeight="24px"
          fontWeight="600"
          color="white.80"
        >
          {ticketsAvailable?.ticketsAvailable.tournamentTicketsAvailable}
        </Text>
      </Flex>
    </Flex>
  )
}

export default WalletSummary;