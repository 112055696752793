import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { IconDeposit, IconMarket, IconWithdraw } from 'icons';
import IconTicket from 'components/IconTicket/IconTicket';
import { EndedActivityDate } from 'components/EndedActivityDate/EndedActivityDate';

interface ItemProps {
  message: string;
  timestamp: string;
  action: string;
  value: string;
  currency: string;
}

const icons = {
  DEPOSIT: <IconDeposit minW={5} minH={5} />,
  WITHDRAW: <IconWithdraw minW={5} minH={5} __css={{ path: { fill: 'coralQuartz.100' } }} />,
  TICKETS_USED: <IconTicket height="1.5rem" width="1.5rem" />,
  TICKETS_PURCHASED: <IconTicket height="1.5rem" width="1.5rem" />,
  MARKETPLACE_PURCHASED: (
    <IconMarket
      minW={5}
      minH={5}
      __css={{
        path: { fill: 'coralQuartz.100' },
      }}
    />
  ),
  MARKETPLACE_SELL: (
    <IconMarket
      minW={5}
      minH={5}
      __css={{
        path: { fill: 'treasuredTeal.100' },
      }}
    />
  ),
};

export const ActivityItem = ({
  action,
  currency,
  message,
  timestamp,
  value,
}: ItemProps) => {
  const date = new Date(timestamp);
  const formatter = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const formattedDate = formatter.format(date);

  return (
    <Flex
      flexDir="row"
      justify="space-between"
      px={4}
      py={2}
      align={'center'}
      background="linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)"
      borderRadius="8px"
      border="1px solid rgba(255, 255, 255, 0.1)"
      _hover={{
        background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
      transition="background 0.3s ease"
      backdropFilter="auto"
      backdropBlur="20px"
    >
      <Flex align={'center'} gap={4}>
        {icons[action as keyof typeof icons]}
        <Flex flexDir={'column'}>
          <Heading
            textTransform="uppercase"
            color="whiteAlpha.800"
            fontWeight="400"
            fontSize={'14px'}
            maxW="100%"
          >
            {message}
          </Heading>
          <EndedActivityDate timeLeft={timestamp} />
        </Flex>
      </Flex>

      <Stack align="flex-end" gap={0}>
        <Text
          textTransform="uppercase"
          fontSize={'14px'}
          fontFamily={'heading'}
          color="whiteAlpha.800"
        >
          {value}
        </Text>
        <Text
          textTransform="uppercase"
          fontSize={'10px'}
          fontFamily={'heading'}
          color="whiteAlpha.600"
        >
          {currency}
        </Text>
      </Stack>
    </Flex>
  );
};
