/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useAccount } from 'wagmi';
import {
  EVMAAWallet,
  EVMBlockchainIncludingTestnet,
} from '@crossmint/client-sdk-aa';

import { useSetVerifyLogin, useUserSettings } from 'hooks';

interface WalletProviderProps {
  children: ReactNode;
}

const chain = process.env.NEXT_PUBLIC_BLOCKCHAIN_NETWORK as EVMBlockchainIncludingTestnet;

interface WalletContextProps {
  wallet: EVMAAWallet<typeof chain, unknown> | null;
  isWalletCreated: boolean;
  isCreatingWallet: boolean;
  isOperationBlocked: boolean;
  userHasWalletInDatabase: boolean;
  handleClearWalletData: () => void;
  handleSaveNewWallet: (props: {
    email: string;
    accessToken: string;
  }) => Promise<void>;
  setIsOperationBlocked: (isBlocked: boolean) => void;
}

const WalletContext = createContext<WalletContextProps>({
  wallet: null,
  isWalletCreated: false,
  isCreatingWallet: false,
  userHasWalletInDatabase: false,
  isOperationBlocked: false,
  handleClearWalletData: () => undefined,
  handleSaveNewWallet: async () => Promise.resolve(undefined),
  setIsOperationBlocked: () => undefined,
});

export const WalletProvider: React.FC<WalletProviderProps> = ({ children }) => {
  const [wallet, setWallet] = useState<EVMAAWallet<
    typeof chain,
    unknown
  > | null>(null);
  const [isWalletStored, setIsWalletStored] = useState<boolean>(false);
  const [isCreatingWallet, setIsCreatingWallet] = useState<boolean>(false);
  const [isOperationBlocked, setIsOperationBlocked] = useState<boolean>(false);

  const { getUserSettings, changeUserSettings } = useUserSettings();
  const { connector } = useAccount();
  const { mutateAsync } = useSetVerifyLogin();

  const isWalletCreated = wallet !== null;
  const userHasWalletInDatabase =
    !!getUserSettings.data?.getUser?.walletAddress;
  const userExists = !!getUserSettings.data?.getUser?.id;
  const walletAddress = wallet?.getAddress();

  const handleClearWalletData = () => {
    connector?.disconnect().then(() => console.log('DISCONNECTED'));
    setIsWalletStored(false);
    setIsOperationBlocked(false);
    setWallet(null);
  };

  const handleStoreWallet = useCallback(async () => {
    if (!wallet) return;

    try {
      console.log('Storing wallet in database', walletAddress, wallet.chain);

      changeUserSettings.mutate({
        walletAddress,
        walletChain: wallet.chain,
      });
    } catch (error) {
      console.log(error);
    }
  }, [wallet, changeUserSettings]);

  const handleSaveNewWallet = useCallback(
    async ({ email, accessToken }: { email: string; accessToken: string }) => {
      if (isWalletCreated || wallet) return;

      const shouldCreateWallet = typeof window !== 'undefined';

      if (shouldCreateWallet) {
        try {
          setIsCreatingWallet(true);
          const wallets = await import('utils/crossmint/walletsv2');
          const newWallet = await wallets.createAAWalletSigner(
            email,
            accessToken
          );

          console.log({ newWallet });

          setWallet(newWallet);
        } catch (error) {
          console.error('Error on saving wallet', error);
        } finally {
          setIsCreatingWallet(false);
        }
      }
    },
    [wallet, isWalletCreated]
  );

  useEffect(() => {
    const shouldStoreWallet =
      userExists &&
      wallet &&
      !isWalletStored &&
      (!userHasWalletInDatabase ||
        (userHasWalletInDatabase &&
          getUserSettings.data?.getUser?.walletAddress !== walletAddress));

    if (shouldStoreWallet) {
      handleStoreWallet();
      if (!isWalletStored) setIsWalletStored(true);
    }
  }, [wallet, getUserSettings, isWalletStored, changeUserSettings]);

  useEffect(() => {
    const createTrialCar = async () => {
      try {
        await mutateAsync();
        console.log('Trial car created successfully');
        localStorage.removeItem('hasSeenRewardsModal')
      } catch (error) {
        console.error('Error creating trial car:', error);
        // Tenta novamente após 5 segundos em caso de erro
        setTimeout(createTrialCar, 10000);
      }
    };

    const shouldCreateTrialCar = 
      userExists && 
      wallet && 
      isWalletStored && 
      userHasWalletInDatabase &&
      !getUserSettings.data?.getUser?.loginVerified;

    if (shouldCreateTrialCar) {
      createTrialCar();
    }
  }, [userExists, wallet, isWalletStored, userHasWalletInDatabase, mutateAsync]);

  return (
    <WalletContext.Provider
      value={{
        wallet,
        isWalletCreated,
        isCreatingWallet,
        isOperationBlocked,
        userHasWalletInDatabase,
        handleClearWalletData,
        handleSaveNewWallet,
        setIsOperationBlocked,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
