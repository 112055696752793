import { Flex, Heading, UnorderedList, ListItem, Text, Show } from '@chakra-ui/react';
import AidrianaImage from 'components/Aidriana/Image';

interface AidrianaTipsProps {
  tips: string[] | undefined;
  header?: boolean;
}

export const AidrianaTips = ({ tips, header = true }: AidrianaTipsProps) => {
  return (
    <Flex px={4} w="full" h="full" gap={4} py={4}>
      <Show above="md">
        <AidrianaImage
          width={160}
          height={160}
          alt="Aidriana"
          bgGradient="linear-gradient(362.18deg, #E31B23 -21.88%, rgba(227, 27, 35, 0) 58.12%)"
        />
      </Show>
      <Flex
        flexDir="column"
        gap={2}
        overflow="auto"
        maxH="450px"
        className="red-scrollbar"
      >
        <Heading size="md" color="white.80" fontWeight="normal">
          Feedback
        </Heading>
        {header && (
          <Text fontSize="md" mb="4">
            Here&apos;s some general feedback from the race and a comparison of
            stats and parts.
          </Text>
        )}

        <UnorderedList>
          {tips && tips?.length > 0 ? (
            <>
              {tips?.map((tip) => (
                <ListItem
                  key={tip}
                  background="rgba(255, 255, 255, 0.1)"
                  fontFamily={'heading'}
                  textTransform="uppercase"
                  p="2"
                  mb="0.5rem"
                  fontSize="xs"
                  color="white.80"
                  borderRadius="sm"
                  borderTopLeftRadius="0"
                  listStyleType="none"
                  position="relative"
                  _before={{
                    content: '""',
                    position: 'absolute',
                    top: '0px',
                    left: '-5px',
                    borderTop: '1px solid transparent',
                    borderBottom: '5px solid transparent',
                    borderRight: '5px solid rgba(255, 255, 255, 0.1)',
                  }}
                >
                  {tip}
                </ListItem>
              ))}
            </>
          ) : (
            <>No race tips.</>
          )}
        </UnorderedList>
      </Flex>
    </Flex>
  );
};
