import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconPayouts = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    viewBox="0 0 12 12"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M6 0c3.305 0 6 2.695 6 6 0 3.328-2.695 6-6 6-3.328 0-6-2.672-6-6 0-3.305 2.672-6 6-6Zm2.016 7.383c.257-1.36-1.079-1.711-1.875-1.922l-.211-.047c-.938-.258-.89-.398-.867-.586.046-.281.585-.422 1.218-.328.188.023.399.094.657.164a.56.56 0 0 0 .703-.351.56.56 0 0 0-.352-.704c-.305-.093-.516-.164-.726-.187V3A.57.57 0 0 0 6 2.437.555.555 0 0 0 5.437 3v.422c-.796.117-1.359.562-1.476 1.219-.258 1.336 1.102 1.71 1.687 1.851l.188.07c1.031.258 1.125.375 1.078.61-.047.281-.586.422-1.242.328a8.132 8.132 0 0 1-.867-.234l-.14-.047a.525.525 0 0 0-.704.351.55.55 0 0 0 .352.703l.14.047c.305.094.633.211.984.258V9c0 .328.235.563.563.563A.555.555 0 0 0 6.563 9v-.398c.773-.118 1.335-.563 1.453-1.22Z"
    />
  </svg>
));
export default SvgIconPayouts;
