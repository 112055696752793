import { Image, Flex } from '@chakra-ui/react';
const IconKgen = () => {
  return (
    <Flex
      minW="60px"
      alignItems="center"
      justifyContent="center"
    >
      <Image 
        src="/images/kgen-races.png" 
        alt="Kgen Races" 
        width="32px" 
        height="32px"
      />
    </Flex>
  );
};

export default IconKgen;
