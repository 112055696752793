import { Image } from '@chakra-ui/react';
const IconSilverTicket = ({ 
  width = '32px', 
  height = '32px' 
}: { 
  width?: string | Record<string, string>, 
  height?: string | Record<string, string> 
}) => {
  return <Image 
    src="/images/icons/icon-silver-ticket.png" 
    alt="Silver Ticket" 
    width={width} 
    height={height} 
  />
}

export default IconSilverTicket;
