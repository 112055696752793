/* eslint-disable react-hooks/exhaustive-deps */
import decode from 'jwt-decode';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { ReactNode, createContext, useContext, useEffect } from 'react';

import routes from 'routes';

interface AuthProviderProps {
  children: ReactNode;
}

const Context = createContext({});

export function AuthProvider({ children }: AuthProviderProps) {
  const router = useRouter();
  const pathname = usePathname();
  const currentPath = router.asPath;
  const encodedRedirectTo = encodeURIComponent(currentPath);

  // TODO this is a temporary hack until protected routes and token refresh are implemented
  useEffect(() => {
    let expiredTokenTimer: ReturnType<typeof setInterval> | undefined;
    const rawSessionData = localStorage.getItem('sessionData');
    const publicRoutes = [
      routes.login(),
      routes['2fa'](),
      routes['2fa/confirmation']({}),
      routes.resetPassword(),
      routes.verifyAccount({ email: '' }).split('?')[0],
      routes.publicDashboard(),
    ];

    const checkExpiration = (exp: number) => {
      if (Date.now() >= exp * 1000) {
        localStorage.removeItem('sessionData');
        router.push(`${routes.login()}?redirectTo=${encodedRedirectTo}`);
      } else if (pathname?.includes('login') || pathname?.includes('/2fa')) {
        router.push(routes.root());
      }
    };

    // redirect to login if token is expired
    if (rawSessionData) {
      const { accessToken } = JSON.parse(rawSessionData);
      // TODO try / catch in case of invalid token
      const { exp }: { exp: number } = decode(accessToken);

      // check for expired token immediately, then on interval
      checkExpiration(exp);
      expiredTokenTimer = setInterval(() => {
        checkExpiration(exp);
      }, 5000);
    }

    // redirect to login if no token
    console.log(pathname, publicRoutes);
    if (!rawSessionData && publicRoutes.every((route) => route !== pathname)) {
      router.push(`${routes.login()}?redirectTo=${encodedRedirectTo}`);
    }

    // effect cleanup
    return () => {
      clearInterval(expiredTokenTimer);
    };
  }, [router, pathname]);
  return <Context.Provider value={{}}>{children}</Context.Provider>;
}

export function useAuth() {
  return useContext(Context);
}
