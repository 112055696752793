import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import { AuthRequest } from 'types';

import { loginGoogle } from './loginWithGoogle';
// import { loginFacebook } from './loginWithFacebook';

const signupRequest = async ({
  authProvider,
  credentials,
  source = '',
}: AuthRequest) => {
  // normalize casing to prevent "user doesn't exist" errors
  const email = credentials.email.toLowerCase();

  if (authProvider === 'google') {
    return await loginGoogle();
  }

  // if (authProvider === 'facebook') {
  // }

  if (credentials) {
    const subdomain = window.location.hostname
      .split('.')
      .slice(0, -2)
      .join('.');
    let environment = 'game';

    if (subdomain === '' || subdomain === 'dev') {
      environment = 'dev';
    }

    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID || '',
      ClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID || '',
    });

    const dataEmail = new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: 'email',
      Value: email,
    });

    return new Promise((resolve, reject) => {
      userPool.signUp(
        email,
        credentials.password,
        [dataEmail],
        [],
        (err, result) => {
          if (err) {
            reject(err);
          }

          resolve(result?.user);
        },
        { environment, source }
      );
    });
  }
};

export { signupRequest };
