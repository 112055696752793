import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useSetVerifyLogin = () => {
  const { graphql, graphQLClient } = useGraphQLClient();
  const queryClient = useQueryClient();

  const setVerifyLoginGQL = graphql(`
    mutation VerifyFirstLogin {
      verifyFirstLogin
    }
  `);

  const setVerifyLogin = useMutation({
    mutationKey: ['setVerifyLogin'],
    mutationFn: () => {
      return graphQLClient.request(setVerifyLoginGQL);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['garageQuery'],
      });

      queryClient.invalidateQueries({
        queryKey: ['userSettings'],
      });
    },
  });

  return setVerifyLogin;
};

export default useSetVerifyLogin;
