/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  SVGProps,
} from 'react';
import { IconDollarBalance, IconVextBorder, IconMatic } from 'icons';
import { ChakraComponent } from '@chakra-ui/react';
import useWalletBalance from 'hooks/useWalletBalance';
import {
  ConvertionResponse,
  useCryptoConvervtion,
} from 'hooks/Crypto/useCryptoConvertion';

interface FundsProviderProps {
  children: ReactNode;
}
export type TokenPossibilities = 'usdc' | 'matic' | 'vext';

type CryptoData = {
  currency: string;
  balance: number;
  readableBalance: number;
  usdValue: number;
  color: string;
  icon: ChakraComponent<
    (props: SVGProps<SVGSVGElement>) => React.JSX.Element,
    object
  >;
  convertionData: ConvertionResponse;
};
export interface BalanceDataProps {
  usdc: CryptoData;
  vext: CryptoData;
  matic: CryptoData;
}

interface FundsContextProps {
  actualTokenSelected: TokenPossibilities;
  changeSelectedToken: (token: TokenPossibilities) => void;
  balanceData: BalanceDataProps;
  isLoading: boolean;
}

const FundsContext = createContext<FundsContextProps>({} as FundsContextProps);

export const FundsProvider: React.FC<FundsProviderProps> = ({ children }) => {
  // Init
  //
  //
  const [actualTokenSelected, setActualTokenSelected] =
    useState<TokenPossibilities>('usdc');

  const { data: userBalanceData, isLoading: isLoadingBalance } =
    useWalletBalance();
  const { data: maticConvertionData, isLoading: isLoadingMatic } =
    useCryptoConvervtion('matic');
  const { data: vextConvertionData, isLoading: isLoadingVext } =
    useCryptoConvervtion('vext');
  const { data: usdcConvertionData, isLoading: isLoadingUsdc } =
    useCryptoConvervtion('usdc');

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadingCheck =
      isLoadingBalance || isLoadingMatic || isLoadingVext || isLoadingUsdc;
    setIsLoading(loadingCheck);
  }, [isLoadingBalance, isLoadingMatic, isLoadingVext, isLoadingUsdc]);

  // Init WalletObject
  const [balanceData, setBalanceData] = useState<BalanceDataProps>({
    usdc: {
      currency: 'USDC',
      balance: 0,
      readableBalance: 0,
      usdValue: 0,
      color: 'usdc.100',
      icon: IconDollarBalance,
      convertionData: {} as ConvertionResponse,
    },
    vext: {
      currency: 'VEXT',
      balance: 0,
      readableBalance: 0,
      usdValue: 0,
      color: 'vext.100',
      icon: IconVextBorder,
      convertionData: {} as ConvertionResponse,
    },
    matic: {
      currency: 'MATIC',
      balance: 0,
      readableBalance: 0,
      usdValue: 0,
      color: 'matic.100',
      icon: IconMatic,
      convertionData: {} as ConvertionResponse,
    },
  });

  useEffect(() => {
    if (
      userBalanceData &&
      maticConvertionData &&
      vextConvertionData &&
      usdcConvertionData
    ) {
      setBalanceData({
        matic: {
          ...balanceData.matic,
          balance: userBalanceData?.getUserBalance?.matic || 0,
          readableBalance: userBalanceData?.getUserBalance?.readableMatic || 0,
          usdValue:
            maticConvertionData?.last *
            (userBalanceData?.getUserBalance?.readableMatic || 0),
          convertionData: maticConvertionData,
        },
        vext: {
          ...balanceData.vext,
          balance: userBalanceData?.getUserBalance?.vext || 0,
          readableBalance: userBalanceData?.getUserBalance?.readableVext || 0,
          usdValue:
            vextConvertionData?.last *
            (userBalanceData?.getUserBalance?.readableVext || 0),
          convertionData: vextConvertionData,
        },
        usdc: {
          ...balanceData.usdc,
          balance: userBalanceData?.getUserBalance?.usdc || 0,
          readableBalance: userBalanceData?.getUserBalance?.readableUsdc || 0,
          usdValue:
            usdcConvertionData?.last *
            (userBalanceData?.getUserBalance?.readableUsdc || 0),
          convertionData: usdcConvertionData,
        },
      });
    }
  }, [
    userBalanceData,
    maticConvertionData,
    vextConvertionData,
    usdcConvertionData,
  ]);

  const changeSelectedToken = (token: TokenPossibilities) => {
    setActualTokenSelected(token);
  };

  return (
    <FundsContext.Provider
      value={{
        actualTokenSelected,
        changeSelectedToken,
        balanceData,
        isLoading,
      }}
    >
      {children}
    </FundsContext.Provider>
  );
};

export const useFunds = () => useContext(FundsContext);
