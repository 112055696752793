import { Flex, useDisclosure, Text } from '@chakra-ui/react';
import { RModal, RTooltip } from 'components';
import { PrizePoolStrategy } from 'components/PrizePool/PrizePool';
import { IconInfo } from 'icons';

export const RacePrizePool = ({
  raceId,
  prizePool,
  fontSize,
  fractionDigits,
}: {
  raceId: string;
  prizePool: number;
  fontSize?: string | Record<string, string>;
  fractionDigits?: number;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <RTooltip label="View details">
        <Flex
          h="100%"
          justify="center"
          align="center"
          cursor="pointer"
          onClick={(e) => {
            e.stopPropagation();
            console.log('clicked');
            onOpen();
          }}
        >
          <Text color="treasuredTeal.100" fontSize={fontSize || 'lg'} fontWeight="semibold">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: fractionDigits || 0,
              maximumFractionDigits: fractionDigits || 0,
            }).format(Number(prizePool) / 100)}
          </Text>
          <IconInfo
            __css={{
              path: { fill: 'treasuredTeal.100' },
              ml: 1,
              width: '14px',
            }}
          />
        </Flex>
      </RTooltip>

      <RModal
        size="3xl"
        title="Prize Pool Placing"
        isOpen={isOpen}
        onClose={onClose}
        variant="darkGrey"
        isCentered
      >
        <PrizePoolStrategy raceId={raceId} />
      </RModal>
    </>
  );
};
