import {
  Button,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
  Text,
  Flex,
  Skeleton,
  useDisclosure,
  Box,
  Divider,
  Heading,
  Grid,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';

import routes from 'routes';
import {
  IconLogout,
  IconVextBorder,
  IconGear,
  IconPencil,
  IconPlusCircle,
  IconChevron,
  IconDollarBalance,
  IconMaticCircle,
  IconTicketTournament,
  IconWallet,
} from 'icons';
import IconTicket from 'components/IconTicket/IconTicket';
import { colors } from 'utils/themeConstants';
import { useGetTicketsAvailable, useSessionData, useUserSettings } from 'hooks';
import { SVGIcon } from 'types';
import { RTicketsBundleModal, RTooltip } from 'components';
import { useUser, useWallet } from 'context';
import { WalletDrawer } from 'components/Wallet/WalletDrawer';
import SetAvatarNameModal from 'components/Avatar/SetAvatarNameModal';
import { useFunds } from 'context/fundsContext';
import { getNextDay3PMEST, getNextFriday3PMEST } from 'utils/helpers';
import { CountdownTimer } from 'components/CountdownTimer/CountdownTimer';
import IconSilverTicket from 'components/IconSilverTicket/IconSilverTicket';
import WalletModal from 'components/Wallet/WalletModal/WalletModal';

const Balance = ({
  Icon,
  color,
  amount,
  label,
  onClick,
}: {
  Icon: SVGIcon;
  color?: string;
  amount?: number | string;
  label?: string;
  onClick?: () => void;
}) => {
  return (
    <RTooltip label={label} shouldWrapChildren>
      <Flex h="full" alignItems="center" gap="1" fontSize="1rem">
        <Icon
          h="1.5rem"
          w="auto"
          mr="0.25rem"
          __css={{ path: { fill: color } }}
        />{' '}
        {/* {typeof amount === 'number' ? (
          <Text color={color} fontWeight={700}>
            {amount}
          </Text>
        ) : <Spinner size="xs" />} */}
        <Text color={color} fontWeight={700}>
          {amount}
        </Text>
        {onClick && (
          <IconPlusCircle
            h="0.875rem"
            w="auto"
            ml="0.25rem"
            cursor="pointer"
            __css={{ path: { fill: 'white.40' } }}
            onClick={onClick}
          />
        )}
      </Flex>
    </RTooltip>
  );
};

// const CustomButton = () => {
//   const { isOpen } = usePopoverContext();

//   return (
//     <Button
//       display="flex"
//       justifyContent="center"
//       variant="unstyled"
//       rightIcon={dropdownIcon}
//       bg={isOpen ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.1)'}
//       _hover={{
//         bg: 'rgba(255, 255, 255, 0.2)',
//       }}
//       borderRadius="3"
//       h={["2.5rem", "3rem"]}
//       p="2"
//     >
//       <Box
//         position="relative"
//         overflow="hidden"
//         borderRadius="3"
//         width="2.5rem"
//         height="2.5rem"
//         bg={`linear-gradient(362.18deg, #E31B23 -21.88%, rgba(227, 27, 35, 0) 58.12%), linear-gradient(180deg, ${colors.black[100]} 44.27%, ${colors.yankeesBlue[100]} 100%)`}
//       >
//         <Avatar
//           size="full"
//           src={avatarImgSrc}
//           position="absolute"
//           w="5rem"
//           h="5rem"
//           left="-1.2rem"
//           objectFit="cover"
//           objectPosition="center"
//         />
//         <IconCrown width="1rem" top="-2.5" position="absolute" />
//       </Box>
//     </Button>
//   );
// };

const UserMenu = ({
  dropdownIcon,
  setIsWalletOpen,
}: {
  dropdownIcon: SVGIcon;
  setIsWalletOpen: (isOpen: boolean) => void;
}) => {
  const { clearSessionData } = useSessionData();
  const { getUserSettings } = useUserSettings();
  const { avatarImgSrc } = useUser();
  const { handleClearWalletData } = useWallet();

  const router = useRouter();
  const { data } = useGetTicketsAvailable();
  const { isOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenEditName,
    onOpen: onOpenEditName,
    onClose: onCloseEditName,
  } = useDisclosure();
  const {
    isOpen: isPopoverOpen,
    onOpen: onPopoverOpen,
    onClose: onPopoverClose,
  } = useDisclosure();

  const handleLogout = () => {
    clearSessionData();
    handleClearWalletData();
    router.push(routes.login());
  };

  const {
    isOpen: isWalletOpen,
    onClose: onWalletClose,
    onOpen: onWalletOpen,
  } = useDisclosure();

  const {
    isOpen: isWalletModalOpen,
    onClose: onWalletModalClose,
    onOpen: onWalletModalOpen,
  } = useDisclosure();

  const getDisplayName = () => {
    if (getUserSettings.isLoading) {
      return <Skeleton width="60%" mr="2" />;
    }

    return (
      <Text fontSize="md" whiteSpace="break-spaces" isTruncated>
        {getUserSettings.data?.getUser.playerName ||
          getUserSettings.data?.getUser.email.split('@')[0]}
      </Text>
    );
  };

  const { balanceData, isLoading: isFundsLoading } = useFunds();

  return (
    <>
      <SetAvatarNameModal
        isOpen={isOpenEditName}
        onClose={onCloseEditName}
        currentName={
          getUserSettings.data?.getUser.playerName ||
          getUserSettings.data?.getUser.email?.split('@')[0] ||
          '' // TODO show skeleton while loading
        }
      />
      <Popover
        arrowShadowColor="fieryCoral.100"
        offset={[0, 15]}
        isOpen={isPopoverOpen}
        onClose={onPopoverClose}
      >
        <WalletDrawer
          isOpen={isWalletOpen}
          onClose={() => {
            onWalletClose();
            setIsWalletOpen(false);
          }}
        />
        <Flex flexDir="row" alignItems="center" gap="3">
          {/* <Show above="md">
            <Balances />
          </Show> */}

          <Flex
            onClick={() => {
              onWalletModalOpen();
              setIsWalletOpen(true);
            }}
            cursor="pointer"
            _hover={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }}
            p="2"
            borderRadius="4px"
            background={isWalletModalOpen ? 'bloodMoon.100' : 'transparent'}
          >
            <IconWallet />
          </Flex>
          <Box>
            <PopoverTrigger>
              <Button
                display="flex"
                justifyContent="center"
                variant="unstyled"
                rightIcon={dropdownIcon}
                bg={
                  isPopoverOpen ? 'bloodMoon.100' : 'rgba(255, 255, 255, 0.1)'
                }
                _hover={{
                  bg: isPopoverOpen
                    ? 'bloodMoon.100'
                    : 'rgba(255, 255, 255, 0.2)',
                }}
                borderRadius="3"
                h={['2.5rem', '2.5rem']}
                p="1"
                onClick={onPopoverOpen}
                id="profile-button"
              >
                <Box
                  position="relative"
                  overflow="hidden"
                  borderRadius="3"
                  width="2rem"
                  height="2rem"
                  bg={`linear-gradient(362.18deg, #E31B23 -21.88%, rgba(227, 27, 35, 0) 58.12%), linear-gradient(180deg, ${colors.black[100]} 44.27%, ${colors.yankeesBlue[100]} 100%)`}
                >
                  <Avatar
                    size="full"
                    src={avatarImgSrc}
                    position="absolute"
                    w="5rem"
                    h="4.5rem"
                    left="-1.5rem"
                    objectFit="cover"
                    objectPosition="center"
                  />
                  {/* <IconCrown width="1rem" top="-2.5" position="absolute" /> */}
                </Box>
              </Button>
            </PopoverTrigger>
          </Box>
        </Flex>

        <Portal>
          <PopoverContent
            marginRight="1.5rem"
            border="0"
            borderRadius="0"
            width={{ base: '100vw', md: '20rem' }}
          >
            <PopoverArrow
              bg="fieryCoral.100"
              clipPath="polygon(100% 0%, 100% 0%, 0% 100%, 0% 0%)" // hides overflow that leaks through semi transparent background
              transform="scale(175%) rotate(45deg) !important"
            />
            <PopoverBody
              bg="rgba(0, 0, 0, 0.8)"
              p="4"
              boxShadow="dark-lg"
              backdropFilter="blur(10px)"
              borderRadius="8px"
              w="full"
            >
              <Flex flexDir="column" gap="2">
                <Flex flexDir="row" alignItems="center" justifyContent="space-between">
                  <Flex
                    alignItems="center"
                    as={NextLink}
                    href={routes.avatar()}
                  >
                    <Box
                      position="relative"
                      borderRadius="3"
                      overflow="hidden"
                      width="3rem"
                      height="3rem"
                      mr="0.5rem"
                      mb="2"
                      bg={
                        'linear-gradient(123.99deg, rgba(79, 197, 186, 0) -31.65%, rgba(79, 197, 186, 0) 50.56%), linear-gradient(302.18deg, #E31B23 -21.88%, rgba(227, 27, 35, 0) 58.12%)'
                      }
                    >
                      <Avatar
                        size="full"
                        src={avatarImgSrc}
                        position="absolute"
                        w="10rem"
                        h="10rem"
                        left="-3.5rem"
                        top="-0.8rem"
                        objectFit="cover"
                        objectPosition="center"
                      />
                      {/* <IconCrown width="2rem" top="5" /> */}
                    </Box>
                    {getDisplayName()}
                  </Flex>
                  <Flex
                    alignItems="center"
                    cursor="pointer"
                    onClick={onOpenEditName}
                    id="edit-name-div"
                    gap={2}
                  >

                    <IconPencil width="1rem" ml="auto" />
                  </Flex>
                </Flex>

                <Flex flexDir="column" gap="2">
                  <Button
                    onClick={() => router.push(routes.settings())}
                    variant="ghost"
                    width="full"
                    alignItems="center"
                    justifyContent="left"
                    position="relative"
                    flexDir="row"
                    size="sm"
                    leftIcon={<IconGear mx="1" />}
                    id="settings-button"
                    fontSize="14px"
                    lineHeight="20px"
                    fontWeight="400"
                    color="white.80"
                  >
                    Settings
                    <IconChevron mx="1" position="absolute" right="3" />
                  </Button>

                  <Button
                    onClick={handleLogout}
                    variant="ghost"
                    width="full"
                    alignItems="center"
                    justifyContent="left"
                    flexDir="row"
                    size="sm"
                    leftIcon={<IconLogout mx="1" />}
                    id="logout-button"
                    fontSize="14px"
                    lineHeight="20px"
                    fontWeight="400"
                    color="white.80"
                  >
                    Log Out
                  </Button>
                </Flex>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>

      <WalletModal isOpen={isWalletModalOpen} onClose={() => {
        onWalletModalClose();
        setIsWalletOpen(false);
      }} />
    </>
  );
};

export default UserMenu;
