import { Flex, Heading, Skeleton, Text } from '@chakra-ui/react';
import { Races, Rarity } from 'api/generated/graphql';
import { RaceTier, RRaceStatus } from 'components';
import { RaceStatus } from 'types';
import { admissionTicketBgGradient } from 'utils/themeConstants';
import { RaceTimer } from './RaceTimer';
import TournamentBadge from 'components/Tiers/TournamentBadge';
import { RacePrizePool } from 'components/Races2/RacePrizePool/RacePrizePool';
import IconKgen from 'components/IconKgen/IconKgen';
import RaceLevel from 'components/Races2/RaceLevel/RaceLevel';
interface RaceHeaderProps {
  race?: Races;
  raceStatus: RaceStatus;
}
export const raceRarity: Record<0 | 1 | 2 | 3 | 4 | 5, string> = {
  0: 'free',
  1: Rarity.Common,
  2: Rarity.Uncommon,
  3: Rarity.Rare,
  4: Rarity.Epic,
  5: Rarity.Legendary,
};
export const RaceHeader = ({ race, raceStatus }: RaceHeaderProps) => {
  return (
    <Flex
      w={{ base: 'full', md: '38rem' }}
      alignItems="flex-end"
      justifyContent="space-between"
      flexDir="row"
      mb="0.5rem"
    >
      <Flex
        alignItems="center"
        fontSize="1.25rem"
        textTransform="capitalize"
        fontWeight={400}
      >
        {race?.tier.name.toLowerCase() === 'tournament' || race?.tier.name.toLowerCase() === 'weeklytournament' ? (
          <TournamentBadge />
        ) 
        : race?.tier.name.toLowerCase() === 'kgen' ? (
            <IconKgen />
          ) 
          : (
            <RaceLevel level={(race?.difficulty || 0) + 1} />
          )}
        <Flex flexDir="column" gap="0.25rem" ml="0.5rem">
          <Heading fontSize="1.125rem" lineHeight="1.5rem" fontWeight={400}>
            {race?.track?.frontendName || (
              <Skeleton height="1rem" width="7rem" />
            )}
          </Heading>
          <Flex alignItems="center" gap="0.25rem" fontFamily="body">
            <RRaceStatus type="simple" status={raceStatus} />
            <RaceTimer dateTime={race?.qualifyingAnnouncementTime as string} />
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDir="column" alignItems="flex-end">
        <Text
          fontFamily="heading"
          textTransform="uppercase"
          fontSize="0.625rem"
          lineHeight="1.0.75rem"
          color="white.60"
          fontWeight={400}
        >
          Prize Pool
        </Text>
        <Flex alignItems="center" gap="0.25rem" fontFamily="body" cursor="pointer">
          <RacePrizePool
            raceId={race?.id || ''}
            prizePool={race?.prizePool || 0}
            fontSize="24px"
            fractionDigits={2}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};