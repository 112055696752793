import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconMaticWallet = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.8}
      fillRule="evenodd"
      d="M31.2 16C31.2 7.605 24.396.8 16 .8 7.607.8.8 7.605.8 16S7.607 31.2 16 31.2c8.395 0 15.2-6.805 15.2-15.2Zm-14.352 4.042c-.028.409-.235.767-.552.955L12.6 23.161c-.379.205-.783.184-1.096.004l-3.71-2.143a1.096 1.096 0 0 1-.545-.95l.025-4.284c.028-.409.234-.767.551-.956l3.697-2.163c.38-.206.784-.184 1.096-.004l1.29.744-.014 2.009-1.25-.722a1.096 1.096 0 0 0-1.096.004l-1.965 1.147c-.34.229-.547.587-.551.955l.011 2.236c.012.43.232.77.545.95l1.953 1.128c.351.203.779.185 1.096-.003l1.964-1.148c.34-.228.547-.586.552-.955l.014-2.91.013-2.009.014-2.91c.028-.409.234-.767.552-.955l3.697-2.164c.379-.206.783-.184 1.096-.004l3.671 2.12a.99.99 0 0 1 .506.928l-.01 4.346c-.027.409-.234.767-.55.955l-3.675 2.124c-.379.206-.783.184-1.096.004l-1.328-.767.014-2.008 1.289.744c.351.203.779.184 1.096-.004l2.003-1.125c.34-.228.547-.586.552-.955l.011-2.275a1.108 1.108 0 0 0-.544-.951l-1.953-1.128a1.096 1.096 0 0 0-1.096.004l-2.004 1.125c-.34.228-.547.586-.551.955l.025 2.933-.053 1.986.003 2.973Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconMaticWallet;
