import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconRetryWallet = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={22}
    fill="none"
    viewBox="0 0 20 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#fff"
      fillOpacity={0.95}
      d="M6.875 6c-2.773 0-5 2.266-5 5 0 .547-.43.938-.938.938C.391 11.938 0 11.546 0 11a6.86 6.86 0 0 1 6.875-6.875h5.586V1.937c0-.351.195-.703.547-.859.351-.117.781-.039 1.055.234l3.124 3.125a.92.92 0 0 1 0 1.29l-3.125 3.125a1.052 1.052 0 0 1-1.054.234c-.352-.156-.547-.508-.547-.899V6H6.875Zm12.188 4.063A.95.95 0 0 1 20 11a6.86 6.86 0 0 1-6.875 6.875H7.5v2.188c0 .39-.234.742-.586.898-.351.117-.781.039-1.016-.234l-3.164-3.125c-.351-.352-.351-.938 0-1.329l3.164-3.125c.235-.234.665-.312 1.016-.195.352.156.586.508.586.86V16h5.625c2.734 0 5-2.227 5-5 0-.508.39-.938.938-.938Z"
    />
  </svg>
));
export default SvgIconRetryWallet;
