import { Flex, Text } from "@chakra-ui/react";
import { raceLevelGradient } from "utils/themeConstants";

const raceColors = {
  1: 'cerebralGrey.100',
  2: 'secondaryGrey.100',
  3: 'uncommon.100',
  4: 'rare.100',
  5: 'epic.100',
  6: 'legendary.100',
}

const RaceLevel = ({ level, textFontSize, numberFontSize, minW }: { level: number, textFontSize?: string, numberFontSize?: string, minW?: string }) => {
  return (
    <Flex
      background={raceLevelGradient[level as keyof typeof raceLevelGradient]}
      borderTopRightRadius="12px"
      borderBottomLeftRadius="12px"
      borderTopLeftRadius="2px"
      borderBottomRightRadius="2px"
      px="1.5"
      py="1.5"
      minW={minW || "58px"}
      border="1px solid rgba(255, 255, 255, 0.2)"
      alignItems="center"
      justifyContent="center"
      gap="1"
      style={{
        backgroundOrigin: 'border-box',
      }}
    >
      <Text
        fontSize={textFontSize || "10px"}
        lineHeight={textFontSize || "10px"}
        fontWeight="600"
        color="white.80"
      >
        LEVEL
      </Text>
      <Text
        fontSize={numberFontSize || "14px"}
        lineHeight={numberFontSize || "14px"}
        fontFamily="heading"
        fontWeight="400"
        color="white.80"
      >
        {level}
      </Text>
    </Flex>
  )
}

export default RaceLevel;