import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import RImage from 'components/Image/Image';
import { CheckoutModal } from 'components/CheckoutModal/CheckoutModal';
import { useAnimatedTransition, useLoadingTrailer } from 'context';
import { useGetTicketPrice, useTicketPaymentProcessSucceeded } from 'hooks';
import { CrossmintEvent } from '@crossmint/client-sdk-react-ui';
import { IconCheckGreen, IconCheckSquare, IconDnaFusion, IconTicketTournament, IconUnlocked } from 'icons';
import IconTicket from 'components/IconTicket/IconTicket';
import { CurrencyType, MutationTicketPaymentProcessSucceededEventArgs } from 'api/generated/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { CryptoTabTicket } from 'components/CheckoutModal/Crypto/CryptoTabTicket';
import router from 'next/router';

const TicketsBanner = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  // const [quantity, setQuantity] = useState(1);

  // const { setIsLoadingTickets } = useLoadingTrailer();

  // const queryClient = useQueryClient();

  // const paymentEnv =
  //   process.env.NEXT_PUBLIC_ENV === 'dev' ? 'staging' : 'production';
  // const ticketsContractId = process.env.NEXT_PUBLIC_TICKETS_CONTRACT_ID || '';
  // const projectId = process.env.NEXT_PUBLIC_CROSSMINT_API_ID || '';

  // const { showAnimatedTransition } = useAnimatedTransition();
  // const { mutateAsync } = useTicketPaymentProcessSucceeded();

  // const { data: bundle1Data } = useGetTicketPrice(1);

  // const bundle1 = bundle1Data?.ticketPrice;

  // const onSuccessBuy = async (e: CrossmintEvent, bundleId: number) => {
  //   showAnimatedTransition({
  //     Icon: IconUnlocked,
  //     title: 'Ticket',
  //     id: 'ticket-purchased-success',
  //     subtitle: 'Purchased',
  //     color: 'treasuredTeal.100',
  //     bgColor:
  //       'linear-gradient(116deg, #04360F -11.26%, #000400 30.41%, #011205 71.39%, #006845 104.43%)',
  //     gradientColor: {
  //       from: 'rgba(72, 223, 187, 0.2)',
  //       to: 'rgba(72, 223, 187, 0)',
  //     },
  //   });

  //   console.log('==> Mutate ticket events <==');
  //   const input = {
  //     succeededInput: {
  //       currency: CurrencyType.Usd,
  //       bundleId,
  //     },
  //   } as MutationTicketPaymentProcessSucceededEventArgs;
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   const orderIdentifier = e?.payload?.orderIdentifier;
  //   if (orderIdentifier) {
  //     console.log('orderIdentifier', orderIdentifier);
  //     //todo: cannot use individual fields as they are not included with crossmint types; need different type wrappers?
  //     const uuidRegex =
  //       /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  //     if (uuidRegex.test(orderIdentifier)) {
  //       input.succeededInput.orderIdentifier = orderIdentifier;
  //     } else {
  //       input.succeededInput.actionId = orderIdentifier;
  //     }
  //   }
  //   await mutateAsync(input);
  //   await queryClient.refetchQueries({
  //     queryKey: ['ticketsAvailable'],
  //   });
  //   onClose();
  // };

  // useEffect(() => {
  //   let timeout: NodeJS.Timeout | null = null;

  //   if (quantity > 0) {
  //     timeout = setTimeout(() => {
  //       setIsLoadingTickets(false);
  //     }, 1000);
  //   }

  //   return () => {
  //     if (timeout) {
  //       clearTimeout(timeout);
  //     }
  //   };
  // }, [quantity, setIsLoadingTickets]);

  return (
    <>
      <Swiper
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{
          clickable: true,
          type: 'bullets',
          el: '.swiper-pagination',
        }}
        breakpoints={{
          768: {
            slidesPerView: 2,
            spaceBetween: 4,
          },
          1024: {
            slidesPerView: 2.5,
            spaceBetween: 5,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 5,
          },
        }}
        style={{
          height: window.innerWidth < 1024 ? '120px' : 'auto',
          paddingBottom: window.innerWidth < 1024 ? '30px' : '0px'
        }}
      >
        <SwiperSlide>
          <Flex
            justifyContent="space-between"
            w="full"
            minH="88px"
            alignItems="center"
            px={{ base: 4, md: 4 }}
            py={{ base: 2, md: 2 }}
            position="relative"
            overflow="hidden"
            border="1px solid rgba(255, 255, 255, 0.1)"
            borderRadius="4px"
          >
            <Flex
              position="absolute"
              left={0}
              bottom={0}
              w="full"
              h="full"
            >
              <Flex 
                w="full"
                h="full"
                bg="linear-gradient(110.7deg, #D83832 0%, rgba(216, 56, 50, 0) 90.18%)"
                opacity={0.6}
              />
              <Flex
                w="full"
                h="full"
                bg="linear-gradient(308.2deg, #4FC5BA -10.12%, rgba(79, 197, 186, 0) 50.41%)"
              />
            </Flex>
            <Flex
              flexDirection="column"
              zIndex={10}
            >
              <Text
                color="rgba(255, 9, 0, 1)"
                fontSize="16px"
                fontWeight="700"
              >
                READY TO RACE?
              </Text>
              <Text
                whiteSpace="pre-line"
                fontSize="16px"
                fontWeight="600"
                color="white.95"
                fontStyle="italic"
              >
                {`GET YOUR \n TICKETS NOW`}
              </Text>
            </Flex>

            <RImage
              src={`/images/tickets/single-ticket.svg`}
              height={150}
              width={150}
              objectFit="contain"
              transition="all 0.3s ease-in-out"
              position="absolute"
              left="50%"
              top="50%"
              transform="translate(-50%, -50%)"
              alt={''}
              zIndex={10}
            />

            <Button
              variant="tertiary"
              zIndex={10}
              onClick={() => router.push('/marketplace/tickets')}
            >
              BUY TICKETS
            </Button>
          </Flex>
        </SwiperSlide>
        <SwiperSlide>
          <Flex
            justifyContent="space-between"
            w="full"
            minH="90px"
            alignItems="center"
            px={{ base: 2, md: 4 }}
            py={{ base: 2, md: 2 }}
            position="relative"
            overflow="hidden"
            border="1px solid rgba(255, 255, 255, 0.1)" 
            borderRadius="4px"
          >
            <Flex
              position="absolute"
              left={0}
              bottom={0}
              w="full"
              h="full"
              background="linear-gradient(81.48deg, #FFE500 -56.34%, rgba(0, 0, 0, 0.6675) 42.7%, rgba(38, 166, 154, 0.3) 100.15%)"
            >
              {/* <Flex 
                w="full"
                h="full"
                bg="linear-gradient(120.79deg, #4FC5BA 50.77%, rgba(79, 197, 186, 0) 80.44%)"
                opacity={0.6}
              />
              <Flex
                w="full"
                h="full"
                bg="linear-gradient(308.34deg, #FFEE58 -5.04%, rgba(255, 238, 88, 0) 70.59%)"
                opacity={0.6}
              /> */}
            </Flex>
            <Flex
              flexDirection="column"
              zIndex={10}
            >
              <Flex
                gap={1}
              >
              <Text
                background="linear-gradient(225deg, #FCF4BF 0%, #EFC862 18%, #CD9832 73%, #BB811B 100%)"
                backgroundClip="text"
                fontSize={{ base: '14px', md: '16px' }}
                fontWeight="700"
              >
                EARN A
                </Text>
                <IconTicketTournament
                  h="1.5rem"
                  w="1.5rem"
                />
                <Text
                  background="linear-gradient(225deg, #FCF4BF 0%, #EFC862 18%, #CD9832 73%, #BB811B 100%)"
                  backgroundClip="text"
                  fontSize={{ base: '14px', md: '16px' }}
                  fontWeight="700"
                >
                GOLDEN TICKET
              </Text>
              </Flex>
              <Text
                whiteSpace="pre-line"
                fontSize={{ base: '14px', md: '14px' }}
                fontWeight="600"
                color="white.95"
                fontStyle="italic"
              >
                {`SELECT 'LEADERBOARD JACKPOT'\n WHEN JOINING A RACE`}
              </Text>
            </Flex>

            <Flex
              zIndex={10} 
              alignItems="center"
              background="linear-gradient(0deg, rgba(72, 223, 187, 0.4) -50%, rgba(72, 223, 187, 0) 100%)"
              borderRadius="4px"
              px={{ base: '0.25rem', md: '0.5rem' }}
              py="0.25rem"
              border="2px solid rgba(79, 197, 186, 0.6)"
              fontSize="24px"
              color="white.100"
            >
              <IconTicket 
                h="2rem"
                w="2rem"
              />
              1
              <IconCheckSquare
                h="1.5rem"
                w="1.5rem"
                ml="0.5rem"
              />
            </Flex>
          </Flex>
        </SwiperSlide>
        <SwiperSlide>
          <Flex
            justifyContent="space-between"
            w="full"
            minH="90px"
            alignItems="center"
            px={{ base: 4, md: 4 }}
            py={{ base: 2, md: 2 }}
            position="relative"
            overflow="hidden"
            border="1px solid rgba(255, 255, 255, 0.1)"
            background="rgba(18, 18, 18, 1)"
            borderRadius="4px"
          >
            <Flex
              position="absolute"
              left={0}
              bottom={0}
              w="full"
              h="full"
            >
              <Flex 
                w="full"
                h="full"
                background="linear-gradient(110.7deg, #42A5F5 0%, rgba(66, 165, 245, 0) 80.18%)"

                opacity={0.6}
              />
              <Flex
                w="full"
                h="full"
                background="linear-gradient(300.2deg, #4FC5BA -20.12%, rgba(79, 197, 186, 0) 65.41%)"
              />
            </Flex>
            <Flex
              flexDirection="column"
              zIndex={12}
            >
              <Text
                background="linear-gradient(90deg, #42A5F5 0%, #71D3FF 100%)"
                backgroundClip="text"
                fontSize="16px"
                fontWeight="700"
              >
                NEED AN UPGRADE?
              </Text>
              <Text
                whiteSpace="pre-line"
                fontSize="14px"
                fontWeight="600"
                color="white.95"
                fontStyle="italic"
              >
                {`FUSE CARS TOGETHER TO\n CREATE A NEW ONE`}
              </Text>
            </Flex>

            <RImage
              src={`/images/cars/fusedCar.png`}
              height={175}
              width={175}
              objectFit="contain"
              transition="all 0.3s ease-in-out"
              position="absolute"
              left="53%"
              top="50%"
              transform="translate(-50%, -50%)"
              alt={''}
              zIndex={10}
            />

            <Button
              variant="tertiary"
              zIndex={10}
              onClick={() => router.push('/car-fusion')}
            >
              FUSE
              <IconDnaFusion
                h="1.5rem"
                w="1.5rem"
              />
            </Button>
          </Flex>
        </SwiperSlide>
        <Flex
          className="swiper-pagination"
          position="absolute"
          alignItems="flex-end"
          justifyContent="center"
          bottom={0}
          left={0}
          right={0}
          top={0}
          gap={1}
        >
        </Flex>
      </Swiper>

      {/* <CheckoutModal
        isOpen={isOpen}
        onClose={onClose}
        checkoutValue={'100'}
        collectionId={ticketsContractId}
        numberInput
        quantity={quantity}
        setQuantity={setQuantity}
        environment={paymentEnv}
        mintConfig={{
          totalPrice: String((bundle1?.price || 5) * quantity),
          bundleId: 1,
          quantity: quantity,
        }}
        CryptoTab={CryptoTabTicket}
        cryptoTabProps={{
          onSuccess: (e) => onSuccessBuy(e, 1),
          bundleId: 1,
        }}
        projectId={projectId}
        title="Buy Racino Tickets"
        onSuccessfulPurchase={(e) => onSuccessBuy(e, 1)}
      /> */}
    </>
  )
}

export default TicketsBanner;
