import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconVextBig = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={88}
    height={88}
    fill="none"
    viewBox="0 0 88 88"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#7A7AFF"
      fillRule="evenodd"
      d="M85.8 44c0 23.085-18.715 41.8-41.8 41.8C20.913 85.8 2.2 67.085 2.2 44 2.2 20.915 20.913 2.2 44 2.2c23.085 0 41.8 18.715 41.8 41.8ZM15.4 28.6 44 36.994 72.6 28.6 51.11 51.833l8.626 14.243-15.738-7.203-15.737 7.203 8.625-14.243L15.399 28.6Zm9.381 5.903L44 41.11l19.218-6.607L44 46.417 24.781 34.503ZM44 48.963 23.772 34.61 40.52 51.616l-9.584 11.806L44 54.053l13.064 9.37-9.583-11.807 16.746-17.005L44 48.963Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconVextBig;
