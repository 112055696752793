import * as React from 'react';
import type { SVGProps } from 'react';
import { chakra } from '@chakra-ui/react';
const SvgIconUsdcBig = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={89}
    height={88}
    fill="none"
    viewBox="0 0 89 88"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#2775CA"
      fillRule="evenodd"
      d="M88.5 44c0 24.383-19.617 44-44 44-24.384 0-44-19.617-44-44 0-24.384 19.616-44 44-44 24.383 0 44 19.616 44 44Zm-69.666-8.983c-5.317 14.116 2.016 30.066 16.316 35.2.55.366 1.1 1.1 1.1 1.65v2.566c0 .367 0 .55-.184.733-.183.734-.916 1.1-1.65.734-10.266-3.3-18.15-11.184-21.45-21.45-5.5-17.417 4.034-35.934 21.45-41.434.184-.183.55-.183.734-.183.733.184 1.1.733 1.1 1.467v2.566c0 .917-.367 1.467-1.1 1.834-7.516 2.75-13.566 8.617-16.316 16.317Zm34.1-21.267c.183-.733.916-1.1 1.65-.733 10.082 3.3 18.15 11.183 21.45 21.633 5.5 17.416-4.034 35.933-21.45 41.433-.184.183-.55.183-.734.183-.733-.183-1.1-.733-1.1-1.466v-2.567c0-.916.367-1.467 1.1-1.833 7.517-2.75 13.566-8.617 16.316-16.317 5.318-14.116-2.016-30.066-16.316-35.2-.55-.366-1.1-1.1-1.1-1.833v-2.567c0-.367 0-.55.184-.733ZM56.6 50.967c0-6.417-3.85-8.617-11.55-9.533-5.5-.734-6.6-2.2-6.6-4.767s1.833-4.217 5.5-4.217c3.3 0 5.133 1.1 6.05 3.85.184.55.733.917 1.283.917h2.934c.733 0 1.283-.55 1.283-1.283v-.184c-.733-4.033-4.033-7.15-8.25-7.517v-4.4c0-.733-.55-1.283-1.467-1.466h-2.75c-.733 0-1.283.55-1.467 1.466v4.217c-5.5.733-8.983 4.4-8.983 8.983 0 6.05 3.667 8.434 11.367 9.35 5.133.917 6.783 2.017 6.783 4.95 0 2.934-2.566 4.95-6.05 4.95-4.767 0-6.416-2.016-6.966-4.766-.184-.734-.733-1.1-1.283-1.1h-3.117c-.733 0-1.284.55-1.284 1.283v.183c.733 4.584 3.667 7.884 9.717 8.8v4.4c0 .734.55 1.284 1.467 1.467h2.75c.733 0 1.283-.55 1.467-1.466v-4.4c5.5-.917 9.166-4.767 9.166-9.717Z"
      clipRule="evenodd"
    />
  </svg>
));
export default SvgIconUsdcBig;
