import {
  IconAustinMinimapMarkers,
  IconBarcelonaMinimapMarkers,
  IconBudapestMinimapMarkers,
  IconImolaMinimapMarkers,
  IconLusailMinimapMarkers,
  IconMelbourneMinimapMarkers,
  IconMexicoCityMinimapMarkers,
  IconMontrealMinimapMarkers,
  IconSakhirMinimapMarkers,
  IconSilverstoneMinimapMarkers,
  IconSpaMinimapMarkers,
  IconSpielbergMinimapMarkers,
  IconSuzukaMinimapMarkers,
  IconZandvoortMinimapMarkers,
} from 'icons';
import { SVGProps } from 'react';

type RaceMinimapType = {
  [key: string]: React.ElementType;
};

export const RaceMinimapList: RaceMinimapType = {
  Belgium: IconSpaMinimapMarkers,
  Spain: IconBarcelonaMinimapMarkers,
  'United Kingdom': IconSilverstoneMinimapMarkers,
  Netherlands: IconZandvoortMinimapMarkers,
  USA: IconAustinMinimapMarkers,
  Italy: IconImolaMinimapMarkers,
  Bahrain: IconSakhirMinimapMarkers,
  Qatar: IconLusailMinimapMarkers,
  Hungary: IconBudapestMinimapMarkers,
  Austria: IconSpielbergMinimapMarkers,
  Canada: IconMontrealMinimapMarkers,
  Japan: IconSuzukaMinimapMarkers,
  Australia: IconMelbourneMinimapMarkers,
  'Mexico City': IconMexicoCityMinimapMarkers,
  Texas: IconAustinMinimapMarkers,
};

const getMinimapMarkersPerTrack = (track: string): React.ElementType => {
  return RaceMinimapList[track] || IconSpaMinimapMarkers;
};

type MinimapProps = SVGProps<SVGSVGElement> & {
  race: string;
};

export const RaceMinimap = ({ race, ...props }: MinimapProps) => {
  const RaceMinimap = getMinimapMarkersPerTrack(race);

  return <RaceMinimap {...props} />;
};

export default RaceMinimap;
