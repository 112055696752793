import {
  Flex,
  Modal,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import WalletBalance from '../subcomponents/WalletBallance';
import WalletButtons from '../subcomponents/WalletButtons';
import WalletSummary from '../subcomponents/WalletSummary';
import { useState } from 'react';
import Receive from '../subcomponents/Receive';
import Send from '../subcomponents/Send';
import { useFunds } from 'context/fundsContext';
import { useGetTicketsAvailable, useUserSettings } from 'hooks';
import { IconCheckGreen, IconCopyPaste, IconWalletFilled } from 'icons';
import { ActivityTab } from '../ActivityTab';

interface WalletModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WalletModal = ({ isOpen, onClose }: WalletModalProps) => {
  const [activeSection, setActiveSection] = useState<'send' | 'receive' | 'wallet'>('wallet');
  const [activeSendSection, setActiveSendSection] = useState<"send" | "to" | "summary" | "success" | "error" | "transaction">("send");

  const { balanceData, isLoading: isFundsLoading } = useFunds();
  const { getUserSettings } = useUserSettings();
  const { data: ticketsAvailable, isLoading: isTicketsLoading } = useGetTicketsAvailable();

  const [copied, setCopied] = useState<boolean>(false);

  const handleCopyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Falha ao copiar:', err);
      setCopied(false);
    }
  };

  const shouldShowGradient = !(
    activeSection === 'send' && 
    ['success', 'error', 'transaction'].includes(activeSendSection)
  );

  const handleCloseModal = () => {
    setActiveSection('wallet');
    setActiveSendSection('send');
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} variant="walletModal" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex
            alignItems="center"
            gap="2"
            fontSize="14px"
            lineHeight="18px"
            fontWeight="400"
            color="white.100"
          >
            <IconWalletFilled />
            Wallet Address
            <Flex
              cursor="pointer"
              onClick={() => handleCopyToClipboard(getUserSettings?.data?.getUser.walletAddress || '')}
              alignItems="center"
              gap="2"
            >
              <IconCopyPaste 
                w="1rem"
                h="1rem"
                sx={{
                  path: {
                    fill: copied ? 'treasuredTeal.100' : 'white.100'
                  }
                }}
              />
              {copied && <IconCheckGreen />}
            </Flex>
          </Flex>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody
          position="relative"
        >
          {shouldShowGradient && (
            <Flex 
              position="absolute"
              top="0"
              right="0"
              w="full"
              h="full"
              background="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.22) 100%);"
              zIndex="-1"
            />
          )}
          {activeSection === 'wallet' && (
            <Tabs
              w="full"
              variant="tabWallet"
            >
              <TabPanels>
                <TabPanel>
                  <Flex
                    flexDir="column"
                    gap="1rem"
                    height="500px"
                    overflowY="auto"
                    className="red-scrollbar"
                    px="4"
                    py="8"
                  >
                    <WalletBalance balanceData={balanceData} isLoading={isFundsLoading} />

                    <WalletButtons setActiveSection={setActiveSection} />

                    <WalletSummary balanceData={balanceData} ticketsAvailable={ticketsAvailable} />
                  </Flex>
                </TabPanel>

                <TabPanel>
                <Flex
                  flexDir="column"
                  gap="4"
                  alignItems="center"
                  justifyContent="flex-start"
                  height="500px"
                  overflowY="auto"
                  className="red-scrollbar"
                  px="4"
                  py="8"
                  w="full"
                  >
                    <Text
                      fontSize="24px"
                      lineHeight="28px"
                      fontFamily="heading"
                      fontWeight="400"
                      color="white.100"
                    >
                      Activity
                    </Text>
                    <ActivityTab />
                  </Flex>
                </TabPanel>
              </TabPanels>
              <TabList>
                <Tab>
                  Home
                </Tab>
                <Tab>
                  Activity
                </Tab>
              </TabList>
            </Tabs>
          )}

          {activeSection === 'receive' && (
            <Receive setActiveSection={setActiveSection} />
          )}

          {activeSection === 'send' && (
            <Send 
              setActiveSection={setActiveSection} 
              balanceData={balanceData}
              activeSendSection={activeSendSection}
              setActiveSendSection={setActiveSendSection}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
};

export default WalletModal;
