import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import {
  IconBumpsConditions,
  IconGripConditions,
  IconRainConditions,
  IconTemperatureConditions,
  IconWindConditions,
} from 'icons';
import {
  AirTemperatureCategory,
  TrackTemperatureCategory,
  WindCategories,
  TarmacConditionCategories,
  WeatherCategories,
} from 'api/generated/graphql';
import {
  AirTemperatureColors,
  TrackTemperatureColors,
  WeatherColors,
  RoughnessColors,
  TarmacConditionColors,
  WindColors,
} from '../../RaceInfo/RaceInfoTypes';
import { useState } from 'react';

export const conditionsHoverText = {
  skill: 'How difficult the track is, impacting Tuning options.',
  air: 'How cold or hot the air is, impacting Cooling and Engine Mode.',
  track: 'How cold or hot the track is, impacting Tire choice.',
  weather: 'How much water is on the track, impacting Tires and Stability.',
  wind: 'How fast the air moves, impacting Rear Wing effectiveness.',
  roughness: 'How clean the track is, impacting Tire choice.',
  tarmac: 'How bumpy the track is, impacting Ride Height.',
  points: 'Higher levels have bigger Point bonuses in larger grids.',
  payouts: 'Higher levels have more top-heavy payout distributions.',
  tuning: 'Higher levels have wider Tuning ranges and options.',
};

// Função auxiliar para determinar o número de boxes preenchidos
const getFilledBoxes = (
  category: string,
  type: 'air' | 'track' | 'weather' | 'roughness' | 'tarmac' | 'wind'
): number => {
  const categories = {
    air: Object.keys(AirTemperatureColors),
    track: Object.keys(TrackTemperatureColors),
    weather: Object.keys(WeatherColors),
    roughness: Object.keys(RoughnessColors),
    tarmac: Object.keys(TarmacConditionColors),
    wind: Object.keys(WindColors),
  };
  const index = categories[type].indexOf(category);
  return (index + 1) / 2; // Convert to scale of 0-3 with 0.5 increments
};

// Componente para renderizar os boxes
const ConditionBoxes = ({
  category,
  type,
}: {
  category: string;
  type: 'air' | 'track' | 'weather' | 'roughness' | 'tarmac' | 'wind';
}) => {
  const filledBoxes = getFilledBoxes(category, type);
  const color = 'yellow';

  return (
    <Flex alignItems="center" gap={1}>
      {[...Array(3)].map((_, index) => {
        const fillAmount = Math.min(1, Math.max(0, filledBoxes - index));
        
        return (
          <Box
            key={index}
            w={2}
            h={2}
            position="relative"
            borderRadius="2px"
            border="1px solid"
            borderColor={fillAmount > 0 ? color : 'white.20'}
            overflow="hidden"
          >
            <Box
              position="absolute"
              left={0}
              bottom={0}
              w={fillAmount === 0.5 ? '50%' : '100%'}
              h="100%"
              bg={fillAmount > 0 ? color : 'transparent'}
            />
          </Box>
        );
      })}
    </Flex>
  );
};

const RaceConditions = ({
  airTemperature,
  trackTemperature,
  weather,
  wind,
  roughness,
  tarmac,
  showHover = false,
}: {
  airTemperature: AirTemperatureCategory;
  trackTemperature: TrackTemperatureCategory;
  weather: WeatherCategories;
  wind: WindCategories;
  roughness: string;
  tarmac: TarmacConditionCategories;
  showHover?: boolean;
}) => {
  const [isMobile] = useMediaQuery('(max-width: 48em)');
  const [conditionHovered, setConditionHovered] = useState<
    'air' | 'track' | 'weather' | 'wind' | 'roughness' | 'tarmac' | null
  >(null);

  return (
    <Flex
      gap={4}
      w="full"
      alignItems="center"
      flexDir="column"
      justifyContent="flex-end"
    >
      {!showHover && (
        <Text
          fontSize="16px"
          color="white.80"
          fontWeight={400}
          fontFamily="heading"
        >
          TRACK CONDITIONS
        </Text>
      )}
      <Flex w="full" gap={2}>
        {isMobile ? (
          // Mobile layout (2 columns)
          <>
            <Flex flexDirection="column" gap={1} w="full">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'air' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderRadius="8px"
                onMouseEnter={() => setConditionHovered('air')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconTemperatureConditions />
                  <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
                    Air
                  </Text>
                </Flex>
                <ConditionBoxes category={airTemperature} type="air" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'track' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                onMouseEnter={() => setConditionHovered('track')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconTemperatureConditions />
                  <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
                    Track
                  </Text>
                </Flex>
                <ConditionBoxes category={trackTemperature} type="track" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'roughness' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderRadius="8px"
                onMouseEnter={() => setConditionHovered('roughness')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconGripConditions />
                  <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
                    Grip
                  </Text>
                </Flex>
                <ConditionBoxes category={roughness} type="roughness" />
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap={1} w="full">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'wind' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderRadius="8px"
                onMouseEnter={() => setConditionHovered('wind')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconWindConditions />
                  <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
                    Wind
                  </Text>
                </Flex>
                <ConditionBoxes category={wind} type="wind" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'weather' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                onMouseEnter={() => setConditionHovered('weather')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconRainConditions />
                  <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
                    Rain
                  </Text>
                </Flex>
                <ConditionBoxes category={weather} type="weather" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'tarmac' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderRadius="8px"
                onMouseEnter={() => setConditionHovered('tarmac')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconBumpsConditions />
                  <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
                    Bumps
                  </Text>
                </Flex>
                <ConditionBoxes category={tarmac} type="tarmac" />
              </Flex>
            </Flex>
          </>
        ) : (
          // Desktop layout (3 columns)
          <>
            <Flex flexDirection="column" gap={1} w="full">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'air' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderRadius="8px"
                onMouseEnter={() => setConditionHovered('air')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconTemperatureConditions />
                  <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
                    Air
                  </Text>
                </Flex>
                <ConditionBoxes category={airTemperature} type="air" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'track' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderRadius="8px"
                onMouseEnter={() => setConditionHovered('track')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconTemperatureConditions />
                  <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
                    Track
                  </Text>
                </Flex>
                <ConditionBoxes category={trackTemperature} type="track" />
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap={1} w="full">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'wind' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderRadius="8px"
                onMouseEnter={() => setConditionHovered('wind')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconWindConditions />
                  <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
                    Wind
                  </Text>
                </Flex>
                <ConditionBoxes category={wind} type="wind" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'roughness' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderRadius="8px"
                onMouseEnter={() => setConditionHovered('roughness')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconGripConditions />
                  <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
                    Grip
                  </Text>
                </Flex>
                <ConditionBoxes category={roughness} type="roughness" />
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap={1} w="full">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'weather' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderRadius="8px"
                onMouseEnter={() => setConditionHovered('weather')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconRainConditions />
                  <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
                    Rain
                  </Text>
                </Flex>
                <ConditionBoxes category={weather} type="weather" />
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                background={`linear-gradient(90deg, rgba(255, 255, 255, ${
                  conditionHovered === 'tarmac' ? '0.3' : '0.1'
                }) 0%, rgba(255, 255, 255, 0) 100%)`}
                px="8px"
                py="4px"
                borderRadius="8px"
                onMouseEnter={() => setConditionHovered('tarmac')}
                onMouseLeave={() => setConditionHovered(null)}
              >
                <Flex alignItems="center" gap={2}>
                  <IconBumpsConditions />
                  <Text fontSize="12px" color="white.80" lineHeight="14px" fontWeight="600">
                    Bumps
                  </Text>
                </Flex>
                <ConditionBoxes category={tarmac} type="tarmac" />
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
      <Text
        fontSize="0.75rem"
        lineHeight="0.875rem"
        color="white.60"
        fontWeight={500}
      >
        {conditionHovered
          ? conditionsHoverText[conditionHovered]
          : 'Select a Condition to learn more'}
      </Text>
    </Flex>
  );
};

export default RaceConditions;
