import { Flex, Heading } from '@chakra-ui/react';
import { IconTicketTournament } from 'icons';
import IconTicket from 'components/IconTicket/IconTicket';
import { admissionTicketBgGradient } from 'utils/themeConstants';
import IconSilverTicket from 'components/IconSilverTicket/IconSilverTicket';

interface TicketTypeSelectProps {
  numberOfTickets?: number;
  ticketsAvailable?: number;
  type?: 'racino' | 'daily' | 'weekly';
}

export const TicketTypeSelect = ({
  numberOfTickets = 0,
  ticketsAvailable = 0,
  type = 'racino',
}: TicketTypeSelectProps) => {
  return (
    <Flex w="full" flexDir="column" gap="0.125rem" mb="1rem">
      <Flex
        w="full"
        alignItems="center"
        justifyContent="space-between"
        p="0.5rem"
        borderTopRadius="0.5rem"
        bg={admissionTicketBgGradient}
      >
        <Heading
          fontSize="0.875rem"
          color="white.60"
          textTransform="uppercase"
          fontWeight={400}
        >
          Available
        </Heading>
        <Flex gap="0.5rem" alignItems="center">
          {type === 'racino' ? (
              <IconTicket w="0.875rem" h="0.875rem" />
            ) : type === 'daily' ? (
              <IconSilverTicket width="1rem" height="1rem" />
            ) : (
              <IconTicketTournament w="1.25rem" h="1.25rem" />
          )}
          <Heading
            fontSize="1.125rem"
            textTransform="uppercase"
            color="white.80"
            fontWeight={400}
          >
            {ticketsAvailable}
          </Heading>
        </Flex>
      </Flex>

      <Flex
        w="full"
        alignItems="center"
        justifyContent="space-between"
        p="0.5rem"
        bg={admissionTicketBgGradient}
      >
        <Heading
          fontSize="0.875rem"
          color="white.60"
          textTransform="uppercase"
          fontWeight={400}
        >
          Total
        </Heading>
        <Flex gap="0.5rem" alignItems="center">
          {type === 'racino' ? (
            <IconTicket w="0.875rem" h="0.875rem" />
          ) : type === 'daily' ? (
            <IconSilverTicket width="1rem" height="1rem" />
          ) : (
            <IconTicketTournament w="1.25rem" h="1.25rem" />
          )}
          <Heading
            fontSize="1.125rem"
            textTransform="uppercase"
            color="white.80"
            fontWeight={400}
          >
            {numberOfTickets}
          </Heading>
        </Flex>
      </Flex>
    </Flex>
  );
};
